import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import Breadcrumb from "../common/breadcrumb";
import SessionCheck from "../common/SessionCheck";
import { connect } from 'react-redux'
import store from '../../store';
import { logoutUser } from '../../actions'

var axios = require('axios');

class ChangePassword extends Component {

    constructor (props) {
        super (props)

        this.state = {
          current_pswd: '',
          new_pswd: '',
          confirm_pswd: '',
          warning: null,
          successText: null
        }
    }

    onChange = (e) => {
      var obj = {};
      obj[e.target.name] = e.target.value;
      this.setState(obj);
    }

    changePswd = () => {
      if (this.state.current_pswd === '' || this.state.new_pswd === '' || this.state.confirm_pswd === '') {
        this.setState({warning: '* Please fill out all the fields correctly.'})
        return;
      }

      if (this.state.new_pswd.length < 6) {
        this.setState({warning: '* Your new password must be at least 6 characters long.'})
        return;
      }


      if (this.state.new_pswd !== this.state.confirm_pswd) {
        this.setState({warning: '* Passwords do not match.'})
        return;
      }

      axios.post('/api/shop/change_password', {current_pswd: this.state.current_pswd, new_pswd: this.state.new_pswd})
      .then(({data}) => {
          this.setState({warning: null, successText: 'Your password has changed!', current_pswd: '', new_pswd: '', confirm_pswd: ''})

      })
      .catch(error => {
        this.setState({warning: '* Your current password is incorrect.'})
      })
    }

    logOut = e => {
      e.preventDefault();

      store.dispatch(logoutUser());
    }

    render (){
      if (!this.props.user) {
        return <Redirect to={'/'} />;
      }

        return (
            <div>
              <SessionCheck />
                <Breadcrumb title={'Account / Change Password'}/>


                {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="account-sidebar">
                                  <Link to={`${process.env.PUBLIC_URL}/account/dashboard`} style={{color: 'white'}}>
                                    Back
                                  </Link>
                                </div>
                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                    <span className="filter-back">
                                        <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                    </span>
                                    </div>
                                    <div className="block-content">
                                        <ul>
                                            <li tabIndex={0}><Link to={`${process.env.PUBLIC_URL}/account/dashboard`}>Account Info</Link></li>
                                            <li tabIndex={0}><Link to={`${process.env.PUBLIC_URL}/account/orders`}>My Orders</Link></li>
                                            <li tabIndex={0} className="active"><Link to={`${process.env.PUBLIC_URL}/account/profile/change-password`}>Change Password</Link></li>
                                            <li tabIndex={0}><Link to={`${process.env.PUBLIC_URL}/account/profile/change-preferences`}>My Preferences</Link></li>
                                            <li tabIndex={0} className="last"><a href="/" onClick={this.logOut}>Log Out</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        <div className="page-title">
                                            <h2>Change Your Password</h2>
                                        </div>
                                        <div className="box-account box-info">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="box">
                                                        <div className="box-title">
                                                        </div>
                                                        <div className="box-content">
                                                              <div className="form-row">
                                                                  <div className="col-md-12" style={{padding: '10px 0'}}>
                                                                      <input type="password" name="current_pswd" defaultValue={this.state.current_pswd} className="form-control" id="current_pswd"  placeholder="Current Password" required="" onChange={this.onChange}/>
                                                                  </div>
                                                                  <div className="col-md-12" style={{padding: '10px 0'}}>
                                                                      <input type="password" name="new_pswd" defaultValue={this.state.new_pswd} className="form-control" id="new_pswd" placeholder="New Password" required="" onChange={this.onChange}/>
                                                                  </div>
                                                                  <div className="col-md-12" style={{padding: '10px 0'}}>
                                                                      <input type="password" name="confirm_pswd" defaultValue={this.state.confirm_pswd} className="form-control" id="confirm_pswd" placeholder="Confirm New Password" required="" onChange={this.onChange}/>
                                                                  </div>
                                                                  <div className="col-md-12" style={{padding: '10px 0'}}>
                                                                    {this.state.warning ?
                                                                      <p style={{color: 'red'}}>{this.state.warning}</p>
                                                                      :
                                                                      ''
                                                                    }
                                                                    {this.state.successText ?
                                                                      <p style={{color: '#129e51'}}>{this.state.successText}</p>
                                                                      :
                                                                      ''
                                                                    }
                                                                    <button aria-labelledby="Submit change password" onClick={this.changePswd} className="btn btn-solid">Submit</button>
                                                                  </div>
                                                              </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.userData.user,
})

export default connect(
    mapStateToProps, {logoutUser}
)(ChangePassword)
