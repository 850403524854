import React, { Component } from 'react';
import '../common/index.scss';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import DetailsWithPrice from "./common/product/details-price";
import { addToCart } from '../../actions'
import ImageZoom from './common/product/image-zoom'
import SmallImages from './common/product/small-image'
import Modal from 'react-responsive-modal';
import {
  isMobile
} from "react-device-detect";

class ProductModal extends Component {
	constructor() {
		super();
		this.state = {
			nav1: null,
			nav2: null,
      showImage: 0
		};
	}

	componentDidMount() {
		this.setState({
			nav1: this.slider1,
			nav2: this.slider2
		});
	}

	onAddToCartClicked(item, variant, q, cartItem, cart_id) {
		this.props.addToCart(item, variant, q, cartItem, cart_id);
		this.props.onClose();
	}

  selectedImage = (index) => {
    this.setState({showImage: index})
  }

	render() {
		const { symbol, item } = this.props

		var productsnav = {
			slidesToShow: 3,
			slidesToScroll: 1,
			swipeToSlide: true,
			draggable: true,
			focusOnSelect: true
		};

		if (!item) {
			return <Redirect to={'/'} />
		}

		return (
			<Modal open={this.props.open} onClose={this.props.onClose} classNames={{closeButton: 'product-modal-close-btn'}}>
				{/*Section Start*/}
				<section style={{paddingTop: '25px'}}>
					<div className="collection-wrapper">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 col-12 product-thumbnail">
                  <div>
                    <ImageZoom name={item.name} image={item.images[this.state.showImage]} className="img-fluid image_zoom_cls-0" />
                  </div>

									{isMobile ?
										null
										:
										(
											<SmallImages item={item} settings={productsnav} navOne={this.state.nav1} selectedImage={this.selectedImage} />
										)
									}
								</div>
								<DetailsWithPrice symbol={symbol} item={item} navOne={this.state.nav1}
									addToCartClicked={this.onAddToCartClicked.bind(this)}
									cart_obj={this.props.cart} cart={this.props.cartItems}
									store={this.props.store} />

									{isMobile ?
										(
											<SmallImages item={item} settings={productsnav} navOne={this.state.nav1} selectedImage={this.selectedImage} />
										) : 	null
									}
							</div>
						</div>
					</div>
				</section>
				{/*Section End*/}


				{/* <RelatedProduct /> */}
			</Modal>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let productId = ownProps.productId;
	return {
		// eslint-disable-next-line
		item: state.data.products.find(el => el.store_products.findIndex(s => s.id == productId) !== -1),
		symbol: state.data.symbol,
		store: state.storeData.selected_store,
		cartItems: state.cartList.cart,
		cart: state.cartList.cartObj,
    user: state.userData.user
	}
}

export default connect(mapStateToProps, { addToCart })(ProductModal);
