import React, { Component } from 'react';

export default class ImageZoom extends Component {
    render() {
        const {name, image} = this.props;

        return (
          <div>
            <img
              src={`${image}`}
              alt={`${name}`}
              className="img-fluid image_zoom_cls-0"
              onError={(e) => {
                e.target.src = 'https://swad.nyc3.cdn.digitaloceanspaces.com/image-placeholder.jpg'
              }} />
          </div>
        );
    }
}
