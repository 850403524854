import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import SlotCountdownTimer from "../SlotCountdownTimer";
import Modal from 'react-responsive-modal';
import StoreListing from '../../collection/common/store-listing.jsx';
import {SlideUpDown} from "../../../services/script"
import { connect } from 'react-redux'
import { clearStores, clearTimeSlot } from '../../../actions'
import store from '../../../store';

class FooterOne extends Component {
  constructor(props){
      super(props)

      this.state = {
          open: false,
          showStores: false
      }
  }
    componentDidMount(){
        var contentwidth = window.innerWidth;
        if ((contentwidth) < 750) {
            SlideUpDown('footer-title');
        } else {
            var elems = document.querySelectorAll(".footer-title");
            [].forEach.call(elems, function(elemt) {
                let el = elemt.nextElementSibling;
                el.style = "display: block";
            });
        }
    }

    showStoreListings = () => {
      this.setState({showStores: true})
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false, showStores: false });
    };


    render () {
      const {open} = this.state;

        return (
            <footer className="footer-light">
                <div className="light-layout">
                    <div className="container">
                    </div>
                </div>
                <section className="light-layout">
                    <div className="container">
                        <div className="row footer-theme partition-f">
                            <div className="col">
                                <div className="sub-title">
                                    <div className="footer-title">
                                        <h4>Links</h4>
                                    </div>
                                    <div className="footer-contant">
                                        <ul className="footer-inline-ul">
                                          <li><Link to="/contact">contact us</Link></li>
                                          <li><Link to="/policies/privacy-policy">privacy policy</Link></li>
                                          <li><Link to="/policies/refund-policy">refund policy</Link></li>
                                        </ul>
                                        <ul className="footer-inline-ul">
                                          <li><Link to="/policies/terms-of-service">terms of service</Link></li>
                                          <li><Link to="/refunds">refund requests</Link></li>
                                          <li><Link to="/faq" style={{textTransform: 'uppercase'}}>faq</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="sub-title">
                                    <div className="footer-title">
                                        <h4>store information</h4>
                                    </div>

                                    { this.props.user && this.props.selected_store.id ?
                                      (
                                        <div className="footer-contant">
                                        <ul className="contact-list footer-inline-ul">
                                            <li>
                                              <i className="fa fa-map-marker"></i>
                                              {this.props.selected_store.address}
                                            </li>
                                            <li><i className="fa fa-envelope-o"></i> <a aria-label="E-mail Store" href={`mailto:${this.props.selected_store.email}`} style={{textTransform: 'lowercase'}}>{this.props.selected_store.email}</a></li>
                                          </ul>
                                          <ul className="contact-list footer-inline-ul">
                                            <li role="button" aria-label="Change store" tabIndex={0} onClick={this.onOpenModal} style={{cursor: 'pointer'}}>
                                              <i className="fa fa-shopping-cart"></i>
                                              change store
                                            </li>
                                          </ul>
                                          </div>

                                      ) :
                                      (
                                        <div className="footer-contant">

                                        <ul className="contact-list">
                                          <li>
                                            You must login/register and select your local store to get your local store information.
                                          </li>
                                          {this.props.user ?
                                            ('')
                                            :
                                            (
                                              <li>
                                                <Link to='/login'>
                                                  Login
                                                </Link>
                                                <span style={{margin: '0 10px'}}>|</span>
                                                <Link to='/register'>
                                                  Register
                                                </Link>
                                              </li>
                                            )
                                          }

                                        </ul>
                                        </div>
                                      )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="sub-footer ">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-md-6 col-sm-12">
                                <div className="footer-end">
                                    <p><i className="fa fa-copyright" aria-hidden="true"></i>2023 Patel Brothers</p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6 col-sm-12">
                                <div className="payment-card-bottom">
                                    <ul>
                                        <li>
                                            <img width="25px" height="25px" src={`https://swad.nyc3.cdn.digitaloceanspaces.com/patelbros/icons/visa.png`} alt="We Accept Visa" />
                                        </li>
                                        <li>
                                            <img width="25px" height="25px" src={`https://swad.nyc3.cdn.digitaloceanspaces.com/patelbros/icons/mastercard.png`} alt="We Accept Mastercard" />
                                        </li>
                                        <li>
                                            <img width="25px" height="25px" src={`https://swad.nyc3.cdn.digitaloceanspaces.com/patelbros/icons/amex.png`} alt="We Accept American Express" />
                                        </li>
                                        <li>
                                            <img width="25px" height="25px" src={`https://swad.nyc3.cdn.digitaloceanspaces.com/patelbros/icons/discover.png`} alt="We Accept Discover" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SlotCountdownTimer />

                <Modal open={open} onClose={this.onCloseModal} center>
                  <div className="modal-header">
                    <h2>Change Your Store</h2>
                  </div>
                  <div className="modal-body">
                    {this.state.showStores ?
                      (<StoreListing />)
                      :
                      (
                        <h3>
                          Are you sure you want to change your store?
                          <p className="text-danger text-center"><b>{this.props.reservedSlot ? '* YOUR TIME SLOT WILL EXPIRE IF YOU CONTINUE.' : ''}</b></p>
                        </h3>
                      )
                    }

                    <center>
                      {/*<button className="modal-btn btn btn-success" onClick={this.showStoreListings}>Yes</button>*/}
                      <Link role="button" aria-labelledby="Close modal clear timeslots" className="modal-btn btn btn-success" to={'/'} onClick={() => {
                        this.setState({ open: false });
                        if (this.props.reservedSlot) {
                          store.dispatch(clearTimeSlot(this.props.reservedSlot.id))
                        }
                        store.dispatch(clearStores());
                      }}>Yes</Link>
                      <button aria-labelledby="Close modal" className="modal-btn btn btn-danger" onClick={this.onCloseModal}>Cancel</button>
                    </center>
                  </div>
                </Modal>

            </footer>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.userData.user,
    reservedSlot: state.userData.slot,
    selected_store: state.storeData.selected_store
})

export default connect(
    mapStateToProps, {clearStores, clearTimeSlot}
)(FooterOne)
