import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import store from '../../store';
import { logoutUser, addToCart } from '../../actions';
import Breadcrumb from "../common/breadcrumb";
import SessionCheck from "../common/SessionCheck";

const axios = require('axios');
const moment = require('moment');

class Orders extends Component {
	constructor(props) {
		super(props);
		this.state = {
			orders: [],
			loadingOrders: false,
			loadingRefund: false,
			loadingCancel: false,
			isCancelOpen: false,
			orderToCancel: null,
			requestCancelError: '',
			isRefundOpen: false,
			orderToRefund: null,
			refundNotes: '',
			requestRefundError: ''
		};
	}

	componentDidMount() {
		if (this.props.user) {
			this.setState({ loadingOrders: true });
			axios.get('/api/shop/account_orders')
				.then(({ data }) => {
					this.setState({ orders: data, loadingOrders: false });
				})
				.catch(error => {
					this.setState({ loadingOrders: false });
				})
		}
	}

	logOut = e => {
		e.preventDefault();
		store.dispatch(logoutUser());
	}

	requestRefund = (order) => {
		this.setState({orderToRefund: order, isRefundOpen: true, requestRefundError: ''});
	}

	requestCancel = (order) => {
		this.setState({orderToCancel: order, isCancelOpen: true, requestCancelError: ''});
	}

	cancelOrder = (orderId) => {
    try {
		this.setState({loadingCancel: true});

		axios.post('/api/shop/request_cancel_order', {
		  order_id: orderId
		})
		.then(({data}) => {
		  this.setState({orders: this.state.orders.map(item => {
			if (item.id === data.id) {
			  item.order_status = 'Cancellation Requested';
			}
			return item;
		  })});

		  this.setState({orderToCancel: null, isCancelOpen: false, loadingCancel: false, requestCancelError: ''});

		})
		.catch(error => {
			this.setState({loadingCancel: false, requestCancelError: 'Could not request cancellation for this order at this time. Please try again later.'});
		})

    } catch (e) {
		this.setState({loadingCancel: false, requestCancelError: 'Could not cancel order at this time. Please try again later.'});
    }
  }

	reorder = (order) => {
		for (let i = 0; i < order.products.length; i++) {
			let orderItem = order.products[i];
			let product = this.props.products.find(el => el.store_products.findIndex(s => parseInt(s.product_id) === parseInt(orderItem.product_id)) !== -1);
			if (product) {
				let qty = orderItem.qty;

				product.price = orderItem.price;

				if (product.stock > 0) {
					qty = Math.min(product.stock, orderItem.qty);
				}

				let itemsInCart = this.props.cartItems.filter(i => parseInt(i.sp_id) === parseInt(orderItem.sp_id));
				let cartItem = itemsInCart.length > 0 ? itemsInCart[0] : null;
				let cart_id = this.props.cart ? this.props.cart.id : null;
				this.props.addToCart(product, orderItem.variant, qty, cartItem, cart_id);
			}
		}

		this.props.history.push('/cart');
	}

	renderOrdersOnMobile() {
		return (
			<div className="box-account box-info d-block d-md-none d-lg-none d-xl-none px-3">
				{this.state.orders.map(order => {
					return (
						<div className='border-bottom pt-2' key={order.id}>
							<h5>
								<Link to={{ pathname: `${process.env.PUBLIC_URL}/account/order/${order.id}`, state: { order: order } }}>
									Order #{order.order_num}
								</Link>
								{order.products.length} Items - {order.total}
							</h5>
							<h5>Pickup: {moment(order.selected_time_slot.pickup_date).format("MM/DD/YYYY")} {order.selected_time_slot.pickup_time}</h5>
							<h5>{this.state.orders[0].store.name}</h5>
							<div>
								<h5>{order.order_status}</h5>
							</div>
							<div className='d-flex flex-row justify-content-end align-items-center pb-2'>
								{this.props.store.id == order.store_id ?
									<button aria-labelledby={`Reorder ${order.id}`} className='btn select-store-btn mr-2' onClick={() => this.reorder(order)}>
										Re-order
									</button>
									: null
								}
								{(order.order_status && order.order_status.toLowerCase().includes('completed')) && (
									<button aria-labelledby={`Request refund of order ${order.id}`} className='btn select-store-btn' onClick={() => this.requestRefund(order)}>
										Request Refund
									</button>
								)}
								{(order.order_status && order.order_status.toLowerCase().includes('pending')) && (
									<button aria-labelledby={`Cancel order ${order.id}`} className='btn select-store-btn' onClick={() => this.requestCancel(order)} disabled={this.state.loadingCancel}>
										Cancel Order
									</button>
								)}
							</div>
						</div>
					)
				})}
			</div>
		);
	}

	renderOrders() {
		return (
			<div className="row">
				{this.state.orders.map(order => {
					return (
						<div className="col-sm-12" key={order.id}>
							<div className="box">
								<div className="box-title">
								</div>
								<div className="box-content">
									<div className="row">
										<div className="col-3">
											<h5><Link to={{ pathname: `${process.env.PUBLIC_URL}/account/order/${order.id}`, state: { order: order } }}>
												Order #{order.order_num}</Link></h5>
											<h5>{order.products.length} Items - {order.total}</h5>
										</div>
										<div className="col-3">
											<h5>Pickup Date:</h5>
											<h5>{moment(order.selected_time_slot.pickup_date).format("MM/DD/YYYY")} @ {order.selected_time_slot.pickup_time}</h5>
											<h5>{this.state.orders[0].store.name}</h5>
										</div>
										<div className="col-3">
											<h5>{order.order_status}</h5>
										</div>
										<div className="col-3">
											<button aria-labelledby={`Re order ${order.id}`} className='btn select-store-btn mb-2' onClick={() => this.reorder(order)}>
												Re-order
											</button>
											{(order.order_status && order.order_status.toLowerCase().includes('completed')) && (
												<button aria-labelledby={`Request refund ${order.id}`} className='btn select-store-btn' onClick={() => this.requestRefund(order)}>
													Request Refund
												</button>
											)}
											{(order.order_status && order.order_status.toLowerCase().includes('pending')) && (
												<button aria-labelledby={`Cancel order ${order.id}`} className='btn select-store-btn' onClick={() => this.requestCancel(order)} disabled={this.state.loadingCancel}>
													Cancel Order
												</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					)
				})}
			</div>
		);
	}

	render() {
		if (!this.props.user) {
			return <Redirect to={'/'} />;
		}

		return (
			<div>
				<SessionCheck />
				<Breadcrumb title={'My Orders'} />
				{this.renderOrdersOnMobile()}
				{/*Dashboard section*/}
				<section className="section-b-space d-none d-md-block d-lg-block d-xl-block">
					<div className="container">
						<div className="row">
							<div className="col-lg-3">
								<div className="account-sidebar">
									my orders
                				</div>
								<div className="dashboard-left">
									<div className="collection-mobile-back">
										<span className="filter-back">
											<i className="fa fa-angle-left" aria-hidden="true"></i> back
                    					</span>
									</div>
									<div className="block-content">
										<ul>
											<li tabIndex={0}><Link to={`${process.env.PUBLIC_URL}/account/dashboard`}>Account Info</Link></li>
											<li tabIndex={0} className="active"><Link to={`${process.env.PUBLIC_URL}/account/orders`}>My Orders</Link></li>
											<li tabIndex={0}><Link to={`${process.env.PUBLIC_URL}/account/profile/change-password`}>Change Password</Link></li>
											<li tabIndex={0}><Link to={`${process.env.PUBLIC_URL}/account/profile/change-preferences`}>My Preferences</Link></li>
											<li tabIndex={0} className="last"><a href="/" onClick={this.logOut}>Log Out</a></li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-lg-9">
								<div className="dashboard-right">
									<div className="dashboard">
										<div className="page-title">
											<h2>My Orders</h2>
										</div>
										<div className="box-account box-info">
											{this.state.orders.length > 0 ?
												this.renderOrders()
												:
												<p>
													{this.state.loadingOrders ?
														'Loading Orders...'
														:
														'You have not placed an order yet.'
													}
												</p>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{this.renderCancelModal()}
				{this.renderRefundModal()}
			</div>
		)
	}

	renderRefundModal() {
		if (this.state.orderToRefund == null)
			return null;

		const items = this.state.orderToRefund.products;

		return (
			<Modal open={this.state.isRefundOpen} onClose={() => this.setState({isRefundOpen: false})} classNames={{modal: "request-refund-model"}}>
				<div className="modal-header">
          <h3>
            	Request Refund
          </h3>
        </div>
		  	<div className="modal-body">
				{items.map((orderItem) => {
					let product = this.props.products.find(el => el.store_products.findIndex(s => parseInt(s.product_id) === parseInt(orderItem.product_id)) !== -1);
					let name = product ? product.name : 'no product name';

					return (
						<div key={orderItem.id} className='row pb-2 px-2 mr-1'>
							<div className='col-lg-4'>
								<input type="checkbox" onClick={(e) => orderItem.refundRequested = e.target.checked}
									value={orderItem.refundRequested} checked={orderItem.refundRequested}
									className="form-check-input" id={orderItem.id + '-rr-check'} />
								<label for={orderItem.id + '-rr-check'}>{name}</label>
							</div>
							<div className='col-lg-4'>
								<input type="number" style={{maxWidth: '60px'}} onChange={(e) => orderItem.refund_qty = e.target.value}
									defaultValue={0}
									className="form-check-input mt-0" id={orderItem.id + '-rr-qty'} />
									<span style={{marginLeft: '50px'}}>/ {orderItem.qty}</span>
							</div>
							<div className='col-lg-4'>
								<select value={orderItem.refundReason}
									onChange={(e) => orderItem.refundReason = e.target.value}>
									<option value="">Select a Reason</option>
									<option value="Item Not Received">Item Not Received</option>
									<option value="Defective Item">Defective Item</option>
									<option value="Wrong Item">Wrong Item</option>
									<option value="Other">Other</option>
								</select>
							</div>
						</div>
					);
				})}
				</div>
				<div className='pb-3'>
					<textarea maxLength='5000' className="form-control" placeholder="Please enter any other information regarding you refund request here." name="message" rows="4"
						onChange={(e) => this.setState({refundNotes: e.target.value})}></textarea>
				</div>
				<div>
					<span style={{color: 'red'}}>{this.state.requestRefundError}</span>
				</div>
				<div>
					<button aria-labelledby="Request refund" className='btn btn-solid' onClick={() => this.doRefund()}>request refund</button>
				</div>
			</Modal>
		);
	}

	async doRefund() {
		const order = this.state.orderToRefund;
		const data = {
			order_id: order.id,
			customer_note: this.state.refundNotes.trim(),
			refund_items: order.products.filter(p => p.refundRequested)
		}
		if (data.refund_items.length === 0){
			this.setState({requestRefundError: 'Please check the items to request a refund'});
			return;
		}

		const noReasonItems = data.refund_items.filter(i => !i.refundReason);
		if (noReasonItems.length > 0) {
			this.setState({requestRefundError: 'Please select a refund reason'});
			return;
		}
		const otherReasonItems = data.refund_items.filter(i => i.refundReason === 'Other');
		if (otherReasonItems.length > 0 && data.customer_note === '') {
			this.setState({requestRefundError: 'Please enter a request refund note'});
			return;
		}
		const aboveQtyItems = data.refund_items.filter(i => parseInt(i.refund_qty) > parseInt(i.qty));
		if (aboveQtyItems.length > 0) {
			this.setState({requestRefundError: `Request refund quantity couldn't be higher than original quantity.`});
			return;
		}

		this.setState({ loadingRefund: true });
		try {
			const resp = await axios.post('/api/shop/request_refund', data);
			const updatedOrders = this.state.orders.map(item => {
				if (item.id === order.id) {
					item.order_status = 'Refund Requested';
				}
				return item;
			});
			this.setState({orders: updatedOrders});
		}
		catch(error) {
			alert('Request refund failed ' + error);
		}
		this.setState({loadingRefund: false, isRefundOpen: false});
	}

	renderCancelModal() {
		if (!this.state.orderToCancel)
			return null;

    const findOrder = this.state.orderToCancel;

    const orderedDate = moment(findOrder.selected_time_slot.pickup_date).format("MM/DD/YYYY");
    const nowDate = moment().format("MM/DD/YYYY");
		const needsRequest = moment(orderedDate).isSame(nowDate);
		return (
			<Modal open={this.state.isCancelOpen} onClose={() => this.setState({isCancelOpen: false})}>
					<div className="modal-header">
						<h3>
								{needsRequest ? 'Cancellation Request' : 'Cancel Order'}
						</h3>
					</div>
					<div className="modal-body">
						Are you sure you want to {needsRequest ? 'submit a cancellation request for' : 'cancel'} this order?
						{needsRequest ?
							' You may be charged a $9.99 restocking fee.'
							: null
						}
						<div>
							<span style={{color: 'red'}}>{this.state.requestCancelError}</span>
						</div>
				</div>
				<div>
					<center>
						<button className='btn btn-solid' onClick={() => this.cancelOrder(findOrder.id)}>confirm</button>
						<button className='btn btn-solid' style={{marginLeft: '10px'}} onClick={() => this.setState({isCancelOpen: false})}>nevermind</button>
					</center>
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.userData.user,
	cartItems: state.cartList.cart,
	store: state.storeData.selected_store,
	cart: state.cartList.cartObj,
	products: state.data.products
})

export default connect(
	mapStateToProps, { logoutUser, addToCart }
)(Orders)
