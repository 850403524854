import React from 'react';
import { observer } from 'mobx-react';
import pdListModel1 from '../../../models/ProductListingModel';
import CategoryListing from './category-listing';
import ProductListing from "./product-listing";
import StickyBox from "react-sticky-box";
import Filter from "./filter";
import FilterBar from "./filter-bar";
import SearchFilter from "./search-bar";
import SessionCheck from '../../common/SessionCheck';
import {
  isMobile
} from "react-device-detect";

interface IProps {
  match: any,
  history: any
}

@observer
export default class CatsProds extends React.Component<IProps, {}> {
	render() {
    const filters = pdListModel1.filters;

    if (filters.name == '') {
      if (this.props.match.params.department) {
        if (this.props.match.params.department == 'all') {
          if (filters.categories && filters.categories.findIndex(c => c == 'All') === -1) {
            pdListModel1.setFilterCategoryAndDepartment(['All'], '')
          }
        } else if (this.props.match.params.department == 'sale') {
          if (filters.categories && filters.categories.findIndex(c => c == 'Sale') === -1) {
            pdListModel1.setFilterCategoryAndDepartment(['Sale'], '')
          }
        } else {
          const departmentName = pdListModel1.departmentSlugs[this.props.match.params.department]

          if (departmentName) {
            if (this.props.match.params.subdepartment) {
              const subdepartmentName = (pdListModel1.subdepartmentSlugs(departmentName))[this.props.match.params.subdepartment]
              if (subdepartmentName) {
                if (filters.categories.findIndex(c => c == subdepartmentName) === -1) {
                  pdListModel1.setFilterCategoryAndDepartment([subdepartmentName], departmentName)
                }
              }

            } else {
              if (filters.department != departmentName) {
                var subs = pdListModel1.subdepartments(departmentName);
                var catNames = subs.map(s => s.category);
                pdListModel1.setFilterCategoryAndDepartment(catNames, departmentName)
              }
            }
          }
        }

      } else if (this.props.match.params.product_name) {
          if (filters.name != this.props.match.params.product_name) {
            if (filters.name == null || filters.name == '') {
              pdListModel1.setFilterCategory([]);
              pdListModel1.setFilterName(this.props.match.params.product_name)
            } else {
              this.props.history.push("/")
            }
          }
      } else {

        if (filters.department != '') {
          pdListModel1.setFilterName('');
          pdListModel1.setFilterCategory([]);
        } else  {
          if (filters.categories) {
            if (filters.categories.length > 0 && filters.categories[0] == 'All') {
              pdListModel1.setFilterName('');
              pdListModel1.setFilterCategory([]);
            }
          }
        }
      }
    } else if (filters.name && filters.name.length > 0) {
      if (this.props.match.params.product_name) {
        if (filters.name != this.props.match.params.product_name) {
            this.props.history.push("/")
        }
      }
    }

    if (filters.categories) {
      if ((!filters.name || filters.name == '') && filters.categories.length === 0) {
  			return <CategoryListing />;
  		}
    } else {
      if ((!filters.name || filters.name == '') && !filters.categories) {
  			return <CategoryListing />;
  		}
    }

		return (
			<section className="section-b-space pt-0">
				<div className="collection-wrapper">
					<div className="container">
						{isMobile ?
							<div className="row">
							<div role="button" aria-label="Close Filter" aria-haspopup={true} tabIndex={0} className="col-sm-12 close-overlay-collection-filter"
							onClick={() => this.closeFilter()}
							></div>
							<div className="col-sm-3 collection-filter">
								<StickyBox offsetTop={20} offsetBottom={20}>
									<Filter />
								</StickyBox>
							</div>
							<div className="collection-content col">
								{this.renderProductListing()}
							</div>
						</div>
							:
							<div className="row">
              <div role="button" aria-label="Close Filter" aria-haspopup={true} tabIndex={0} className="col-sm-12 close-overlay-collection-filter"
							onClick={() => this.closeFilter()}
							></div>
							<div className="col-sm-3 collection-filter">
								<StickyBox offsetTop={20} offsetBottom={20}>
									<Filter />
								</StickyBox>
							</div>
							<div className="collection-content col">
								{this.renderProductListing()}
							</div>
						</div>

						}
					</div>
				</div>
			</section>
		);
	}

	openFilter = () => {
		document.querySelector(".collection-filter").setAttribute('style', "left: -15px");
		document.querySelector(".close-overlay-collection-filter").setAttribute('style', "left: 0");
	}

	closeFilter = () => {
		document.querySelector(".collection-filter").setAttribute('style', "left: -100%");
		document.querySelector(".close-overlay-collection-filter").setAttribute('style', "left: -100%");
	}

	renderProductListing() {
		return (
			<div className="page-main-content ">
				<div className="">
					<div className="row">
						<div className="col-sm-12">
							<div className="collection-product-wrapper">
                <div className='d-block d-lg-none d-xl-none mb-3 border-bottom'>
                  <SearchFilter />
                </div>
								<div className="product-top-filter">
									<div className="container-fluid p-0">
										<div className="row">
											<div className="col-xl-12">
												<div className="filter-main-btn">
													<span role="button" aria-label="Open Categories Filter" tabIndex={0} onClick={() => this.openFilter()} className="w-100 filter-btn btn btn-theme">
														<i	className="fa fa-filter" aria-hidden="true"></i> Categories
													</span>
													<div className="category-tags-list">

													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<FilterBar onLayoutViewClicked={() => {}} />
											</div>
										</div>
									</div>
								</div>
								<ProductListing />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
