import React from 'react';
import { observer } from 'mobx-react';
import ReactPaginate from 'react-paginate';
import ProductListItem from "./product-list-item";
import pdListModel1 from '../../../models/ProductListingModel';
import {
  isMobile
} from "react-device-detect";

@observer
export default class ProductListing extends React.Component<{}, {}> {
	handlePageClick = data => {
    if (document.querySelector(".product-listing-box-image")) {
      var elems = document.querySelectorAll(".product-listing-box-image");
      [].forEach.call(elems, function(elem) {
        elem.setAttribute('src', 'https://swad.nyc3.cdn.digitaloceanspaces.com/image-placeholder.jpg')
      })
    }

		pdListModel1.setCurrentPage(data.selected);
		window.scrollTo(0, 0)
	};

	render() {
		const model = pdListModel1;  // just make it easier to see the value in debugger
		const products = model.filteredAndSortedProducts;
		const pageCount = Math.ceil(products.length / model.perPage);
		const offset = model.currentProductPage * model.perPage;

    const department = model.filters.department;
		let limit = (model.currentProductPage + 1) * model.perPage;
		if (limit > products.length)
			limit = products.length;

		const productsInView = products.slice(offset, limit);

		return (
			<div className="product-wrapper-grid pt-2">
				<div className="container-fluid">
          {department.toLowerCase().includes('bakery') ?
            <img src="https://swad.nyc3.digitaloceanspaces.com/categories/bakery-banner.png" alt="Bakery Banner Image" className="img-fluid" style={{maxHeight: "200px", width: '100%', objectFit: 'contain'}} />
            : null
          }
					{products.length > 0 ?
						<div className="product-list-component">
							<div className="row" style={{ marginBottom: '50px' }}>
								{productsInView.map((product, index) =>
									<div className={'col-xl-2 col-md-4 col-sm-6 col-6 col-grid-box'} key={index}>
										<ProductListItem product={product} key={index} />
									</div>)
								}
							</div>

							<ReactPaginate
								previousLabel={'previous'}
								nextLabel={'next'}
								breakLabel={'...'}
								breakClassName={'break-me'}
								pageCount={pageCount}
								forcePage={model.currentProductPage}
								marginPagesDisplayed={isMobile ? 1: 2}
								pageRangeDisplayed={isMobile ? 2 : 5}
								onPageChange={this.handlePageClick}
								containerClassName={'pagination justify-content-end'}
								pageClassName={'page-item'}
								pageLinkClassName={'page-link'}
								nextLinkClassName={'page-link'}
								previousLinkClassName={'page-link'}
								disabledClassName={'page-item disabled'}
								activeClassName={'page-item active'}
							/>
						</div>
						:
						<div className="row">
							<div className="col-sm-12 text-center section-b-space mt-5 no-found" >
								<img alt="No Products Found" src={`https://swad.nyc3.cdn.digitaloceanspaces.com/patelbros/icons/empty-search.jpg`}
									className="img-fluid mb-4" />
								<h3>No Products Found</h3>
							</div>
						</div>
					}
				</div>
			</div>
		);
	}
}
