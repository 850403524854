import React, {Component} from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import Breadcrumb from "../common/breadcrumb";
import SessionCheck from "../common/SessionCheck";
import {
  parseMoney, multiplyByQty, addCost, subtractCost, moneyFormat
} from '../helpers';

const moment = require('moment');

class OrderDetails extends Component {

    render (){
      if (!this.props.user) {
        return <Redirect to={'/'} />;
      }

        const {order} = this.props.location.state;
        let reservedSlot = order.selected_time_slot;
        let items = order.products;
        let products = this.props.products;

        let checkoutTotal = order.total;

        let subtotal = 0.0;
        let tax = 0.0;
        for (let li of items) {
          tax = addCost( tax, li.tax_amount )
          subtotal = addCost(subtotal, multiplyByQty(li.price, li.qty) )
        }

        if (order.order_status === 'Refunded' && order.refund_amount) {
          checkoutTotal = moneyFormat( subtractCost(order.total, order.refund_amount) )
        }
        if (order.order_status === 'Cancelled') {
          checkoutTotal = "$0.00";
        }

        return (
            <div>
              <SessionCheck />
              <Breadcrumb title={'Order Details'}/>

                <section className="section-b-space light-layout">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="success-text">
                                    <h2>#{order.order_num}</h2>
                                    <h3>Your Curbside Pickup Date:</h3>
                                    <h4 style={{fontSize: '28px', fontWeight: 'bold'}}>
                                      {moment(new Date(reservedSlot.pickup_date).toISOString()).format('dddd MMM DD, YYYY')}<br/>{reservedSlot.pickup_time}
                                    </h4>
                                    <h4 style={{fontSize: '28px', fontWeight: 'bold'}}>{order.store.name}</h4>
                                    <h3>{order.order_status === 'Pending' ? 'Est.' : ''} Total: {checkoutTotal}</h3>
                                    {order.order_status === 'Refunded' ?
                                      (
                                        <h3 style={{color: 'red', fontWeight: 'bold'}}>Status: {order.payment_status} Processed</h3>
                                      )
                                      :
                                      (
                                        <h3 style={{color: `${order.order_status === 'Completed' ? 'green' : (order.order_status === 'Cancelled' ? 'red' : '')}`, fontWeight: `bold`}}>Status: {order.order_status}</h3>
                                      )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-b-space">
                    <div className="container">
                        <div className="row order-page-details">
                          <h3 className="order-details-title">your order details</h3>
                            <div className="col-lg-12">
                                <div className="product-order">
                                <div className="row product-order-detail" style={{padding: '30px 0'}}>
                                    <div className="col-3">
                                    </div>
                                    <div className="col-3 order_detail">
                                        <div>
                                            <h4><b>description</b></h4>
                                        </div>
                                    </div>
                                    <div className="col-3 order_detail" >
                                        <div>
                                            <h4><b>quantity</b></h4>
                                        </div>
                                    </div>
                                    <div className="col-3 order_detail" style={{textAlign: 'right'}}>
                                        <div style={{width: '100%'}}>
                                            <h4><b>price</b></h4>
                                        </div>
                                    </div>
                                </div>
                                    {items.map((item, index) => {
                                      var allProducts = products.slice();
                                      // eslint-disable-next-line
                                      var product = allProducts.find(el => el.store_products.findIndex(s => parseInt(item.sp_id) === parseInt(s.id)) !== -1);
                                      var isPerLb = product.tags.findIndex(t => t && t.toLowerCase() === 'per lb') !== -1;

                                      return <div className="row product-order-detail" key={index}>
                                          <div className="col-3">
                                          <img src={product.images[0]} alt={`Order-detail-Item ${product.name}-${index}`} className="img-fluid" style={{objectFit: 'contain'}}/>
                                          </div>
                                          <div className="col-3 order_detail">
                                              <div>
                                                  <h4>{product.name}</h4>
                                                  <h5>{isPerLb ? '' : item.variant}</h5>
                                              </div>
                                          </div>
                                          <div className="col-3 order_detail"  style={{backgroundColor: (item.altered_qty ? 'yellow' : '')}}>
                                              <div>
                                                  <h5>
                                                  {item.qty}
                                                  {product.tags.map(t => {
                                                      if (t === 'per lb') {
                                                        //eslint-disable-next-line
                                                        return ` Lb${item.qty != 1 ? 's' : ''}`
                                                      } else {
                                                        return ''
                                                      }
                                                    })
                                                  }
                                                  {` @ `}{moneyFormat(item.price)}
                                                  {item.refund_qty ? <span style={{color: 'red'}}><br/>(Refunded {item.refund_qty}/{item.qty})</span> : ''}
                                                  {item.altered_qty && !item.refund_qty ? <span><br/>(Quantity {item.altered_qty})</span> : ''}
                                                  </h5>
                                              </div>
                                          </div>
                                          <div className="col-3 order_detail" style={{textAlign: 'right'}}>
                                              <div style={{width: '100%'}}>
                                                  {item.refund_qty ?
                                                    <h5 style={{color: 'red'}}>
                                                      {moneyFormat( multiplyByQty( item.price, (parseFloat(item.qty) - parseFloat(item.refund_qty)) ) )}
                                                    </h5>
                                                    :
                                                    <h5>
                                                      {moneyFormat( multiplyByQty(item.price, item.qty) )} {item.tax_amount && parseMoney(item.tax_amount) > 0 ?  <span style={{fontSize: '10px'}}>+ tax</span> : null}
                                                    </h5>
                                                  }
                                              </div>
                                          </div>
                                      </div>
                                    })}
                                </div>
                            </div>

                            <div className="total-sec">
                                <ul>
                                    <li>subtotal <span>{moneyFormat(subtotal)}</span></li>
                                    {subtotal < this.props.store.minimum_required && (this.props.store.shopping_fee && parseMoney(this.props.store.shopping_fee) > 0) ?
                                      (
                                        <li>Personal Shoppers Fee <span>{moneyFormat(this.props.store.shopping_fee)}</span></li>
                                      )
                                      : ''
                                    }
                                    <li>Tax <span>{moneyFormat(tax)}</span></li>
                                </ul>
                            </div>
                            <div className="final-total">
                                <h3>{order.order_status === 'Pending' ? 'est.' : ''} total <span>{moneyFormat(checkoutTotal)}</span></h3>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    cart: state.cartList.cartObj,
    user: state.userData.user,
    products: state.data.products,
    store: state.storeData.selected_store,
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps
)(OrderDetails)
