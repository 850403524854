const currency = require('currency.js');

export function parseMoney(money) {
  return currency(money).value;
}

export function multiplyByQty(money, qty) {
  return currency(money).multiply(qty).value;
}

export function addCost(money1, money2) {
  return currency(money1).add(money2).value;
}

export function subtractCost(money1, money2) {
  return currency(money1).subtract(money2).value;
}

export function moneyFormat(money) {
  return `$${currency(money).format()}`;
}

export function getTaxAmount(item, product) {
  if (product.tax_rate) {
    return multiplyByQty( multiplyByQty(item.price, item.qty), product.tax_rate );
  }

  return 0;
}