import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import Breadcrumb from "../common/breadcrumb";
import SessionCheck from "../common/SessionCheck";
import { connect } from 'react-redux'
import store from '../../store';
import { logoutUser, updatePreferences } from '../../actions'
import { AsYouType } from 'libphonenumber-js'

var axios = require('axios');

class ChangePreferences extends Component {

    constructor (props) {
        super (props)

        this.state = {
          marketing_opt_in: this.props.user.marketing_opt_in,
          get_order_texts: this.props.user.get_order_texts,
          phone_number: this.props.user && this.props.user.phone_number ? new AsYouType('US').input(this.props.user.phone_number) : '',
          warning: null,
          successText: null
        }
    }

    onChange = (e) => {
      this.setState({marketing_opt_in: e.target.checked})
    }

    onChangeTexts = (e) => {
      this.setState({get_order_texts: e.target.checked})
    }

    onChangePhone = (e) => {
      this.setState({phone_number: e.target.value})
    }

    updatePreferences = () => {
      let phoneNumber = ''
      if (this.state.phone_number != '') {
        phoneNumber = new AsYouType('US').input(this.state.phone_number);
      }
      axios.post('/api/shop/change_preferences', {marketing_opt_in: this.state.marketing_opt_in, get_order_texts: this.state.get_order_texts, phone_number: phoneNumber})
      .then(({data}) => {
        store.dispatch(updatePreferences(this.state.marketing_opt_in, this.state.get_order_texts))
          this.setState({warning: null, successText: 'Your preferences have been updated!'})

      })
      .catch(error => {
        this.setState({warning: ''})
      })
    }

    logOut = e => {
      e.preventDefault();

      store.dispatch(logoutUser());
    }

    render (){
      if (!this.props.user) {
        return <Redirect to={'/'} />;
      }

        return (
            <div>
              <SessionCheck />
                <Breadcrumb title={'Account / My Preferences'}/>


                {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="account-sidebar">
                                  <Link to={`${process.env.PUBLIC_URL}/account/dashboard`} style={{color: 'white'}}>
                                    Back
                                  </Link>
                                </div>
                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                    <span className="filter-back">
                                        <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                    </span>
                                    </div>
                                    <div className="block-content">
                                        <ul>
                                            <li tabIndex={0}><Link to={`${process.env.PUBLIC_URL}/account/dashboard`}>Account Info</Link></li>
                                            <li tabIndex={0}><Link to={`${process.env.PUBLIC_URL}/account/orders`}>My Orders</Link></li>
                                            <li tabIndex={0}><Link to={`${process.env.PUBLIC_URL}/account/profile/change-password`}>Change Password</Link></li>
                                            <li tabIndex={0} className="active"><Link to={`${process.env.PUBLIC_URL}/account/profile/change-preferences`}>My Preferences</Link></li>
                                            <li tabIndex={0} className="last"><a href="/" onClick={this.logOut}>Log Out</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        <div className="page-title">
                                            <h2>Edit My Preferences</h2>
                                        </div>
                                        <div className="box-account box-info">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="box">
                                                        <div className="box-title">
                                                        </div>
                                                        <div className="box-content">
                                                              <div className="form-row">
                                                                  <div className="col-md-12" style={{padding: '10px 0'}}>
                                                                  <h4>Email Subscriptions</h4>
                                                                    <label className="form-control" >
                                                                      <input type="checkbox" name="marketing_opt_in" defaultChecked={this.props.user.marketing_opt_in} id="marketing_opt_in" required="" onChange={this.onChange}/>
                                                                      {" "}Keep me up to date on news and exclusive offers
                                                                    </label>
                                                                  </div>
                                                              </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-account box-info">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="box">
                                                            <div className="box-title">
                                                            </div>
                                                            <div className="box-content">
                                                                  <div className="form-row">
                                                                      <div className="col-md-12" style={{padding: '10px 0'}}>
                                                                      <h4>Order Updates</h4>
                                                                          <div className="col-sm-12">
                                                                            <p style={{marginTop: '10px'}}>
                                                                              Phone Number:
                                                                              <input className="form-control" type="text" name="phone_number" defaultValue={this.state.phone_number} onChange={this.onChangePhone}/>
                                                                            </p>
                                                                          </div>
                                                                          <label className="form-control" >
                                                                          <input type="checkbox" name="get_order_texts" defaultChecked={this.props.user.get_order_texts} id="get_order_texts" required="" onChange={this.onChangeTexts}/>
                                                                          {" "}I agree that Patel Bros can send me order pick up information via text messages to the number above. Msg and data rates may apply. Other important terms and conditions apply, including an agreement to resolve any disputes only by individual arbitration (no court trial, no class actions).  To opt out at any time, reply to message with STOP.
                                                                        </label>
                                                                      </div>
                                                                      <div className="col-md-12" style={{padding: '10px 0'}}>
                                                                        {this.state.warning ?
                                                                          <p style={{color: 'red'}}>{this.state.warning}</p>
                                                                          :
                                                                          ''
                                                                        }
                                                                        {this.state.successText ?
                                                                          <p style={{color: '#129e51'}}>{this.state.successText}</p>
                                                                          :
                                                                          ''
                                                                        }
                                                                        <button aria-labelledby="Save preferences" onClick={this.updatePreferences} className="btn btn-solid">Save</button>
                                                                      </div>
                                                                  </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                              </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.userData.user,
})

export default connect(
    mapStateToProps, {logoutUser, updatePreferences}
)(ChangePreferences)
