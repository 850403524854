import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import storeDispatch from '../../store';
import {getCartTotal} from "../../services";
import {reserveTimeSlot, completeCheckout, errorOnCheckout, getOrLoginUser, clearStores, receiveStores, refreshHomeStore, refreshGuestHomeStore, logoutUser} from "../../actions";
import {
  parseMoney, addCost, subtractCost, multiplyByQty, moneyFormat, getTaxAmount,
  merchantActionURL, merchantAuthType, merchantCtrl, logMerchantResults
} from '../helpers';

const moment = require('moment');
const axios = require('axios');

const ApiContracts = require('authorizenet').APIContracts;

class AuthNetNewCommunicator extends Component {
    constructor(props) {
      super (props)

      window.AuthorizeNetIFrame = {};

      window.AuthorizeNetIFrame.onReceiveCommunication = function (querystr) {
        var params = this.parseQueryString(querystr);
          switch (params["action"]) {
            case "successfulSave":
              break;
            case "cancel":
            console.log('cancel')
              this.props.history.push({
                pathname: '/'
              });
              break;
            case "resizeWindow":
              break;

            case "transactResponse":
                var response = JSON.parse(params['response']);
                this.completeCheckout(response);
                var iframe = document.getElementById("add_payment");
                iframe.style.display = 'none';

              break;
            default:
              break;
            }
        }.bind(this);
    }

    parseQueryString = (str) => {
      var vars = [];
      var arr = str.split('&');
      var pair;
      for (var i = 0; i < arr.length; i++) {
        pair = arr[i].split('=');
        vars.push(pair[0]);
        vars[pair[0]] = unescape(pair[1]);
        }
      return vars;
    }

    completeCheckout = (transactResponse) => {
      var transactionId = transactResponse['transId'];

      var authorized = true;
      var orderIDSplit = transactResponse['orderInvoiceNumber'].split('-');
      let orderID = orderIDSplit[0];
      var billTo = transactResponse['billTo'];
      var full_billing_address = `${billTo['address']} ${billTo['city']}, ${billTo['state']} ${billTo['zip']}`;
      var phone = billTo['phoneNumber'];
      var checkoutTotal = transactResponse['totalAmount'];
      var last_four = transactResponse['accountNumber'];

      var original_auth_amount = parseMoney(checkoutTotal);
      var total = original_auth_amount;

      let itemTotal = 0.0;
      let hasProduce = false;
      let tax = 0.0;
      try {
        for (let item of this.props.cartItems) {
          itemTotal = addCost(itemTotal, multiplyByQty(item.price, item.qty));
          let product = this.props.products.find(el => el.store_products.findIndex(s => parseInt(s.id) === parseInt(item.sp_id)) !== -1);

          if (product) {
            if (product.category.toLowerCase() == 'produce') {
              hasProduce = true;
            }
          } else {
            axios.post("/api/shop/log_error", {error: "Product not found", info: `${this.props.user.id} - ${item.id} ${item.sp_id} Product not found when checking for taxes (Transcation Happened)`}).then(({data}) => { }).catch(er => { })
          }

          if (item.tax_amount) {
            tax = addCost(tax, item.tax_amount);

          } else {
            tax = addCost(tax, getTaxAmount(item, product));
          }
        }
      } catch (e) {
        axios.post("/api/shop/log_error", {error: e, info: `${this.props.user.id} - ${e} ERROR ON CHECKOUT TAXES CALCULATION (Transcation Happened)`}).then(({data}) => { }).catch(er => { })
      }

      itemTotal = addCost(itemTotal, tax);

      //remove pre auth from order total, but keep it for the original authorization amount
      if (hasProduce) {
        total = subtractCost(total, 20);
        itemTotal = addCost(itemTotal, 20);
      }

      if (itemTotal < this.props.store.minimum_required && (this.props.store.shopping_fee && parseMoney(this.props.store.shopping_fee) > 0)) {
          itemTotal = addCost(itemTotal, this.props.store.shopping_fee);
      }


      if (moneyFormat(original_auth_amount) < moneyFormat(itemTotal)) { //these should match
          axios.post("/api/shop/log_error", {error: `${orderID}`, info: `${orderID} - Totals Do Not Match Up on Checkout, Cancelled Transaction - Item total is ${itemTotal} and Authorized amount is ${original_auth_amount}`}).then(({data}) => { }).catch(er => { })
          storeDispatch.dispatch(errorOnCheckout());

          this.props.history.push({
              pathname: '/'
          })

          return;
      }

      let hasFee = false
      if (this.props.total < this.props.store.minimum_required && (this.props.store.shopping_fee && parseMoney(this.props.store.shopping_fee) > 0)) {
        hasFee = true;
      }

      let subtotal = subtractCost(total, tax);

      // complete checkout payment
      axios.post('/api/shop/checkout', {
        order_num: orderID, last_four: last_four, original_auth_amount: original_auth_amount, total_tax: tax, transaction_id: transactionId, prefix: this.props.store.order_prefix, authorized: authorized, subtotal: subtotal,
        total: total, cart_id: this.props.cart.id, user_id: this.props.user.id, slot_id: this.props.reservedSlot.id, store_id: this.props.store.id,
        billing_address: full_billing_address, address: billTo['address'], city: billTo['city'], state: billTo['state'], phone: phone, zip_code: billTo['zip'], charged_shopping_fee: hasFee ? this.props.store.shopping_fee : null})
      .then(({data}) => {

        try {
          var lineAsProducts = this.props.products.filter(p => data.order_line_items.find(el => el.sp_id == p.id));
          storeDispatch.dispatch(completeCheckout(data.cart));
          this.props.history.push({
              pathname: '/order/success',
                  state: { hasProduce: hasProduce, hasFee: hasFee, hasTaxable: parseMoney(tax) > 0, order: data.order, reservedSlot: data.time_slot, items: data.order_line_items, products: lineAsProducts, orderTotal: checkoutTotal, symbol: this.props.symbol }
          })
        } catch (e) {
          axios.post("/api/shop/log_error", {error: e, info: `${this.props.user.id} - ERROR ON CHECKOUT (CHECKOUT HAPPENED)`}).then(() => { }).catch(er => { })
          storeDispatch.dispatch(completeCheckout(data.cart));

          this.props.history.push({
            pathname: '/account/orders'
          });

        }
      })
      .catch(error => {
          axios.post("/api/shop/log_error", {error: error, info: `${this.props.user.id} - ERROR ON CHECKOUT`}).then(() => {}).catch(e => {})

        this.props.history.push({
          pathname: '/account/orders'
        });
      })
    }

    render() {
        return (
          <div>
          </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    cart: state.cartList.cartObj,
    user: state.userData.user,
    products: state.data.products,
    reservedSlot: state.userData.slot,
    store: state.storeData.selected_store,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps, {completeCheckout, errorOnCheckout}
)(withRouter(AuthNetNewCommunicator))
