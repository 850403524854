import React from 'react';
import { observer } from 'mobx-react';
import pdListModel1 from '../../../models/ProductListingModel';

interface MyProps {
	onLayoutViewClicked: (colSize:number) => void;
}

@observer
export default class FilterBar extends React.Component<MyProps, {}> {
	//List Layout View
	listLayout() {
		document.querySelector(".collection-grid-view").setAttribute('style', "opacity:0");
		document.querySelector(".product-wrapper-grid").setAttribute('style', "opacity:0.2");
		document.querySelector(".product-wrapper-grid").classList.add("list-view");
		var elems = document.querySelector(".product-list-component .row").childNodes;
		[].forEach.call(elems, function (el) {
			el.className = '';
			el.classList.add('col-lg-12');
		});
		setTimeout(function () {
			document.querySelector(".product-wrapper-grid").setAttribute('style', "opacity: 1");
		}, 500);
	}

	//Grid Layout View
	gridLayout() {
		document.querySelector(".collection-grid-view").setAttribute('style', "opacity:1");
		document.querySelector(".product-wrapper-grid").classList.remove("list-view");
		var elems = document.querySelector(".product-list-component .row").childNodes;
		[].forEach.call(elems, function (el) {
			el.className = '';
			el.classList.add('col-lg-3');
		});
	}

	// Layout Column View
	LayoutView = (colSize) => {
		if (!document.querySelector(".product-wrapper-grid").classList.contains("list-view")) {
			var elems = document.querySelector(".product-list-component .row").childNodes;
			[].forEach.call(elems, function (el) {
				el.className = '';
				el.classList.add('col-lg-' + colSize);
			});
		}

		this.props.onLayoutViewClicked(colSize);
	}

	onSortByChange = (v) => {
		pdListModel1.setFilterSortBy(v);
	}

	render() {
		return (
			<div className="product-filter-content">
				<div className="search-count">
					<h5>Showing <b>{pdListModel1.filters.department ? pdListModel1.filters.department : 'All'}</b> Products ({pdListModel1.filteredAndSortedProducts.length} Results)</h5>
				</div>

				<div className="product-page-filter">
					<select aria-label="Sort options" aria-haspopup={true} value={pdListModel1.filters.sortBy} onChange={(e) => this.onSortByChange(e.target.value)}>
						<option value="">Sorting items</option>
						<option value="HighToLow">Price: High to Low</option>
						<option value="LowToHigh">Price: Low to High</option>
						<option value="Newest">Newest Items</option>
						<option value="AscOrder">Sort By Name: A To Z</option>
						<option value="DescOrder">Sort By Name: Z To A</option>
					</select>
				</div>
			</div>
		)
	}
}
