import React, {Component} from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import {
  parseMoney, multiplyByQty, addCost, subtractCost, moneyFormat
} from '../helpers';

const moment = require('moment');
const axios = require('axios');

class orderSuccess extends Component {
    constructor (props) {
        super (props);

        let userPickupDetails = this.props.user && this.props.user.pickup_details ? this.props.user.pickup_details : [];

        this.state = {
          car: userPickupDetails[0] || '',
          license: userPickupDetails[1] || '',
          pointOfContact: userPickupDetails[2] || '',
          savedText: ''
        };
    }

    onChange = (e) => {
      let obj = {};
      obj[e.target.name] = e.target.value;
      this.setState(obj);
    }

    saveUserDetails = () => {
      if (this.props.user) {
        axios.post('/api/shop/update_user_pickup_info', {pickup_details: [this.state.car, this.state.license, this.state.pointOfContact], user_id: this.props.user.id})
        .then(({data}) => {
          this.setState({savedText: 'Saved!'});
        })
        .catch(error => {

        });
      }
    }

    render() {
      if (!this.props.user || !this.props.store) {
        return <Redirect to={'/'} />;
      }

        const {items, orderTotal, reservedSlot, order, hasProduce, hasTaxable, hasFee} = this.props.location.state;
        const {products} = this.props;

        let subtotal = parseMoney(orderTotal);
        let checkoutTotal = parseMoney(orderTotal);

        // Remove the pre-auth charge added with produce items from the total to get correct subtotal amount
        if (hasProduce) {
          subtotal = subtractCost(subtotal, 20.0);
        }
        if (hasFee) {
          subtotal = subtractCost(subtotal, this.props.store.shopping_fee);
        }

        // Calculate tax from line items - this should be changed to use order.total_tax
        let tax = 0.0;
        if (hasTaxable) {
          for (let item of items) {
            if (item.tax_amount) {
              tax = addCost( tax, item.tax_amount )
            }
          }
        }
        //Remove tax from subtotal so subtotal equals only the price of each item pre-tax
        subtotal = subtractCost(subtotal, tax)

        let userPickupDetails = this.props.user.pickup_details || [];

        return (
          <div>
            <section className="section-b-space light-layout">
              <div className="container">

                <div className="row">
                  <div className="col-md-12">
                    <div className="success-text">
                      <i className="fa fa-check-circle" aria-hidden="true"></i>
                      <h2>thank you</h2>
                      <h2>{this.props.store.name}</h2>
                      <p>Your Payment has been authorized</p>
                      <p>Order ID: #{order.order_num}</p>
                      <h3>Your Curbside Pickup Date:</h3>
                      <h4 style={{fontSize: '28px', fontWeight: 'bold'}}>
                        {moment(new Date(reservedSlot.pickup_date).toISOString()).format('dddd MMM DD, YYYY')}<br/>
                        <span style={{textTransform: 'uppercase'}}>{reservedSlot.pickup_time}</span>
                      </h4>
                      <h4 style={{padding: '10px 20px', fontWeight: 'bold', textTransform: 'none'}}>
                        You will receive a text or e-mail letting you know your order is ready for pick up.
                        <br/>
                        Please make sure to add our e-mail shop@patelbros.com to your trusted contacts so it does not go into spam.
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="row" style={{paddingTop: '10px'}}>
                  <div className="col-lg-12">
                    <div className="row order-success-sec">
                      <div className="col-md-12">
                        <div>
                          <p><span style={{fontWeight: 'bold'}}>Pickup Details:</span> Please enter some OPTIONAL details so we know which car we should drop the items to.</p>
                          <form className="form">
                            <h3 className="text-success">{this.state.savedText}</h3>
                            <input className="form-control" defaultValue={userPickupDetails.length > 0 ? userPickupDetails[0] : ''} type='text' name='car' placeholder="Pickup Contact's Car Color and Model" onChange={this.onChange} />
                            <input className="form-control" defaultValue={userPickupDetails.length > 1 ? userPickupDetails[1] : ''}type='text' name='license' placeholder="Pickup Contact's License No."  onChange={this.onChange} />
                            <input className="form-control" defaultValue={userPickupDetails.length > 2 ? userPickupDetails[2] : ''} type='text' name='pointOfContact' placeholder="Pickup Contact's Phone Number" onChange={this.onChange} />
                            <p style={{cursor: 'pointer'}} className="form-control btn btn-success" onClick={this.saveUserDetails}>Save</p>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </section>

            <section className="section-b-space">
              <div className="container">
                <div className="row">
                  <h3 className="order-details-title">your order details</h3>
                    <div className="col-lg-12">
                      <div className="product-order">
                      {items.map((item, index) => {
                        var product = products.find(el => el.store_products.findIndex(s  => parseInt(item.sp_id) === parseInt(s.id)) !== -1);
                        let store_product = product.store_products.find(el => el.id == item.sp_id);

                        var isPerLb = false;
                        if (product.tags) {
                          isPerLb = product.tags.findIndex(t => t && t.toLowerCase() === 'per lb') !== -1;
                        }

                        return (
                          <div className="row product-order-detail" key={index}>

                            <div className="col-3">
                              <img src={product.images[0]} alt={`Order-Success ${product.name}-${index}`} className="img-fluid" style={{objectFit: 'contain'}} onError={(e) => {
                                e.target.src = 'https://swad.nyc3.cdn.digitaloceanspaces.com/image-placeholder.jpg'
                              }} />
                            </div>

                            <div className="col-3 order_detail">
                              <div>
                                <h4>{product.name}</h4>
                                <h5>{isPerLb ? '' : item.variant}</h5>
                              </div>
                            </div>

                            <div className="col-3 order_detail text-right">
                              <div style={{width: '100%'}}>
                                <h4>quantity</h4>
                                <h5>
                                  {item.qty}
                                  {product.tags.map(t => {
                                    if (t === 'per lb') {
                                      return ` Lb${parseInt(item.qty) !== 1 ? 's' : ''}`
                                    } else {
                                      return ''
                                    }
                                  })}
                                  {" "} @ {moneyFormat(item.price)} {isPerLb ? '/ lb' : null} {store_product.sale_price ? <span style={{textDecoration: 'line-through', color: 'red'}}>{store_product.price}</span> : null}
                                </h5>
                              </div>
                            </div>

                            <div className="col-3 text-right order_detail">
                              <div style={{width: '100%'}}>
                                <h4>price</h4>
                                <h5>
                                  {moneyFormat( multiplyByQty(item.price, item.qty) )} {item.tax_amount && parseMoney(item.tax_amount) > 0 ?  <span style={{fontSize: '10px'}}>+ tax</span> : null}
                                </h5>
                              </div>
                            </div>

                          </div>
                        );
                      })}
                      <div className="success-page-totals">
                        <div className="total-sec">
                          <ul>
                            <li>subtotal <span>{moneyFormat(subtotal)}</span></li>
                            <li>Tax <span>{moneyFormat(tax)}</span></li>
                            { hasProduce ?
                              <li>Pre-Authorization for Produce <span>$20.00</span></li>
                              : null
                            }
                            { hasFee ?
                              <li>Personal Shoppers Fee <span>{moneyFormat(this.props.store.shopping_fee)}</span></li>
                              : null
                            }
                          </ul>
                        </div>
                        <div className="final-total">
                          <h3>est. total <span>{moneyFormat(checkoutTotal)}</span></h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.userData.user,
    products: state.data.products,
    store: state.storeData.selected_store,
});

export default connect(
    mapStateToProps
)(orderSuccess);
