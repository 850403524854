import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import Breadcrumb from "../../common/breadcrumb";
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

class Refund extends Component {
    render (){
        return (
          <div>
              {/*SEO Support*/}
              <Helmet>
                  <title>Patel Brothers | Refund Policy</title>
              </Helmet>
              {/*SEO Support End */}

              <Breadcrumb title={'Refund Policy'}/>

              <section className="section-b-space">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <h2 style={{padding: '20px 0'}}>Refund Policy</h2>
                      <h3><strong>Failure to Pick Up</strong></h3>
                      <h3>We reserve the right to charge a $9.99 re-stocking fee for any orders that are abandoned and not picked-up in a timely fashion.  We will make attempts to contact you, but if we are unable to reach you then we will have to re-stock to avoid spoilage. </h3>
                      <h3><strong>Returns</strong></h3>
                      <h3>Please click <Link to={`${process.env.PUBLIC_URL}/account/orders`}>here</Link> to submit a refund request.<br/></h3>
                      <h3>
                        We do not offer returns on any items at this time unless there is a material defect with the product (such as it is expired or has gone bad).  Non-perishable goods: such defect must be reported to us within 24 hours of receiving the product. Perishable goods: defects must be reported the same day (vegetables, dairy etc).
                      </h3>
                      <h3><br/> To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging. <br/><br/> To complete your return, we require a receipt or proof of purchase. <br/> Please do not send your purchase back to the manufacturer.

                      <br/> <br/> <strong>Refunds (if applicable)</strong> <br/>Please request a refund by navigating to Dashboard {"->"} My Orders. If the refund is approved or granted you will receive an e-mail with an updated receipt. </h3>
                      <h3><br/> <br/> <strong>Late or missing refunds (if applicable)</strong> <br/> If you haven’t received a refund yet, first check your bank account again. <br/> Then contact your credit card company, it may take some time before your refund is officially posted. <br/> Next contact your bank. There is often some processing time before a refund is posted. <br/>
                      If you’ve done all of this and you still have not received your refund yet,
                      please contact us at {this.props.store.email}. <br/> <br/> <strong>Sale items (if applicable)</strong> <br/> Only regular priced items may be refunded, unfortunately sale items cannot be refunded. <br/><br/></h3>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )
    }
}

const mapStateToProps = (state) => ({
    store: state.storeData.selected_store
})

export default connect(
    mapStateToProps
)(Refund)
