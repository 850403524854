import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from "react-redux";
import NumberFormat from 'react-number-format';

import {
  multiplyByQty, moneyFormat,
} from '../../../helpers';

const CartHeader  = ({editQty, changeQty, item, total, symbol, removeFromCart, product, hideCart, totalStock, selectedVariant, isProduce, saleOriginalPrice}) => (
    <li >
        <div className="media">
            <Link to={`${process.env.PUBLIC_URL}/product/${product ? product.id : ''}`}><img alt={`${product ? product.name : 'Empty Cart Item'}`} className="mr-3" src={`${product ? product.images[0] : ''}`} onError={(e) => {
              e.target.src = 'https://swad.nyc3.cdn.digitaloceanspaces.com/image-placeholder.jpg'
            }} /></Link>
            <div className="media-body">
                <Link role="button" aria-labelledby="Hide cart" onClick={hideCart} to={`${process.env.PUBLIC_URL}/product/${product ? product.id : ''}`}><h4>{product ? product.name : ''} {item.variant && product && product.tags && product.tags.findIndex(t => t == 'per lb') === -1 ? `- ${item.variant}` : ''}</h4></Link>
                {saleOriginalPrice ?
                  <h5>
                    <span className="td-color" style={{marginRight: '2px'}}>
                      {moneyFormat( multiplyByQty(item.price, item.qty) )}
                    </span>
                    {product.taxable ? <span style={{fontSize: '10px', textTransform: 'lowercase'}}> + tax </span> : null }
                    <span className="smaller">
                      ({moneyFormat(item.price)} {saleOriginalPrice ? <span className="smaller" style={{textDecoration: 'line-through', color: 'red', fontSize: '10px'}}> {moneyFormat(saleOriginalPrice)}</span> : null}{isProduce ? ` / lb` : ` ea.`})
                    </span>
                  </h5>
                  :
                  <h5>
                    <span className="td-color">{moneyFormat( multiplyByQty(item.price, item.qty) )}</span>
                    {product.taxable ? <span style={{fontSize: '10px', textTransform: 'lowercase'}}> + tax </span> : ' ' }
                    <span className="smaller">({isProduce ? `${moneyFormat(item.price)} / lb` : `${moneyFormat(item.price)} ea.`})</span>
                  </h5>
                }

                <div className="qty-box">
                    <div className="input-group">

                    <span className="input-group-prepend">
                        <button aria-labelledby="Decrease quantity" type="button" className='btn quantity-left-minus disable-dbl-tap-zoom' onClick={() => editQty(item, product, selectedVariant,'decrement')} data-type="minus" data-field="">
                         <i className="fa fa-angle-left"></i>
                        </button>
                    </span>

                    <NumberFormat allowNegative={false} value={item.qty} decimalScale={isProduce !== -1 ? 2 : 0} className="form-control input-number" onValueChange={(values) => changeQty(item, product, selectedVariant, totalStock, values )}/>

                    <span className="input-group-prepend">
                    {/*eslint-disable-next-line*/}
                    { totalStock > 0 && item.qty == totalStock ?
                      (<button aria-labelledby="Increase quantity" aria-disabled={true} className={`btn quantity-right-plus disable-dbl-tap-zoom disabled`}  data-type="plus">
                      <i className="fa fa-angle-right"></i>
                      </button>
                      )
                      :
                      (<button aria-labelledby="Increase quantity" className={`btn quantity-right-plus disable-dbl-tap-zoom`} onClick={() => editQty(item, product, selectedVariant, 'increment')}  data-type="plus">
                      <i className="fa fa-angle-right"></i>
                      </button>
                    )
                    }

                    {product.tags.map((t, i) => {
                        if (t === 'per lb') {
                          return <span key={i} style={{marginLeft: '10px', alignSelf: 'center', fontSize: '18px'}}>
                           {/* eslint-disable-next-line */}
                           Lb{item.qty != 1 ? 's' : ''}
                          </span>
                        } else {
                          return ''
                        }
                      })
                    }
                    </span>

                   </div>
                </div>
                <p role="button" aria-labelledby="Remove item from cart" tabIndex={0} onClick={ removeFromCart}>
                Remove
                </p>
            </div>
        </div>
    </li>
)


const mapStateToProps = (state, ownProps) => {
    let productId = ownProps.item.sp_id;
    return {
        //eslint-disable-next-line
        product: state.data.products.find(el => el.store_products.findIndex(p => p.id == productId) !== -1),
        symbol: state.data.symbol
    }
}

export default connect(mapStateToProps) (CartHeader);
