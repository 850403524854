import React, {Component} from 'react';
import QtyMeasure from './qtyMeasure';

class DetailsWithPrice extends Component {

    constructor (props) {
        super (props)

        this.state = {
            open:false,
            quantity: this.props.item.tags.findIndex(t => t === 'per lb') !== -1 ? '1.0' : '1',
            stock: '',
            nav3: null,
            selectedVariant: this.props.item.variants.length > 0 ? this.props.item.variants[0] : null,
            isProduce: this.props.item.tags.findIndex(t => t === 'per lb') !== -1,
            selectedItem: this.props.item,
        }
    }

    componentDidMount() {
        this.setState({
            nav3: this.slider3
        });

        var item = this.props.item;
        //even tho store_products is an array, the first variant will always show up, so we make the first variant the default product details.
        let storeProduct = item.store_products[0];

        item.id = storeProduct.id; // Since we will be using this id for line_items, let's make this the default id


        if (storeProduct.tags && storeProduct.tags.findIndex(s => s.toLowerCase() === 'per lb') !== -1) {
          item.price = item.original_price ? item.original_price : item.price;

        } else {
          if (!storeProduct.price) {
            item.price = item.original_price;
          } else {
            item.price = storeProduct.price;
          }
        }

        item.sale_price = null;
        if (storeProduct.sale_price) {
          item.sale_price = storeProduct.sale_price;
        } else {
          if (item.original_sale_price) {
            item.sale_price = item.original_sale_price;
          }
        }

        item.sku = storeProduct.sku;
        item.barcode = storeProduct.barcode;
        item.stock = storeProduct.stock;

        item.in_stock = storeProduct.in_stock;

        item.aisle_description = storeProduct.aisle_description;
        item.aisle = storeProduct.aisle;
        item.shelf = storeProduct.shelf;

        item.thumbnail = storeProduct.thumbnail;

        //THIS IS IMPORTANT TO KEEP EVEN THO IT IS A DUPLICATE!!!! THIS ENSURES THAT THE ADD CART ACTION WORKS PROPERLY AS THAT ACTION USES BOTH SP_ID FROM LINE ITEMS AND PRODUCTS
        item.sp_id = storeProduct.id;

        this.setState({quantity: this.state.isProduce ? '1.0' : '1', selectedVariant: this.props.item.variants[0], selectedItem: this.props.item});
        //eslint-disable-next-line
        this.setState({stock: this.state.selectedItem.stock == (this.state.isProduce ? '1.0' : '1') ? 'You have reached the purchase limit.' : ''});

    }

    selectVariant = e => {
      e.preventDefault();
      var item = this.props.item;
      //even tho store_products is an array, the first variant will always show up, so we make the first variant the default product details.
      let storeProduct = item.store_products[e.target.id];

      item.id = storeProduct.id; // Since we will be using this id for line_items, let's make this the default id

      if (!storeProduct.price) {
        item.price = item.original_price;
      } else {
        item.price = storeProduct.price;
      }

      item.sale_price = null;
      if (storeProduct.sale_price) {
        item.sale_price = storeProduct.sale_price;
      } else {
        if (item.original_sale_price) {
          item.sale_price = item.original_sale_price;
        }
      }

      item.sku = storeProduct.sku;
      item.barcode = storeProduct.barcode;
      item.stock = storeProduct.stock;

      item.in_stock = storeProduct.in_stock;

      item.aisle_description = storeProduct.aisle_description;
      item.aisle = storeProduct.aisle;
      item.shelf = storeProduct.shelf;

      item.thumbnail = storeProduct.thumbnail;

      //THIS IS IMPORTANT TO KEEP EVEN THO IT IS A DUPLICATE!!!! THIS ENSURES THAT THE ADD CART ACTION WORKS PROPERLY AS THAT ACTION USES BOTH SP_ID FROM LINE ITEMS AND PRODUCTS
      item.sp_id = storeProduct.id;

      this.setState({quantity: this.state.isProduce ? '1.0' : '1', selectedVariant: this.props.item.variants[e.target.id], selectedItem: this.props.item});

      //eslint-disable-next-line
      this.setState({stock: this.state.selectedItem.stock == (this.state.isProduce ? '1.0' : '1') ? 'You have reached the purchase limit.' : ''});
    }


    selectVariantInput = e => {
      var item = this.props.item;
      //even tho store_products is an array, the first variant will always show up, so we make the first variant the default product details.
      let storeProduct = item.store_products[e.target.value];

      item.id = storeProduct.id; // Since we will be using this id for line_items, let's make this the default id

      if (!storeProduct.price) {
        item.price = item.original_price;
      } else {
        item.price = storeProduct.price;
      }

      item.sale_price = null;
      if (storeProduct.sale_price) {
        item.sale_price = storeProduct.sale_price;
      } else {
        if (item.original_sale_price) {
          item.sale_price = item.original_sale_price;
        }
      }

      item.sku = storeProduct.sku;
      item.barcode = storeProduct.barcode;
      item.stock = storeProduct.stock;

      item.in_stock = storeProduct.in_stock;

      item.aisle_description = storeProduct.aisle_description;
      item.aisle = storeProduct.aisle;
      item.shelf = storeProduct.shelf;

      item.thumbnail = storeProduct.thumbnail;

      //THIS IS IMPORTANT TO KEEP EVEN THO IT IS A DUPLICATE!!!! THIS ENSURES THAT THE ADD CART ACTION WORKS PROPERLY AS THAT ACTION USES BOTH SP_ID FROM LINE ITEMS AND PRODUCTS
      item.sp_id = storeProduct.id;

      this.setState({quantity: this.state.isProduce ? '1.0' : '1', selectedVariant: this.props.item.variants[e.target.value], selectedItem: this.props.item});
      //eslint-disable-next-line
      this.setState({stock: this.state.selectedItem.stock == (this.state.isProduce ? '1.0' : '1') ? 'You have reached the purchase limit.' : ''});
    }


    minusQty = () => {
      if (this.state.isProduce) {
        if(parseFloat(this.state.quantity) > 0) {
            this.setState({stock: ''});
            this.setState({quantity: parseFloat(this.state.quantity) - 0.5})
            //eslint-disable-next-line
            this.setState({totalLbs: `${parseFloat(this.state.quantity)} lb${parseFloat(this.state.quantity) != 1 ? 's' : ''}`});
        }
      } else {
        if(parseInt(this.state.quantity) > 1) {
            this.setState({stock: ''})
            this.setState({quantity: parseInt(this.state.quantity) - 1})
        }
      }
    }

    plusQty = (stockRemaining) => {
      if (this.state.isProduce) {
        if (stockRemaining > 0) { //stock is now purchase limit, if it's 0 there is no purchase limit
          if(stockRemaining > parseFloat(this.state.quantity)) {
            this.setState({quantity: parseFloat(this.state.quantity) + 0.5});
            //eslint-disable-next-line
            this.setState({totalLbs: `${parseFloat(this.state.quantity)} lb${parseFloat(this.state.quantity) != 1 ? 's' : ''}`});

          } else{
              this.setState({stock: 'You have reached the purchase limit.'})
          }

        } else {
          if (stockRemaining == 0) {
            this.setState({quantity: parseFloat(this.state.quantity) + 0.5});
            //eslint-disable-next-line
            this.setState({totalLbs: `${parseFloat(this.state.quantity)} lb${parseFloat(this.state.quantity) != 1 ? 's' : ''}`});
          } else {
            this.setState({stock: 'You have reached the purchase limit.'})
          }
        }

      } else {
        if (stockRemaining > 0) { //stock is now purchase limit, if it's 0 there is no purchase limit
          if(stockRemaining > parseInt(this.state.quantity)) {
              this.setState({quantity: parseInt(this.state.quantity)+1})
          } else{
              this.setState({stock: 'You have reached the purchase limit.'})
          }
        } else {
          if (stockRemaining == 0) {
            this.setState({quantity: parseInt(this.state.quantity)+1})
          } else {
            this.setState({stock: 'You have reached the purchase limit.'})

          }
        }
      }
    }

    changeQty = (e, stockRemaining) => {
      let qty = parseFloat(e.target.value);

      if (this.state.isProduce) {
        if (e.target.value.endsWith('.')) {
          //eslint-disable-next-line
          this.setState({ quantity: e.target.value, totalLbs: `${e.target.value} lb${qty != 1 ? 's' : ''}`});
          return;
        }

        if (stockRemaining > 0) { //stock is now purchase limit, if it's 0 there is no purchase limit
          if(stockRemaining > qty) {
            //eslint-disable-next-line
            this.setState({ quantity: qty, totalLbs: `${qty} lb${qty != 1 ? 's' : ''}`});

          } else {
            //eslint-disable-next-line
            this.setState({ quantity: stockRemaining, totalLbs: `${this.props.item.stock} lb${this.props.item.stock != 1 ? 's' : ''}`, stock: 'You have reached the purchase limit.'});
          }
        } else {
          if (stockRemaining == 0) {
            //eslint-disable-next-line
            this.setState({ quantity: qty, totalLbs: `${qty} lb${qty != 1 ? 's' : ''}`});
          } else {
            this.setState({ quantity: 0, totalLbs: `0 lbs`, stock: 'You have reached the purchase limit.'});
          }
        }

      } else { //Not a per lb produce item
        if (this.state.selectedItem.stock > 0) { //stock is now purchase limit, if it's 0 there is no purchase limit
          if(stockRemaining > qty) {
            this.setState({ quantity: qty });

          } else {
            this.setState({ quantity: stockRemaining, stock: 'You have reached the purchase limit.'});
          }
        } else {
          if (stockRemaining == 0) {
            this.setState({ quantity: qty });
          } else {
            this.setState({ quantity: 0, stock: 'You have reached the purchase limit.'});
          }
        }
      }
    }

    addItemToCart = (item, variant, quantity, cartItem) => {
      if (parseFloat(quantity) == 0.0) {
        return;
      }

      var q = "" + quantity;
      if (q.endsWith('.')) {
        q = quantity + '0';
      }

      let cart_id = this.props.cart_obj ? this.props.cart_obj.id : null;
      this.props.addToCartClicked(item, variant, q, cartItem, cart_id);

      this.setState({quantity: this.state.isProduce ? '1.0' : '1'});
    }

    render (){
        const {item} = this.props
        var stock = item.stock;
        var cart = this.props.cart;

        var category = "";
        if (item.category) {
          category = item.category;
        }

        //eslint-disable-next-line
        var isItemInCart = cart.filter(i => i.sp_id == this.props.item.sp_id);
        if (isItemInCart.length > 0 && stock > 0) {
          var lineItem = isItemInCart[0];
          stock -= lineItem.qty;
          if (stock == 0) {
            stock = -1;
          }
        }

        return (
            <div className="col-lg-6">
                <div className="product-right">
                    <h4 aria-label={`${category}`}>{category}</h4>
                    <h2 aria-label={`${item.name}`}> {item.name}
                    </h2>

                    {item.sale_price ?
                     (
                       <div>
                         <h3 aria-label={`${item.sale_price}`} style={{color: 'red'}}>
                           {item.sale_price}
                           {this.state.isProduce ?
                             (
                               <span aria-label={`per pound`}>
                                 / lb
                               </span>
                             )
                             : ''
                           }
                           {item.taxable ?
                             <span aria-label={`plus tax`} style={{fontSize: '12px', paddingBottom: '10px', fontWeight: 'bold'}}>
                               + tax
                             </span>
                             : ''
                           }
                          </h3>
                          <h4>
                            <del>
                               {item.price}
                                 {this.state.isProduce ?
                                   (
                                     <span aria-label={`per pound`}>
                                       / lb
                                     </span>
                                   )
                                   :
                                   ''}
                             </del>
                          </h4>
                       </div>
                     ) :
                     (
                       <h3 aria-label={`${item.price}`}>
                       {item.price}
                        {this.state.isProduce ?
                          (
                            <span aria-label={`per pound`}>
                            / lb
                            </span>
                          )
                          : ''
                        }
                        {item.taxable ?
                          <span style={{fontSize: '12px', paddingBottom: '10px', fontWeight: 'bold'}}  aria-label={`plus tax`}>
                            + tax
                          </span>
                          : ''
                        }
                       </h3>
                     )
                    }

                    <div className="product-description border-product" style={{minWidth: 200}}>
                      {item.category.toLowerCase() === 'produce' ?
                        <p style={{fontSize: '12px', paddingBottom: '10px', fontWeight: 'bold'}}>
                          *All produce items include a $20 pre-authorization charge on purchases for produce of any size to allow us to adjust - up or down - to the actual weight of an item, which might vary from the estimated weight requested by a customer online.
                        </p>
                        : ''
                      }

                        {!this.state.isProduce && item.variants.length > 0 ?
                            <div>
                              <h6 className="product-title size-text">select</h6>
                              <div className="size-box">
                                {item.variants.length > 4 ?
                                  (
                                    <select defaultValue={item.variants}
                  										onChange={this.selectVariantInput}>
                                      {item.variants.map((size, i) => {
                                        return <option value={`${i}`} key={i}>{size}</option>
                                      })}
                                    </select>
                                  )
                                  :
                                  (
                                    <ul>
                                      {item.variants.map((size, i) => {
                                        return <li key={i} className={this.state.selectedVariant && this.state.selectedVariant.toLowerCase() === size.toLowerCase() ? `active` : ``} style={{borderColor: `${this.props.item.store_products[i].sale_price ? 'red' : '#f7f7f7' }`}}><a id={i} href="/" onClick={this.selectVariant}>{size}</a></li>
                                      })}
                                    </ul>
                                  )
                                }
                              </div>
                            </div>
                            :
                            ''
                          }
                          <p className="text-danger">{this.state.stock}</p>

                          { item.in_stock ?
                            (
                              <QtyMeasure item={this.state.selectedVariant ? this.state.selectedVariant : item} plusQty={() => this.plusQty(stock)} minusQty={this.minusQty} tags={item.tags} changeQty={(e) => this.changeQty(e, stock)} quantity={this.state.quantity} purchaseLimit={this.state.selectedVariant ? this.state.selectedVariant.stock : item.stock}/>
                            )
                            :
                            ''
                          }
                    </div>
                    <div className="product-buttons pr-3" style={{clear: 'both', paddingTop: '20px'}}>
                    {item.in_stock && stock >= 0 ?
                      (
                        <button aria-label={`Add item ${item.name} to cart`} aria-labelledby={`Add item ${item.name} to cart`} className={`btn btn-solid`} onClick={() => this.addItemToCart(item, this.state.selectedVariant, this.state.quantity, isItemInCart.length > 0 ? isItemInCart[0] : null)}>add to cart</button>
                      )
                      :
                      (
                        <button aria-label={`${item.name} ${stock < 0 ? 'reached limit' : 'out of stock'}`} className={`btn btn-solid disabled`}>{stock < 0 ? 'reached limit' : 'out of stock'}</button>
                      )
                    }


                    </div>
                    {item.details ?
                      <div className="border-product">
                          <h6 className="product-title">product details</h6>
                          <p>{item.details}</p>
                      </div>
                      : ''
                    }
                    {/*<div className="border-product">
                        <h6 className="product-title">share it</h6>
                        <div className="product-icon">
                            <ul className="product-social">
                                <li><a href="https://www.facebook.com/" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i className="fa fa-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>*/}
                </div>
            </div>
        )
    }
}


export default DetailsWithPrice;
