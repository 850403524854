import React, { Component } from 'react';
import Pace from 'react-pace-progress'
import TopBar from "./common/topbar";

class HeaderOne extends Component {

    constructor(props) {
      super(props);

  		this.state = {
  			isLoading:false
  		}
    }
    /*=====================
         Pre loader
         ==========================*/
    componentDidMount() {
      if (!window.location.pathname.includes('/processing')) {
        setTimeout(function() {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 500);

        this.setState({ open: true });
      }
    }

    openNav() {
        let openmyslide = document.getElementById("mySidenav");
        if(openmyslide){
            openmyslide.classList.add('open-side')
		}
    }

    closeSearch() {
        document.getElementById("search-overlay").style.display = "none";
    }

	load = ()=>{
		this.setState({isLoading: true});
		fetch().then(()=>{
			// deal with data fetched
			this.setState({isLoading: false})
		})
	};

	render() {

		return (
			<div>
				<header>
					{this.state.isLoading ? <Pace color="#27ae60"/> : null}
					{/*Top Header Component*/}
					<TopBar />
				</header>
			</div>
			)
	}
}

export default HeaderOne;
