import React, { Component } from 'react';
import Slider from 'react-slick';

class SmallImages extends Component {
    constructor (props) {
        super(props);
        this.state = {
            nav2: null
        }
    }
    componentDidMount() {
        this.setState({
            nav2: this.slider2
        });
    }

    render() {
        const { item, settings } = this.props;

        var productsnav = settings;

        return (
            <div className="row">
                <div className="col-12 p-0">
                    <Slider {...productsnav} asNavFor={this.props.navOne} ref={slider => (this.slider2 = slider)} className="slider-nav">
                        {item.thumbnailImages.map((vari, index) =>
                                <div key={index}>
                                  <img src={`${vari}`} key={index} alt={`Item-${index}`} className="img-fluid" style={{maxHeight: '100px', maxWidth: '100px'}} onClick={() => this.props.selectedImage(index)} onError={(e) => {
                                      e.target.src = 'https://swad.nyc3.cdn.digitaloceanspaces.com/image-placeholder.jpg'
                                    }} />
                                </div>
                            )}
                    </Slider>
                </div>
            </div>
        );
    }
}

export default SmallImages;
