import React, { Component } from 'react';
import ProductModal from '../../products/product-modal';

class ProductListItem extends Component {
	constructor(props) {
		super(props)

		this.state = {
			stock: 'In Stock',
			quantity: 1,
			image: '',
			detailModalOpen: false
		}
	}

	onClickHandle(img) {
		this.setState({ image: img });
	}

	toSlug(name) {
			return name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,'')
	}

	minusQty = () => {
		if (this.state.quantity > 1) {
			this.setState({ stock: 'InStock' })
			this.setState({ quantity: this.state.quantity - 1 })
		}
	}

	plusQty = () => {
		if (this.props.product.stock >= this.state.quantity) {
			this.setState({ quantity: this.state.quantity + 1 })
		} else {
			this.setState({ stock: 'Out of Stock !' })
		}
	}

	changeQty = (e) => {
		this.setState({ quantity: parseInt(e.target.value) })
	}

	render() {
		const { product } = this.props;

		return (
			<div className="product-box" style={{cursor: 'pointer'}}> {/*, padding: '10px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>*/}
				<div role="button" tabIndex={0} aria-label={`Open ${product.name} Details`} className="img-wrapper text-center" onClick={() => this.setState({ detailModalOpen: true })}>
					<div className="front">
              <img
                src={`https://swad.nyc3.cdn.digitaloceanspaces.com/patelbros/thumbnails/${this.toSlug(product.name)}.png`}
                className="img-fluid product-listing-box-image"
                alt={`Product-Item ${product.name}`}
								onLoad={(e) => {
									e.target.style.opacity = 1;
								}}
								onError={(e) => {
									e.target.src = 'https://swad.nyc3.cdn.digitaloceanspaces.com/image-placeholder.jpg'
								}} />
					</div>
				</div>
				<div role="button" tabIndex={0} aria-label={`Open ${product.name} Details`} className="product-detail text-center" onClick={() => this.setState({ detailModalOpen: true })}>
					<div className='pb-2'>
					{product.out_of_stock ?
						(
							<button style={{marginTop: '8px'}} className='text-white bg-secondary border-0' >OUT OF STOCK</button>
						)
						:
						(
							<button style={{marginTop: '8px', fontSize: '12px', padding: '4px'}} className='text-white bg-success border-0' >
								{
									product.store_products.length > 1 && !product.store_products[0].sale_price && product.store_products.findIndex(sp => sp.sale_price && sp.sale_price !== '') !== -1 ?
									'VIEW PRICE'
									:
									'ADD TO CART'
								}
							</button>
						)
					}
					<h6 style={{paddingTop: '8px', fontSize: '14px'}}>{product.name.toLowerCase()}</h6>
						{product.sale_price ?
							<h4 style={{color: 'red'}}>
								On Sale {product.variants.length > 1 ? 'From ' : ''} {product.sale_price}
								{product.tags.findIndex(t => t === 'per lb') !== -1 ? '/ lb ' : ' '}
								<del>
									<span className="money">
										{product.price}
										{product.tags.findIndex(t => t === 'per lb') !== -1 ? '/ lb' : ''}
									</span>
								</del>
							</h4>
							:
							<h4>
							{ product.store_products.length > 1 && !product.store_products[0].sale_price && product.store_products.findIndex(sp => sp.sale_price && sp.sale_price !== '') !== -1 ?
								<span style={{color: 'red'}}>
								On Sale
								</span>
								:
								<span>
								{product.variants.length > 1 ? 'From ' : ''}
								<span className="money">
									{product.price}
									{product.tags.findIndex(t => t === 'per lb') !== -1 ? '/ lb' : ''}
								</span>
								</span>
							}
							</h4>
						}
					</div>

				</div>
				<ProductModal productId={product.id} open={this.state.detailModalOpen}
					onClose={() => this.setState({ detailModalOpen: false })} />
			</div>
		)
	}
}

export default ProductListItem;
