import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { logoutUser, clearStores, clearTimeSlot, filterCategory, getOrLoginUser, reserveTimeSlot, failToReserveSlot, receiveStores, selectAStore, silentEditQty, editQty,
	refreshHomeStore, removeFromCart, emptyCart } from '../../../../actions'
	import {getCartTotal, hasSale} from '../../../../services'
import Modal from 'react-responsive-modal';
import StoreListing from '../../../collection/common/store-listing.jsx';
import LogoImage from "../common/logo";
import storeDispatch from '../../../../store';
import ReactLoading from 'react-loading';
import SearchFilter from "../../../collection/common/search-bar";
import pdListModel1 from '../../../../models/ProductListingModel';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import CartPage from './cart-header';
import {
  isMobile
} from "react-device-detect";

import {
   parseMoney, multiplyByQty, addCost, moneyFormat, getTaxAmount
} from '../../../helpers';

const moment = require('moment');
const axios = require('axios');

class TopBar extends Component {
	constructor(props) {
		super(props);

		var navClose;
		if (isMobile) {
			navClose = { left: '-410px' };

		} else {
			navClose = {left: '-410px'};

			if (window.innerWidth < 750) {
				navClose = { left: '-410px' };
			}
			if (window.innerWidth < 1199) {
				navClose = { left: '-300px' };
			}
		}

		this.state = {
			isChangeStoreOpen: false,
			isPickSlotOpen: false,
			showStores: false,
			navClose: navClose,
			isPaneOpen: false,
			layoutColumns: 4,
			hasMoreItems: true,
			loadingStore: false,
			loadingPickupSlots: true,
			showDates: [],
			activeSelected: -1,
			//the active date selected in the select date modal, if -1, that means no slots are available this week, try again next sunday
			alternateStoreSlotsAvailable: 0,
			showDropdown: false,
			lastDateSlotInner: '',
			lastTimeSlotInner: '',
		}

		this.dropdownMenu = React.createRef();
		this.dateSlotMenu = React.createRef();
		this.timeSlotMenu = React.createRef();

		this.showMenu = this.showMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);

	}

	componentWillMount() {
		if (isMobile) {
			this.setState({ navClose: { left: '-410px' } })
		} else {
			if (window.innerWidth < 750) {
				this.setState({ navClose: { left: '-410px' } })
			}
			if (window.innerWidth < 1199) {
				this.setState({ navClose: { left: '-300px' } })
			}
		}
	}

	openNav() {
		this.setState({ navClose: { left: '0px' } })
		document.querySelector(".close-overlay-nav-filter").setAttribute('style', "left: -15px");
	}

	closeNav() {
		this.setState({ navClose: { left: '-410px' } })
		document.querySelector(".close-overlay-nav-filter").setAttribute('style', "left: -415px");
	}

	onMouseEnterHandler() {
		if (window.innerWidth > 1199) {
			document.querySelector("#main-menu").classList.add("hover-unset");
		}
	}

	showMenu(event) {
		event.preventDefault();

		this.refreshSlots();

		this.setState({ showDropdown: true }, () => {
			document.addEventListener('click', this.closeMenu);
		});
	}

	closeMenu(event) {
		try {
			if (!this.dropdownMenu.current.innerHTML.includes(event.target.innerHTML) && !this.state.lastDateSlotInner.includes(event.target.innerHTML) && !this.state.lastTimeSlotInner.includes(event.target.innerHTML)) {
				this.setState({ showDropdown: false, lastDateSlotInner: '', lastTimeSlotInner: '' }, () => {
					document.removeEventListener('click', this.closeMenu);
				});
			}

		} catch (e) {

		}
	}

	logOut = e => {
		e.preventDefault();
		storeDispatch.dispatch(logoutUser());
	}

	showStoreListings = () => {
		this.setState({ showStores: true })
	}

	onOpenChangeStoreModal = () => {
		this.setState({ isChangeStoreOpen: true });
	};

	onCloseChangeStoreModal = () => {
		this.setState({ isChangeStoreOpen: false, showStores: false });
	};

	selectPreferredStore = () => {
		storeDispatch.dispatch(selectAStore(this.props.user.preferred_store, this.props.cart.id, false))
	}

	hideCart = () => {
		this.setState({isPaneOpen: false});
	}

	emptyCart = () => {
		storeDispatch.dispatch(emptyCart(this.props.cart.id))
	}

	render() {
		if (!this.props.user && !this.props.selected_store.id) {
			if (window.location.pathname != '/') {
				return (
					<div className="top-header pt-1">

						<div className="container d-none d-lg-block d-xl-block">
							<div className="row">
								<div className="col-lg-4">
								</div>
								<div className="col-lg-4">
											<div className="brand-logo" style={{padding: '10px 0'}}>
												<LogoImage openCategories={() => {}} />
											</div>
								</div>
							</div>
						</div>

					</div>
				)
			}
			return (<div></div>);
		}

		return (
			<div className="top-header pt-1">
					{ this.props.selected_store.id ?
						(
							<div className="container d-none d-lg-block d-xl-block">
								<div className="row">
									<div className="col-lg-4">
										<div className="brand-logo mt-2">
											<LogoImage openCategories={this.onCategoriesClicked} />
										</div>
									</div>
									{/*<div className="col-lg-4">
										this.renderCurrentStore()
									</div>*/}
									<div className="col-lg-5" style={{alignSelf: 'center'}}>
										<SearchFilter />
									</div>
									{/*
									<div className="col-lg-4">
										{this.renderAccountCartPickTime()}
									</div>
									*/}
									<div className="col-lg-3">
										{this.renderAccountCart()}
									</div>
								</div>
								{this.props.selected_store.id ? this.renderNav() : null}
							</div>
						)
						:
						(
							<div className="container d-none d-lg-block d-xl-block">
								<div className="row">
									<div className="col-lg-4">
									</div>
									<div className="col-lg-4">
											{this.props.user && this.props.user.preferred_store ?
												<div className="brand-logo" style={{padding: '10px 0'}}>
													<LogoImage logoLarge={true} openCategories={this.selectPreferredStore} />
													<center>
														<div className="btn btn-success"  onClick={this.selectPreferredStore}>Back To Store</div>
													</center>
												</div>
												:
												<div className="brand-logo" style={{padding: '10px 0'}}>
													<LogoImage openCategories={this.onCategoriesClicked} />
												</div>
											}
									</div>
									<div className="col-lg-4">
										{this.renderAccountCartPickTime()}
									</div>
								</div>
								{this.props.selected_store.id ? this.renderNav() : null}
							</div>
						)
					}
				<div className='container d-block d-lg-none d-xl-none pb-3'>
					<div className="d-flex flex-row justify-content-between">
						<div style={{width:'24px'}}>
							{this.props.selected_store.id ? this.renderNavMobile() : null}
						</div>
						<div style={{width:'280px'}} className="d-flex flex-column align-items-center justify-content-center ">
							<div>
								<LogoImage openCategories={this.onCategoriesClicked} />
							</div>
							{this.props.selected_store.name ?
								this.renderCurrentStoreMobile() :
								null
							}
							<div className='pt-2 w-100'>
								{this.renderPickTime()}
							</div>
						</div>
						<div style={{width:'24px', paddingTop: '28px'}}>
							{this.props.selected_store.id ?
								<Link to={`${process.env.PUBLIC_URL}/cart`}>
									<div className='d-flex flex-column'>
										<span className='badge badge-pill badge-light' style={{width:'20px'}}>
											{this.props.cartList.length > 0 ? this.props.cartList.length : 0}</span>
										<i className="fa fa-shopping-cart text-white"></i>
									</div>
								</Link> : null
							}
						</div>
					</div>
				</div>
				{this.renderChangeStoreModal()}
				{this.renderSelectPickupDateModal()}
			</div>
		);
	}

	renderCurrentStore() {
		return (
			<div style={{ paddingTop: '14px', textAlign: 'center', textTransform: 'capitalize', color: 'white', fontWeight: 'bold', fontSize: '14px' }}>
				Now Shopping At
				<div style={{fontSize: '20px'}}>
					<Link to="/" style={{color: 'white'}} onClick={() => this.onCategoriesClicked()}>{this.props.selected_store.name ? `The ${this.props.selected_store.name.slice(14)} Location` : 'Patel Brothers'}</Link>
				</div>
				<div style={{paddingTop: '16px'}}>
					{this.props.selected_store.name ?
						<p onClick={this.onOpenChangeStoreModal} style={{ cursor: 'pointer', color: 'yellow', fontWeight: 'normal', padding: '3px 20px', fontSize: '14px', textTransform: 'uppercase' }}>
							Change Location
						</p> : null
					}
				</div>
			</div>
		);
	}

	renderCurrentStoreMobile() {
		return (
			<div style={{ textAlign: 'center', textTransform: 'capitalize', color: 'white', fontWeight: 'bold' }}>
				Now Shopping At
				<div>
					{this.props.selected_store.name ? `The ${this.props.selected_store.name.slice(14)} Location` : 'Patel Brothers'}
				</div>
			</div>
		);
	}

	renderAccountCart() {
		let estimatedTax = 0.0;
		let cartItems = [];
		if (this.props.cartList) {
			try {
					cartItems = this.props.cartList.map((item,index) => {
						if (this.props.products && this.props.products.length > 0) {
							var allProducts = this.props.products.slice();
							//eslint-disable-next-line
							var product = allProducts.find(el => el.store_products.findIndex(s => item.sp_id == s.id) !== -1);

							if (!product) {
								return (<div key={index}></div>);
							}

							//eslint-disable-next-line
							var storeProduct = product.store_products.find(s => item.sp_id == s.id)

							var saleOriginalPrice = null;
							if (storeProduct.sale_price) {
								saleOriginalPrice = storeProduct.price;
							}

							var selectedVariant = null;
							if (product.variants) {
								if (product.variants.length > 0) {
									selectedVariant = product.variants.filter(v => v === item.variant)[0];
								}
							}

							if (item.tax_amount) {
								estimatedTax = addCost( estimatedTax, item.tax_amount )
							} else {
								estimatedTax = addCost(estimatedTax, getTaxAmount(item, product));
							}

							var isProduce = product.tags.findIndex(t => t === 'per lb') !== -1;
							return (
								<CartPage isProduce={isProduce} editQty={this.editQty} changeQty={this.changeQty} saleOriginalPrice={saleOriginalPrice} selectedVariant={selectedVariant} totalStock={storeProduct.stock} hideCart={this.hideCart} key={index} item={item} total={this.props.total} symbol={"$"} removeFromCart={() => storeDispatch.dispatch(removeFromCart(item))}  />
							);
						}

						return (
							<div key={index}></div>
						);

				})
			} catch (e) {
				storeDispatch.dispatch(emptyCart(this.props.cart.id));
			}
		}

		return (
			<div className='pl-2'>
				<div className='account-row-navbar d-flex flex-row align-items-baseline justify-content-between'>
					<div style={{cursor: 'default'}}>
						{this.renderAccountLogin()}
					</div>
					<div className='main-menu'>
						<div className='menu-right'>
							<div className="icon-nav">
								<div className={`onhover-div mobile-cart ${this.props.selected_store.id ? '' : 'hide-cart'}`}>
					       	{this.props.total < this.props.selected_store.minimum_required ?
					         	(<span style={{color: 'white'}}>{moneyFormat(this.props.total)} </span>)
					         	:
					         	(<span style={{color: 'white', fontWeight: 'bold'}}>{moneyFormat(this.props.total)} </span>)
					       	}
					       	<div className="cart-qty-cls">{this.props.cartList.length > 0 ? this.props.cartList.length : 0}</div>
							 		<img role="button" aria-label="Open shopping cart" tabIndex={0} src={`https://swad.nyc3.cdn.digitaloceanspaces.com/patelbros/icons/shopping-cart.png`} className="img-fluid" alt="Shopping Cart Icon" onClick={() => {this.setState({isPaneOpen: true})}}/>
								</div>
								<SlidingPane
					        className="cart-sliding-container"
					        isOpen={this.state.isPaneOpen}
					        title="Cart"
									width="450px"
					        onRequestClose={() => {
					          // triggered on "<" on left top click or on outside click
										// if (window.location.pathname == '/checkout') {
										// 	window.location.reload();
										// } else {
											this.setState({ isPaneOpen: false });
										// }
					        }}
					      >

								{this.props.cartList.length > 0 ?
									<div role="button" aria-labelledby="Clear cart" tabIndex={0} style={{cursor: 'pointer', color: '#777777', fontSize: '10px', textAlign: 'right', marginBottom: '10px'}} onClick={this.emptyCart}>Empty Cart</div>
									:
									null
								}

								{ this.props.cartList ?
		              (<div className="cart-container-list">
		              	{cartItems}
		              </div>)
		            :
		            ''
		            }

								<ul className="show-div shopping-cart">
				        <li>
									{this.props.user ?
										<div className="buttons">
												{this.props.total >= this.props.selected_store.minimum_required || (this.props.total < this.props.selected_store.minimum_required && (this.props.selected_store.shopping_fee && parseMoney(this.props.selected_store.shopping_fee) > 0)) ?
													<center>
													{
														window.location.pathname == '/checkout' ?
														(
															<a href={`${process.env.PUBLIC_URL}/checkout`} className="view-cart btn btn-success">checkout</a>
														) :
														(
															<Link aria-labelledby="Hide checkout" to={`${process.env.PUBLIC_URL}/checkout`} onClick={this.hideCart} className="view-cart btn btn-success">checkout</Link>
														)
													}
													</center>
													:
													<p className="checkout" style={{color: 'red', fontWeight: 'bold'}}>* Minimum Required Amount: ${this.props.selected_store.minimum_required}</p>
												}
										</div>
										:
										<div className="buttons">
											<p className="checkout" style={{fontWeight: 'bold'}}>* Please<Link className="text-primary-theme" to={`${process.env.PUBLIC_URL}/login`}>login</Link>or<Link className="text-primary-theme" to={`${process.env.PUBLIC_URL}/register`}>register</Link>to checkout</p>
										</div>
									}
				        </li>
				        <li>
				            <div className="total">
				                <h5 style={{color: '#999999'}}>subtotal : <span style={{color: 'black'}}>{moneyFormat(this.props.total)}</span></h5>
												<h5 style={{color: '#999999'}}>Est. Tax : <span style={{color: 'black'}}>{moneyFormat(estimatedTax)}</span></h5>
												<h5 style={{color: 'black'}}>Est. Total : <span style={{color: 'black', fontWeight: 'bold'}}>{ moneyFormat( addCost(this.props.total, estimatedTax) ) }</span></h5>
				            </div>
				        </li>
								</ul>
					      </SlidingPane>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}


	renderAccountCartPickTime() {
		let estimatedTax = 0.0;
		return (
			<div className='pl-2'>
				<div className='account-row-navbar d-flex flex-row align-items-baseline justify-content-between'>
					<div>
						{this.renderAccountLogin()}
					</div>
					<div className='main-menu'>
						<div className='menu-right'>
							<div className="icon-nav">
								<div className={`onhover-div mobile-cart ${this.props.selected_store.id ? '' : 'hide-cart'}`}>
					       {this.props.total < this.props.selected_store.minimum_required ?
					         (<span style={{color: 'white'}}>${this.props.total} </span>)
					         :
					         (<span style={{color: 'white', fontWeight: 'bold'}}>${this.props.total} </span>)
					       }
					       <div className="cart-qty-cls">{this.props.cartList.length > 0 ? this.props.cartList.length : 0}</div>
									<img aria-labelledby="Open cart" src={`https://swad.nyc3.cdn.digitaloceanspaces.com/patelbros/icons/shopping-cart.png`} className="img-fluid" alt="Shopping Cart Icon" role="button" onClick={() => {this.setState({isPaneOpen: true})}}/>
								</div>
								<SlidingPane
					        className="cart-sliding-container"
					        isOpen={this.state.isPaneOpen}
					        title="Cart"
									width="450px"
					        onRequestClose={() => {
					          // triggered on "<" on left top click or on outside click
										// if (window.location.pathname == '/checkout') {
										// 	window.location.reload();
										// } else {
											this.setState({ isPaneOpen: false });
										// }
					        }}
					      >

								{this.props.cartList.length > 0 ?
									<div role="button" aria-labelledby="Clear cart" tabIndex={0} style={{cursor: 'pointer', color: '#777777', fontSize: '10px', textAlign: 'right', marginBottom: '10px'}} onClick={this.emptyCart}>Empty Cart</div>
									:
									null
								}

								{ this.props.cartList ?
		              (<div className="cart-container-list">
		              {this.props.cartList.map((item,index) => {
										if (this.props.products && this.props.products.length > 0) {
											var allProducts = this.props.products.slice();
											//eslint-disable-next-line
											var product = allProducts.find(el => el.store_products.findIndex(s => item.sp_id == s.id) !== -1);
											//eslint-disable-next-line
											var storeProduct = product.store_products.find(s => item.sp_id == s.id)

											var saleOriginalPrice = null;
											if (storeProduct.sale_price) {
												saleOriginalPrice = storeProduct.price;
											}

											var selectedVariant = null;
											if (product.variants) {
												if (product.variants.length > 0) {
													selectedVariant = product.variants.filter(v => v === item.variant)[0];
												}
											}

											if (item.tax_amount) {
												estimatedTax = addCost( estimatedTax, item.tax_amount )

											} else {
												estimatedTax = addCost(estimatedTax, getTaxAmount(item, product));
											}

											var isProduce = product.tags.findIndex(t => t === 'per lb') !== -1;
											return (
												<CartPage isProduce={isProduce} editQty={this.editQty} changeQty={this.changeQty} saleOriginalPrice={saleOriginalPrice} selectedVariant={selectedVariant} totalStock={storeProduct.stock} hideCart={this.hideCart} key={index} item={item} total={this.props.total} symbol={"$"} removeFromCart={() => storeDispatch.dispatch(removeFromCart(item))}  />
			              	);
										}

										return (
											<div key={index}></div>
										);

								})}
		              </div>)
		            :
		            ''
		            }

								<ul className="show-div shopping-cart">
				        <li>
				            <div className="buttons">
											{this.props.total >= this.props.selected_store.minimum_required || (this.props.total < this.props.selected_store.minimum_required && (this.props.selected_store.shopping_fee && parseMoney(this.props.selected_store.shopping_fee) > 0)) ?
													<center>
													{
														window.location.pathname == '/checkout' ?
														(
															<a href={`${process.env.PUBLIC_URL}/checkout`} className="view-cart btn btn-success">checkout</a>
														) :
														(
															<Link aria-labelledby="Hide checkout" to={`${process.env.PUBLIC_URL}/checkout`} onClick={this.hideCart} className="view-cart btn btn-success">checkout</Link>
														)
													}
													</center>
				                  :
													<p className="checkout" style={{color: 'red', fontWeight: 'bold'}}>* Minimum Required Amount: ${this.props.selected_store.minimum_required}</p>
				                }
				            </div>
				        </li>
				        <li>
				            <div className="total">
				                <h5 style={{color: '#999999'}}>subtotal : <span style={{color: 'black'}}>{moneyFormat(this.props.total)}</span></h5>
												<h5 style={{color: '#999999'}}>Est. Tax : <span style={{color: 'black'}}>{moneyFormat(estimatedTax)}</span></h5>
												<h5 style={{color: 'black'}}>Est. Total : <span style={{color: 'black', fontWeight: 'bold'}}>{ moneyFormat( addCost(this.props.total, estimatedTax) ) }</span></h5>
				            </div>
				        </li>
								</ul>
					      </SlidingPane>
							</div>
						</div>
					</div>
				</div>
				{this.renderPickTime()}
			</div>
		);
	}

	renderPickTime() {
		var pickup_date = new Date();
		if (this.props.reservedSlot) {
			pickup_date = new Date(`${this.props.reservedSlot.pickup_date}`);
		}
		let nicePickupDate = '';
		if (this.props.reservedSlot) {
			nicePickupDate = `${moment(pickup_date.toISOString()).format('ddd MM/DD')} @ ${this.props.reservedSlot.pickup_time}`;
		}

		return (
			<div>
				{this.props.selected_store.id ?
					<div className="home-slot-picker">
						{this.props.reservedSlot ?
							<h4 className="home-slot-title" style={{fontWeight: 'bold', color: 'yellow'}}>
								RESERVED
							</h4>
							: null
						}
							<div className="dropdown">
								<button aria-label="Show time slot" className="dropdown-toggle" style={{paddingRight: '0', color: `${this.props.reservedSlot ? 'yellow' : ''}`, backgroundColor: `${this.props.reservedSlot ? '#129e51' : ''}`, fontWeight: `${this.props.reservedSlot ? 'bold' : ''}`}} onClick={this.showMenu}>
									{this.props.reservedSlot ?
										nicePickupDate
										:
										<span>
											{this.props.user ? 'Please Select Your Pickup Slot' : 'View Available Pickup Slots'}
										</span>
									}
								</button>
									{this.props.selected_store.id ?
										this.renderSelectPickupDateDropdown()
										:
										null
									}
							</div>

					</div>
					: null
				}
			</div>
		);
	}

	renderAccountLogin() {
		if (!this.props.user) {
			return (
				<Link style={{color: 'white'}} to={`${process.env.PUBLIC_URL}/login`} data-lng="en">Login / Register</Link>
			);
		}
		return (
			<ul className="header-dropdown">
				<li className="onhover-dropdown mobile-account pl-0">
					<img src={`https://swad.nyc3.cdn.digitaloceanspaces.com/patelbros/icons/account.png`} alt="My Account Icon" />
					{this.props.user ?
						<ul className="onhover-show-div">
							<li role="button" tabIndex={0}>
								<Link to={`${process.env.PUBLIC_URL}/account/dashboard`} data-lng="en">My Dashboard</Link>
							</li>
							<li role="button" aria-labelledby="Change store" tabIndex={0} onClick={this.onOpenChangeStoreModal} style={{ cursor: 'pointer', color: '#333333' }}>
								Change Store
							</li>
							<li role="button" aria-labelledby="Log out" tabIndex={0} onClick={this.logOut} style={{ cursor: 'pointer', color: '#333333' }}>
								Log Out
							</li>
						</ul>
						:
						<ul className="onhover-show-div">
							<li>
								<Link to={`${process.env.PUBLIC_URL}/login`} data-lng="en">Login</Link>
							</li>
							<li>
								<Link to={`${process.env.PUBLIC_URL}/register`} data-lng="en">Register</Link>
							</li>
						</ul>
					}
				</li>
			</ul>
		);
	}

	onHomeClicked() {
		pdListModel1.setFilterName('');
		pdListModel1.setFilterCategory(['All']);
	}

	onCategoriesClicked() {
		pdListModel1.setFilterName('');
		pdListModel1.setFilterCategory([]);  // order important. above sets 'All'
	}


	showSale() {
		pdListModel1.showSale();
	}

	renderNav() {
		return (
			<div className="row pb-3">
				<div className="col-lg-4 d-flex flex-row justify-content-center" style={{alignSelf: 'center'}}>
						{this.props.selected_store.name ?
							<p style={{ color: 'white', fontWeight: 'normal', fontSize: '14px', textTransform: 'uppercase', margin: '0' }}>
								Pick Up At
								<span role="button" aria-label="Open change store modal" tabIndex={0} onClick={this.onOpenChangeStoreModal} style={{ cursor: 'pointer', color: 'yellow', fontWeight: 'normal', fontSize: '14px', textTransform: 'uppercase' }}>{this.props.selected_store.name.slice(14)}, {this.props.selected_store.zip_code}</span>
							</p> : null
						}
				</div>
				<div className="col-lg-8 d-flex flex-row justify-content-start pt-2 pb-1">
					{this.props.hasSale ? <Link to={`${process.env.PUBLIC_URL}/categories/sale`} onClick={() => this.showSale()} className="nav-link text-sale">SALE</Link> : null}
					<Link to="/" onClick={() => this.onCategoriesClicked()} className="nav-link text-white">HOME</Link>
					<Link to={`${process.env.PUBLIC_URL}/account/dashboard`} className="nav-link text-white">DASHBOARD</Link>
					<Link to={`${process.env.PUBLIC_URL}/faq`} className="nav-link text-white">FAQ</Link>
					<Link to={`${process.env.PUBLIC_URL}/contact`} className="nav-link text-white">CONTACT</Link>
					<div className="nav-link text-white" style={{position: 'absolute', right: '0'}}>
						{this.renderPickTime()}
					</div>
				</div>
			</div>
		);
	}

	renderNavOld() {
		return (
			<div className="row pb-3">
				<div className="col-lg-4 d-flex flex-row justify-content-end pt-2 pb-1">
					{this.props.hasSale ? <Link to={`${process.env.PUBLIC_URL}/categories/sale`} onClick={() => this.showSale()} className="nav-link text-sale">SALE</Link> : null}
					<Link to="/" onClick={() => this.onCategoriesClicked()} className="nav-link text-white">HOME</Link>
					<Link to={`${process.env.PUBLIC_URL}/account/dashboard`} className="nav-link text-white">DASHBOARD</Link>
				</div>
				<div className="col-lg-4">
					<SearchFilter />
				</div>
				<div className="col-lg-4 d-flex flex-row justify-content-start pt-2 pb-1">
					<Link to={`${process.env.PUBLIC_URL}/faq`} className="nav-link text-white">FAQ</Link>
					<Link to={`${process.env.PUBLIC_URL}/contact`} className="nav-link text-white">CONTACT</Link>
				</div>
			</div>
		);
	}

	renderNavMobile() {
		return (
			<div className="main-navbar">
				<div id="mainnav" >
					<div className="toggle-nav" onClick={this.openNav.bind(this)} >
						<i className="fa fa-bars sidebar-bar text-white"></i>
					</div>
					<div className="col-sm-12 close-overlay-nav-filter"
					onClick={() => this.closeNav()}
					></div>
					<ul className="nav-menu" style={this.state.navClose}>
						<li role="button" aria-labelledby="Close navbar" className="back-btn" onClick={this.closeNav.bind(this)} >
							<div className="mobile-back" style={{justifyContent: 'flex-start'}}>
								<i className="fa fa-angle-left pr-2" aria-hidden="true"></i>
								<span>Back</span>
							</div>
						</li>
						<li>
							<Link to="/" className="nav-link" onClick={() => {
								if (window.innerWidth < 750) {
									this.closeNav();
								}
							}}>
								home
							</Link>
						</li>
						{!this.props.user ?
							<li>
								<Link to={`${process.env.PUBLIC_URL}/login`} onClick={() => {
									this.closeNav();
								}} className="nav-link">
									login
								</Link>
							</li> : ''
						}
						{!this.props.user ?
							<li>
								<Link to={`${process.env.PUBLIC_URL}/register`} onClick={() => {
									this.closeNav();
								}} className="nav-link">
									register
								</Link>
							</li> : ''
						}
						{this.props.user ?
							<li>
								<Link to={`${process.env.PUBLIC_URL}/cart`} onClick={() => {
									this.closeNav();
								}} className="nav-link">
									cart
								</Link>
							</li> :	''
						}
						{this.props.user && this.props.selected_store.name ?
							<li>
							<a href="#" className="nav-link" onClick={(e) => {
								e.preventDefault();
								this.closeNav();
								this.onOpenChangeStoreModal()
							}}>
								Change Location
							</a>
							</li> : null
						}
						{this.props.user ?
							<li>
								<Link to={`${process.env.PUBLIC_URL}/account/dashboard`} onClick={() => {
									this.closeNav();
								}} className="nav-link">
									account
								</Link>
							</li> : ''
						}
						<li>
							<Link to={`${process.env.PUBLIC_URL}/faq`} className="nav-link" onClick={() => {
								if (window.innerWidth < 750) {
									this.closeNav();
								}
							}}>
								FAQ
							</Link>
						</li>
						{this.props.user ?
							<li>
								<Link to={`${process.env.PUBLIC_URL}/contact`} className="nav-link" onClick={() => {
									if (window.innerWidth < 750) {
										this.closeNav();
									}
								}}>
									contact
								</Link>
							</li> : ''
						}

						{this.props.user ?
							<li>
								<a href="#" className="nav-link" onClick={(e) => {
									this.logOut(e);
								}}>
									Log Out
								</a>
							</li> : ''
						}
					</ul>
				</div>
			</div>
		);
	}

	renderChangeStoreModal() {
		return (
			<Modal open={this.state.isChangeStoreOpen} onClose={this.onCloseChangeStoreModal} center>
				<div className="modal-header">
					<h2>Change Your Store</h2>
				</div>
				<div className="modal-body">
					{this.state.showStores ?
						<StoreListing />
						:
						<h3>
							Are you sure you want to change your store?
							<p className="text-danger text-center"><b>
								{this.props.reservedSlot ? '* YOUR TIME SLOT WILL EXPIRE IF YOU CONTINUE.' : ''}</b></p>
						</h3>
					}

					<center>
						{/*<button className="modal-btn btn btn-success" onClick={this.showStoreListings}>Yes</button>*/}
						<Link className="modal-btn btn btn-success" to={'/'} onClick={() => {
							this.setState({ isChangeStoreOpen: false });
							if (this.props.reservedSlot) {
								storeDispatch.dispatch(clearTimeSlot(this.props.reservedSlot.id))
							}
							storeDispatch.dispatch(clearStores());
						}}>Yes</Link>
						<button aria-labelledby="Close change store modal" className="modal-btn btn btn-danger" onClick={this.onCloseChangeStoreModal}>Cancel</button>
					</center>
				</div>
			</Modal>
		);
	}

	renderSelectPickupDateDropdown() {
		if (!this.state.showDropdown) {
			return null
		}

		return (
			<div id="dropdownmenu" className={`dropdown-menu show-dropdown`} style={{width: '100%'}} ref={this.dropdownMenu}>
				{this.state.loadingPickupSlots ?
					<div>
						<center>
							<ReactLoading type={'spin'} color={'#129e51'} height={150} width={150} />
						</center>
					</div>
					:
					<div style={{ display: 'inline' }}>
						<div className="container">
							<div className="row">
								{this.state.activeSelected === -1 ?
									<div className="col-lg-12 col-md-12 col-sm-12 col-12" id="pickup-dates-col" ref={this.dateSlotMenu}>
										{this.state.showDates.map((date, i) => {
											if (!date.enabled) {
												return (
													<div key={i} className="pickup-box disabled">
														<h3>{moment().isoWeekday(date.pickup_day).format('ddd')}, {date.date}</h3>
														<p>{date.slotsAvailable ? `Slots ${parseInt(date.slotsAvailable) === parseInt(date.slotsUsed) ? 'Will Re-' : ''}Open ${moment().isoWeekday(date.pickup_day).subtract(2, 'day').format('dddd')}` : 'No Slots Available'}</p>
													</div>
												);
											}
											else {
												var slotsLeft = date.slotsAvailable - date.slotsUsed;
												return (
													<div key={i} className={`pickup-box`}
														onClick={() => this.selectDateSlot(i)}>
														<h3>{moment().isoWeekday(date.pickup_day).format('ddd')}, {date.date}</h3>
														<p>{slotsLeft} Slot{slotsLeft === 1 ? '' : 's'} Available</p>
													</div>
												);
											}
										})}
									</div>
									:
									(
										<div className="col-lg-12 col-md-12 col-sm-12 col-12"  ref={this.timeSlotMenu}>
											{this.state.activeSelected >= 0 ?
												<div className="timeslot-box">
													<p className="back-btn" onClick={() => this.setState({activeSelected: -1, lastTimeSlotInner: this.timeSlotMenu.current ? this.timeSlotMenu.current.innerHTML : ''})}>BACK</p>
													<h3 className="timeslot-title">
														{this.state.showDates[this.state.activeSelected].date},
														{moment().year()} - {this.state.showDates[this.state.activeSelected].pickup_day}
													</h3>
													<p style={{fontWeight: 'bold'}}>
														(* Reserved slots will expire within 30 minutes of inactivity)
													</p>
													{this.renderSlotsDropdown()}
												</div>
												:
												<div className="timeslot-box empty">
													{this.renderNoSlotsDropdown()}
												</div>
											}
										</div>
									)
								}
							</div>
						</div>
					</div>
				}
			</div>
		);
	}
	renderSlotsDropdown() {
		return (
			this.state.showDates[this.state.activeSelected].pickup_times.map((time, i) => {
				var isSelected = false;

				if (this.props.reservedSlot) {
					if (this.props.reservedSlot.pickup_time === time.pickup_time &&
						this.props.reservedSlot.pickup_date === this.state.showDates[this.state.activeSelected].pickup_date)
					{
						isSelected = true;
					}
				}
				if (!time.enabled) {
					return (
						<div key={i} className="timeslot-box selectable disabled">
							<div className="row">
								<div className="col-lg-12 col-md-12 col-sm-12 col-12">
									<h3>{time.pickup_time}</h3>
								</div>
								<div className="col-lg-12 col-md-12 col-sm-12 col-12" style={{ alignSelf: 'center' }}>
									{isSelected ?
										<p className="btn btn-dark" style={{ cursor: 'default' }}>Your reserved slot</p>
										:
										<p>No Slots Available</p>
									}
								</div>
							</div>
						</div>
					);
				}
				else {
					var slotsLeft = time.slotsAvailable - time.slotsUsed;
					return (
						<div key={i} className='timeslot-box selectable'>
							<div className="row">
								<div className="col-lg-12 col-md-12 col-sm-12 col-12">
									<h3>{time.pickup_time}</h3>
									<p>{slotsLeft} Slot{slotsLeft === 1 ? '' : 's'} Available</p>
								</div>
								{this.props.user ?
									<div className="col-lg-12 col-md-12 col-sm-12 col-12" style={{ alignSelf: 'center' }}>
										{isSelected ?
											<p className="btn btn-dark" style={{ cursor: 'default' }}>Your reserved slot</p>
											:
											<p className="btn btn-success" onClick={() => this.reserveTheSlot(i)}
												style={{ cursor: 'pointer' }}>Reserve this Slot</p>
										}
									</div>
									:
									<div className="col-lg-12 col-md-12 col-sm-12 col-12" style={{ alignSelf: 'center' }}>
										<Link to={`${process.env.PUBLIC_URL}/login`} className="btn btn-success" style={{ cursor: 'pointer' }} onClick={() => 	this.setState({ showDropdown: false, lastDateSlotInner: '', lastTimeSlotInner: '' })}>
											Login or Register
										</Link>
									</div>
								}
							</div>
						</div>
					);
				}
			})
		);
	}

	renderNoSlotsDropdown() {
		return (
			<h3 className='p-3'>
				There Are No Timeslots Available This Week.
				<br /><br />
				Please try again on this upcoming Sunday.
				<br /><br />
				{this.state.alternateStoreSlotsAvailable > 0 ?
					<div>
						<p className="text-danger" style={{ fontSize: '16px', padding: '10px' }}>
							{/* eslint-disable-next-line */}
							There {parseInt(this.state.alternateStoreSlotsAvailable) === 1 ? 'is' : 'are'} {this.state.alternateStoreSlotsAvailable}
							other store{parseInt(this.state.alternateStoreSlotsAvailable) === 1 ? '' : 's'} near you that have some curbside pickup slots,
							would you like to change your store?
							<br /><br />
							WARNING: We cannot guarantee pickup availability, or the price, quantity,
							and availability of items - including those in your cart - if you change your store.
						</p>
						<p className="btn btn-success" onClick={() => this.changeStore()} style={{ cursor: 'pointer' }}>Change Store</p>
					</div>
					:
					''
				}
			</h3>
		);
	}


	renderSelectPickupDateModal() {
		return (
			<Modal open={this.state.isPickSlotOpen} onClose={this.onCloseModal} center classNames={{ modal: "pickup-slot-modal" }}>
				<div className="modal-header">
					<h3>
						Select Your Curbside Pickup Date &amp; Time - {this.props.selected_store.name}
					</h3>
				</div>
				{this.state.loadingPickupSlots ?
					<div className="modal-body">
						<center>
							<ReactLoading type={'spin'} color={'#129e51'} height={300} width={300} />
						</center>
					</div>
					:
					<div className="modal-body" style={{ display: 'inline' }}>
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-4 col-sm-12 col-12" id="pickup-dates-col">
									{this.state.showDates.map((date, i) => {
										if (!date.enabled) {
											return (
												<div key={i} className="pickup-box disabled">
													<h3>{moment().isoWeekday(date.pickup_day).format('ddd')}, {date.date}</h3>
													<p>{date.slotsAvailable ? `Slots Open ${moment().isoWeekday(date.pickup_day).subtract(2, 'day').format('dddd')}` : 'No Slots Available'}</p>
												</div>
											);
										}
										else {
											var slotsLeft = date.slotsAvailable - date.slotsUsed;
											return (
												<div key={i} className={`pickup-box ${this.state.activeSelected === i ? 'active' : ''}`}
													onClick={() => this.selectDateSlot(i)}>
													<h3>{moment().isoWeekday(date.pickup_day).format('ddd')}, {date.date}</h3>
													<p>{slotsLeft} Slot{slotsLeft === 1 ? '' : 's'} Available</p>
												</div>
											);
										}
									})}
								</div>
								<div className="col-lg-8 col-md-8 col-sm-12 col-12">
									{this.state.activeSelected >= 0 ?
										<div className="timeslot-box">
											<h3 className="timeslot-title">
												{this.state.showDates[this.state.activeSelected].date},
												{moment().year()} - {this.state.showDates[this.state.activeSelected].pickup_day}
											</h3>
											{this.renderSlots()}
										</div>
										:
										<div className="timeslot-box empty">
											{this.renderNoSlots()}
										</div>
									}
								</div>
							</div>
						</div>
					</div>
				}
			</Modal>
		);
	}

	renderSlots() {
		return (
			this.state.showDates[this.state.activeSelected].pickup_times.map((time, i) => {
				var isSelected = false;

				if (this.props.reservedSlot) {
					if (this.props.reservedSlot.pickup_time === time.pickup_time &&
						this.props.reservedSlot.pickup_date === this.state.showDates[this.state.activeSelected].pickup_date)
					{
						isSelected = true;
					}
				}
				if (!time.enabled) {
					return (
						<div key={i} className="timeslot-box selectable disabled">
							<div className="row">
								<div className="col-lg-8 col-md-6 col-sm-12 col-12">
									<h3>{time.pickup_time}</h3>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{ alignSelf: 'center' }}>
									{isSelected ?
										<p className="btn btn-dark" style={{ cursor: 'default' }}>Your reserved slot</p>
										:
										<p>No Slots Available</p>
									}
								</div>
							</div>
						</div>
					);
				}
				else {
					var slotsLeft = time.slotsAvailable - time.slotsUsed;
					return (
						<div key={i} className='timeslot-box selectable'>
							<div className="row">
								<div className="col-lg-8 col-md-6 col-sm-12 col-12">
									<h3>{time.pickup_time}</h3>
									<p>{slotsLeft} Slot{slotsLeft === 1 ? '' : 's'} Available</p>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{ alignSelf: 'center' }}>
									{this.props.user ?
										<div className="col-lg-12 col-md-12 col-sm-12 col-12" style={{ alignSelf: 'center' }}>
											{isSelected ?
												<p className="btn btn-dark" style={{ cursor: 'default' }}>Your reserved slot</p>
												:
												<p className="btn btn-success" onClick={() => this.reserveTheSlot(i)}
													style={{ cursor: 'pointer' }}>Reserve this Slot</p>
											}
										</div>
										:
										<div className="col-lg-12 col-md-12 col-sm-12 col-12" style={{ alignSelf: 'center' }}>
											<Link to={`${process.env.PUBLIC_URL}/login`} className="btn btn-success" style={{ cursor: 'pointer' }} onClick={() => 	this.setState({ showDropdown: false, lastDateSlotInner: '', lastTimeSlotInner: '' })}>
												Login or Register
											</Link>
										</div>
									}
								</div>
							</div>
						</div>
					);
				}
			})
		);
	}

	renderNoSlots() {
		return (
			<h3 className='p-3'>
				There Are No Timeslots Available This Week.
				<br /><br />
				Please try again on this upcoming Sunday.
				<br /><br />
				{this.state.alternateStoreSlotsAvailable > 0 ?
					<div>
						<p className="text-danger" style={{ fontSize: '16px', padding: '10px' }}>
							{/* eslint-disable-next-line */}
							There {parseInt(this.state.alternateStoreSlotsAvailable) === 1 ? 'is' : 'are'} {this.state.alternateStoreSlotsAvailable}
							other store{parseInt(this.state.alternateStoreSlotsAvailable) === 1 ? '' : 's'} near you that have some curbside pickup slots,
							would you like to change your store?
							<br /><br />
							WARNING: We cannot guarantee pickup availability, or the price, quantity,
							and availability of items - including those in your cart - if you change your store.
						</p>
						<p className="btn btn-success" onClick={() => this.changeStore()} style={{ cursor: 'pointer' }}>Change Store</p>
					</div>
					:
					''
				}
			</h3>
		);
	}

	refreshSlots = () => {
		if (!this.props.selected_store.pickup_slots) {
			return;
		}
		this.setState({ loadingPickupSlots: true });
		//get weekday slots so it doesn't sort 30 times for each month in the valid function below
		let store = this.props.selected_store;

		let pickup_slots = store.pickup_slots ? store.pickup_slots : [];

		let today = moment();
		let getSlotsForDates = []; //for getting available slots for selectable dates

		let showDates = []; //for showing the dates, even if they are not selectable;

		let cutOffTime = store.cutoff_slot_time; //a time has been set to cut off orders for next day, or null

		if (parseInt(this.props.selected_store.rolling_slots) === 0) {
			// for (var i = 0; i <= 7; i++) {
			for (let i = today.day(); i <= (today.day()+7); i++) {
				let day = moment().add((i - today.day()), 'day');
				if (i <= today.day()) {
					showDates.push({
						date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
						enabled: false, pickup_times: [], slotsAvailable: 0, slotsUsed: 0 });
					continue;
				}

				//eslint-disable-next-line
				let pickup_times = []

				if (cutOffTime && i === today.day()+1) { //if there is a cutoff time, and the current slot we're checking for is tomorrow
					if ( moment(new Date(), "h:mm a").isAfter(moment(cutOffTime, 'h:mm a')) ) {
						showDates.push({
							date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
							enabled: false, pickup_times: [], slotsAvailable: 0, slotsUsed: 0 });
						continue;
					}
				}

				pickup_times = pickup_slots.filter(s => s.pickup_day === moment().weekday(i).format('dddd')).map(s => {
					return { pickup_time: s.pickup_time, slotsAvailable: s.slots, slotsUsed: 0, enabled: s.slots > 0 };
				})

				let availableSlots = pickup_times.map(s => s.slotsAvailable).reduce((x, y) => {
					return x + y;
				}, 0)

				showDates.push({
					date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
					enabled: availableSlots > 0, pickup_times: pickup_times, slotsAvailable: availableSlots, slotsUsed: 0 });
				getSlotsForDates.push(day.format('MM/DD/YYYY'));

			}

		} else {
			let rollingDays = parseInt(this.props.selected_store.rolling_slots);
			let idx = 0;
			for (let i = today.day(); i <= (today.day()+7); i++) {
				//since the slots are rolling, we need to start from today and get either the slots OR when the slot will open for the week
				let day = moment().add((i - today.day()), 'day');
				if (i <= today.day()) {
					showDates.push({
						date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
						enabled: false, pickup_times: [], slotsAvailable: 0, slotsUsed: 0 });
					continue;
				}

				if (cutOffTime && i === today.day()+1) { //if there is a cutoff time, and the current slot we're checking for is tomorrow
					if ( moment(new Date(), "h:mm a").isAfter(moment(cutOffTime, 'h:mm a')) ) {
						showDates.push({
							date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
							enabled: false, pickup_times: [], slotsAvailable: 0, slotsUsed: 0 });
						idx += 1;
						continue;
					}
				}

				if (idx >= rollingDays) {
					let pickup_times = pickup_slots.filter(s => s.pickup_day === moment().weekday(i).format('dddd')).map(s => {
						return { pickup_time: s.pickup_time, slotsAvailable: s.slots, slotsUsed: 0, enabled: false };
					})

					let availableSlots = pickup_times.map(s => s.slotsAvailable).reduce((x, y) => {
						return x + y;
					}, 0)
					showDates.push({
						date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
						enabled: false, pickup_times: [], slotsAvailable: availableSlots, slotsUsed: 0 });
					continue;
				}

				//eslint-disable-next-line
				let pickup_times = pickup_slots.filter(s => s.pickup_day === moment().weekday(i).format('dddd')).map(s => {
					return { pickup_time: s.pickup_time, slotsAvailable: s.slots, slotsUsed: 0, enabled: s.slots > 0 };
				})

				let availableSlots = pickup_times.map(s => s.slotsAvailable).reduce((x, y) => {
					return x + y;
				}, 0)
				showDates.push({
					date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
					enabled: availableSlots > 0, pickup_times: pickup_times, slotsAvailable: availableSlots, slotsUsed: 0 });
				getSlotsForDates.push(day.format('MM/DD/YYYY'));

				idx += 1;
			}
		}

		axios.post('/api/shop/reserved_slots_in_dates', { store_id: store.id, dates: getSlotsForDates })
			.then(({ data }) => {
				let activeIndex = -1;

				showDates.map((sD, i) => {
					let compareDates = data.filter(d => {
						return d.pickup_date === sD.pickup_date;
					});

					if (compareDates.length > 0) {
						sD.slotsUsed = compareDates.length;

						//get the pickup times used, and put them in the slots used for each specific time
						//eslint-disable-next-line
						sD.pickup_times.map(time => {
							let compareTimes = compareDates.filter(d => {
								return d.pickup_time === time.pickup_time;
							})

							time.slotsUsed = compareTimes.length;

							if (time.slotsAvailable <= time.slotsUsed) {
								time.enabled = false;
							}
						});
						//

						if (sD.slotsAvailable <= sD.slotsUsed) {
							if (this.props.reservedSlot) {
								if (this.props.reservedSlot.pickup_date === sD.pickup_date) {
									activeIndex = i
								}
							}
							sD.enabled = false;
						} else {
							if (activeIndex === -1) {
								activeIndex = i
							}
						}

					} else {
						if (sD.slotsAvailable > sD.slotsUsed) {

							if (activeIndex === -1) {
								activeIndex = i
							}
						} else {
							if (this.props.reservedSlot) {
								if (this.props.reservedSlot.pickup_date === sD.pickup_date) {
									activeIndex = i
								}
							}
						}
					}

					return sD;
				});

				if (activeIndex === -1 && this.props.user) {
					axios.post('/api/shop/find_nearby_open_slots', { dates: getSlotsForDates })
						.then(({ data }) => {

							var numberOfStores = 0;
							for (var key in data.remainingByStore) {
								if (data.remainingByStore[key] > 0) {
									numberOfStores += 1;
								}
							}

							if (numberOfStores > 0) {
								storeDispatch.dispatch(receiveStores(data.allStores));

								this.setState({ alternateStoreSlotsAvailable: numberOfStores })
							} else {
								this.setState({ alternateStoreSlotsAvailable: 0 })
							}
						})
				}

				this.setState({ loadingPickupSlots: false, showDates: showDates });
			})
			.catch(error => {
				this.setState({ loadingPickupSlots: false, showDates: showDates })
			});
	}

	selectDateSlot = index => {
		this.setState({ activeSelected: index, lastDateSlotInner: this.dateSlotMenu.current ? this.dateSlotMenu.current.innerHTML : '' });
	}

	reserveTheSlot = index => {
		let selected_date = this.state.showDates[this.state.activeSelected];
		let selected_time_details = selected_date.pickup_times[index];

		let pickup_date = selected_date.pickup_date;
		let pickup_day = selected_date.pickup_day;
		let pickup_time = selected_time_details.pickup_time;

		let store_id = this.props.selected_store.id;

		let cart_id = this.props.cart.id;

		if (this.props.reservedSlot) { //expire an existing slot if changing
			axios.post('/api/shop/expire_slot', { slot_id: this.props.reservedSlot.id })
				.then(() => {
				})
				.catch(error => {

				});
		}

		axios.post('/api/shop/reserve_slot', { pickup_date, pickup_day, pickup_time, store_id, cart_id })
			.then(({ data }) => {
				storeDispatch.dispatch(reserveTimeSlot(data));
				this.setState({ isPickSlotOpen: false })
				this.refreshSlots();
			})
			.catch(error => {
				storeDispatch.dispatch(failToReserveSlot());
				this.refreshSlots();
				if (error.response) {
					if (error.response.status === 401) {
						storeDispatch.dispatch(logoutUser());
					}
				}
			});
	}

	onOpenPickSlotModal = () => {
		this.refreshSlots();
		this.setState({ isPickSlotOpen: true });
	};

	onCloseModal = () => {
		this.setState({ isPickSlotOpen: false });
	};

	editQty = (item, product, selectedVariant, incDec) => {
		// eslint-disable-next-line
		var storeProduct = product.store_products.find(s => item.sp_id == s.id)

		product.id = storeProduct.id; // Since we will be using this id for line_items, let's make this the default id

		if (!storeProduct.price) {
			product.price = product.original_price;
		} else {
			product.price = storeProduct.price;
		}

		if (storeProduct.sale_price) {
			product.sale_price = storeProduct.sale_price;
		}

		product.stock = storeProduct.stock;
		product.sp_id = storeProduct.id;


		var isProduce = product.tags.findIndex(t => t === 'per lb') !== -1;

		var num = isProduce ? 0.5 : 1;
		if (incDec === 'decrement') {
			if (item.qty - num <= 0) {
				return;
			}
		}

		this.props.editQty(item, product, selectedVariant, isProduce ? 0.5 : 1, incDec, this.props.cart ? this.props.cart.id : null)
	}

	changeQty = (item, product, selectedVariant, totalStock, values) => {
		if (values.floatValue < 0) {
			return;
		}

		if (totalStock > 0) {
			if (values.floatValue > totalStock) {
				this.props.silentEditQty(item, product, selectedVariant, totalStock, 'update', this.props.cart ? this.props.cart.id : null)

			} else {
				this.props.silentEditQty(item, product, selectedVariant, values.floatValue, 'update', this.props.cart ? this.props.cart.id : null)
			}

		} else {
			this.props.silentEditQty(item, product, selectedVariant, values.floatValue, 'update', this.props.cart ? this.props.cart.id : null)

		}
	}
}

const mapStateToProps = (state) => ({
	user: state.userData.user,
	filters: state.filters,
	reservedSlot: state.userData.slot,
	cart: state.cartList.cartObj,
	selected_store: state.storeData.selected_store,
	shouldChangeStore: state.storeData.change_store,
	cartList: state.cartList.cart,
	total: getCartTotal(state.cartList.cart),
	products: state.data.products,
	hasSale: hasSale(state.data.products)
})

export default connect(
	mapStateToProps, { logoutUser, selectAStore,silentEditQty, editQty, filterCategory, getOrLoginUser, reserveTimeSlot, failToReserveSlot, clearStores, refreshHomeStore, removeFromCart, emptyCart }
)(TopBar)
