import * as types from '../constants/ActionTypes'
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import pdListModel1 from '../models/ProductListingModel';
import {
  multiplyByQty, getTaxAmount
} from '../components/helpers';

const axios = require('axios');

export const fetchSingleStore = (storeId, pickup_slots) => ({
    type: types.FETCH_SINGLE_STORE,
    storeId,
    pickup_slots
})

export const getSingleStore = (store) => ({
    type: types.GET_SINGLE_STORE,
    store,
})
export const fetchProductsBegin = () => ({
    type: types.FETCH_PRODUCTS_BEGIN
});


export const receiveProducts = products => ({
    type: types.RECEIVE_PRODUCTS,
    products
})
export const clearProducts = () => ({
    type: types.CLEAR_PRODUCTS
})
export const fetchUser = () => ({
  type: types.FETCH_USER
});

export const receiveUser = user => ({
    type: types.RECEIVE_USER,
    user
})

export const updateMyPreferences = (marketing_opt_in, get_order_texts) => ({
    type: types.UPDATE_PREFERENCES,
    marketing_opt_in,
    get_order_texts
})


export const receiveLineItem = (line_items) => ({
    type: types.RECEIVE_LINE_ITEM,
    line_items
})

export const receiveCart = (cart, line_items) => ({
    type: types.RECEIVE_CART,
    cart,
    line_items
})

export const fetchStoresBegin = () => ({
    type: types.FETCH_STORES_BEGIN
});

export const receiveStores = stores => ({
    type: types.RECEIVE_STORES,
    stores
})
export const clearStores = () => ({
    type: types.CLEAR_STORES
})
export const clearCart = () => ({
    type: types.CLEAR_CART
})

export const refreshHomeStore = (data) => dispatch => {
  dispatch(receiveCart({}, []));

  if (!data) {
    dispatch(receiveUser(null));
    dispatch(clearProducts());
    dispatch(clearStores());
    return null;
  }

  dispatch(receiveUser(data.user));
  dispatch(receiveCart(data.cart, data.line_items ? data.line_items : []));
  if (data.cartChanged) {
    toast.error("Some Items in Your Cart Have Changed. Please Review Your Cart.", { autoClose: 5000 });
  }

  if (data.user.preferred_store) {
    dispatch(getSingleStore(data.store));
    dispatch(receiveProducts(data.store.products));
    pdListModel1.setProducts(data.store.products);
  } else {
    //NEED TO SELECT STORE FIRST, RECEIVE ALL STORES
    dispatch(receiveStores(data.all_stores));
  }
}


//UNUSED RIGHT NOW
export const getOrLoginUser = (email, password = null) => dispatch => {
  dispatch(fetchUser());
  let pswd = password ? password : '';
  axios.post('/api/shop/login', {email: email, password: pswd})
    .then(({data}) => {
      if (data.error) {
        dispatch(receiveUser(null));
        dispatch(clearProducts());
        dispatch(clearStores());
        dispatch(receiveCart({}, []));
        return null;
      }

      if (data.cart) {
        dispatch(receiveUser(data.user));
        if (data.user.preferred_store) {
          dispatch(selectAStore(data.user.preferred_store, data.cart.id, false));
        }

        dispatch(receiveCart(data.cart, data.line_items ? data.line_items : []));
      } else {
        dispatch(receiveUser(data.user));

      }

      return data;
    })
}
//

export const registerUser = (email, password, first_name, last_name) => dispatch => {
  dispatch(fetchUser());
  axios.post('/api/shop/register', {email, password, first_name, last_name})
    .then(({data}) => {
      dispatch(receiveUser(data.user));

      if (data.cart) {
        dispatch(receiveCart(data.cart, []));
      }

      return data;
    })
}

export const updatePreferences = (marketing_opt_in, get_order_texts) => dispatch => {
  dispatch(updateMyPreferences(marketing_opt_in, get_order_texts));
}

export const logoutUser = (id) => dispatch => {
  dispatch(receiveUser(null));
  // dispatch(clearProducts());
  // dispatch(clearStores());
  dispatch(receiveCart({}, []));
  axios.post('/api/shop/logout', {id})
    .then(({data}) => {
      return data;
    });
}


export const getAllStores = () => dispatch => {
  dispatch(fetchStoresBegin());
  axios.get('/api/shop/all_stores')
    .then(({data}) => {
      dispatch(receiveStores(data));
      return data;
    });
}

export const selectAStore = (store_id, cart_id, newStore, callback) => dispatch => {
  if (newStore) {
    dispatch(clearProducts());
    var categories = []
    dispatch(filterCategory(categories));
  }

  dispatch(fetchProductsBegin());
  axios.post('/api/shop/select_store', {store_id, cart_id})
    .then(({data}) => {
      if (data.error) {
        dispatch(receiveUser(null));
        dispatch(clearProducts());
        dispatch(receiveCart({}, []));
        return null;
      }

	    pdListModel1.setProducts(data.store.products);

      dispatch(getSingleStore(data.store));
      dispatch(receiveProducts(data.store.products));
      dispatch(receiveLineItem(data.line_items ? data.line_items : []));

      if (data.cartChanged) {
        toast.error("Some Items in Your Cart Have Changed. Please Review Your Cart.", { autoClose: 5000 });
      }

      if (callback) {
        callback()
      }

      return data;
    });
}

export const reserveSingleSlot = (slot) => ({
    type: types.RESERVE_SLOT,
    slot
})

export const reserveTimeSlot = (slot, refresh = null) => dispatch => {
   dispatch(reserveSingleSlot(slot))
   if (slot != null && refresh == null) {
     toast.success("Reserved Your Pickup Slot!", { autoClose: 2000 });
   }
   if (slot == null && refresh) {
     toast.error("Your Time Slot Expired", { autoClose: 2000 });
   }
}

export const failToReserveSlot = () => dispatch => {
   toast.error("This Pickup Slot is No Longer Available.", { autoClose: 2000 });
}


export const clearTimeSlot = (slot_id) => dispatch => {
     dispatch(reserveSingleSlot(null))
     if (slot_id != null) {
       axios.post('/api/shop/expire_slot', {slot_id})
         .then(({data}) => {

         });
       toast.error("Your Time Slot Expired", { autoClose: 2000 });
     }
}


export const completeCheckout = (replaceCart) => dispatch => {
  dispatch(clearCart());
  dispatch(reserveSingleSlot(null));

  dispatch(receiveCart(replaceCart,[]));
}

export const errorOnCheckout = () => dispatch => {
  toast.error("There was an error processing your order, please try again.", { autoClose: 2000 });
}

export const addToCart = (product, variant, qty, item, cart_id) => (dispatch) => {

  let asGuest = false;
  if (!cart_id) {
    asGuest = true;
  }

  let price = product.sale_price ? product.sale_price : product.price;

  let newQty = qty;

  if (item != null) {
      newQty = parseFloat(item.qty) + parseFloat(qty);
  }

  var tax_amount = 0.0;
  if (product.taxable) {
    tax_amount = getTaxAmount({price: price, qty: newQty}, product);
  }

  if (!asGuest) {
    axios.post('/api/shop/add_to_cart', {tax_amount: tax_amount, price: price, qty: newQty, variant: variant ? variant : '', sp_id: product.sp_id, product_id: product.product_id, cart_id: cart_id})
      .then(({data}) => {
        if (data.error) {
          toast.error("Item Not Added. Please Try Again Later.", { autoClose: 2000 });
          return [];
        }
        product.price = price;
        product.tax_amount = tax_amount;
        toast.success("Item Added to Cart", { autoClose: 2000 });
        dispatch(addToCartUnsafe(data.id, product,variant,newQty))
        return data;
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logoutUser());
            toast.error("Please login again, item was not added to cart.", { autoClose: 2000 });
          }
        }
      })

  } else {
    product.price = price;
    product.tax_amount = tax_amount;
    toast.success("Item Added to Cart", { autoClose: 2000 });
    dispatch(addToCartUnsafe(product.id, product,variant,newQty)) //this is supposed to save an li item, but we don't have one so just use store product id as id for the item for now
  }

}

export const addToCartUnsafe = (id, product, variant, qty) => ({
    type: types.ADD_TO_CART,
    id,
    product,
    variant,
    qty
});

export const removeFromCart = (line_item) => (dispatch) => {
    axios.post('/api/shop/remove_line_item', {line_item_id: line_item.id})
      .then(({data}) => {
        if (data.error) {
          toast.error("Something Went Wrong, Couldn't Delete Item. Please Try Again Later.", { autoClose: 2000 });
          return [];
        }

        toast.error("Item Removed from Cart", { autoClose: 2000 });
        dispatch({
            type: types.REMOVE_FROM_CART,
            line_item_id: line_item.id,
        })

        return data;
      })
    .catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({
              type: types.REMOVE_FROM_CART,
              line_item_id: line_item.id,
          })
        }
      }
    })
};

export const emptyCart = (cart_id) => (dispatch) => {
  if (!cart_id) {
    toast.error("All Items Removed From Cart", { autoClose: 2000 });
    dispatch(clearCart());

  } else {
    axios.post('/api/shop/empty_cart', { cart_id })
      .then(({data}) => {
        if (data.error) {
          toast.error("Something Went Wrong, Couldn't Delete Item. Please Try Again Later.", { autoClose: 2000 });
          return [];
        }

        toast.error("All Items Removed From Cart", { autoClose: 2000 });
        dispatch(clearCart());

        return data;
      })
      .catch(error => {
        toast.error("Please log back in to continue.", { autoClose: 2000 });
        dispatch(receiveUser(null));
        dispatch(clearProducts());
        dispatch(clearStores());
        dispatch(receiveCart({}, []));
        return [];
      })
  }
};


export const removeSilent = (line_item) => (dispatch) => {
  dispatch({
      type: types.REMOVE_FROM_CART,
      line_item_id: line_item.id,
  })
}

export const editQty = (item,product,variant,qty,incDecUp, cart_id) => (dispatch) => {
  let asGuest = false;
  if (!cart_id) {
    asGuest = true;
  }

  let price = product.sale_price ? product.sale_price : product.price;

  product.price = price;

  let newQty;
  if (incDecUp === 'increment') {
    newQty = parseFloat(item.qty) + qty

  } else if (incDecUp === 'decrement') {
    newQty = parseFloat(item.qty) - qty

  } else { //== udpate or create
    newQty = qty;
  }

  var tax_amount = 0.0;
  if (product.taxable) {
    tax_amount = getTaxAmount({price: price, qty: newQty}, product);
  }
  product.tax_amount = tax_amount;

  dispatch(addToCartUnsafe(item.id,product,variant,newQty))

  if (!asGuest) {

    axios.post('/api/shop/add_to_cart', {tax_amount: tax_amount, line_id: item.id, qty: newQty, cart_id: cart_id})
      .then(({data}) => {
        if (data.error) {
          toast.error("Item Not Updated. Please Try Again Later.", { autoClose: 2000 });
          return [];
        }
        // toast.success("Item Quantity Updated.", { autoClose: 2000 });
        return data;
      });
  }
}


export const silentEditQty = (item,product,variant,qty,incDecUp, cart_id) => (dispatch) => {
  if (!qty) {
    return;
  }

  let asGuest = false;
  if (!cart_id) {
    asGuest = true;
  }

  let price = product.sale_price ? product.sale_price : product.price;

  product.price = price;

  let newQty;
  if (incDecUp === 'increment') {
    newQty = parseFloat(item.qty) + qty

  } else if (incDecUp === 'decrement') {
    newQty = parseFloat(item.qty) - qty

  } else { //== udpate or create
    newQty = qty;
  }

  var tax_amount = 0.0;
  if (product.taxable) {
    tax_amount = getTaxAmount({price: price, qty: newQty}, product);
  }

  product.tax_amount = tax_amount;

  dispatch(addToCartUnsafe(item.id,product,variant,newQty))

  if (!asGuest) {
    axios.post('/api/shop/add_to_cart', {tax_amount: tax_amount, line_id: item.id, qty: newQty, cart_id: cart_id})
      .then(({data}) => {
        if (data.error) {
          return [];
        }
        return data;
      });
  }
}

//GUEST Actions
export const refreshGuestHomeStore = (store) => dispatch => {

  axios.post('/api/shop/select_store_guest', {store_id: store.id, cart_id: 0})
    .then(({data}) => {
      dispatch(getSingleStore(data.store));
      pdListModel1.setProducts(data.store.products);
      dispatch(receiveProducts(data.store.products));

      return data;
    });


}

export const selectAStoreAsGuest = (store_id, cart_id, newStore, callback) => dispatch => {
  if (newStore) {
    dispatch(clearProducts());
    var categories = []
    dispatch(filterCategory(categories));
  }

  dispatch(fetchProductsBegin());
  axios.post('/api/shop/select_store_guest', {store_id, cart_id})
    .then(({data}) => {
      if (data.error) {
        dispatch(receiveUser(null));
        dispatch(clearProducts());
        dispatch(receiveCart({}, []));
        return null;
      }

	  pdListModel1.setProducts(data.store.products);

      dispatch(getSingleStore(data.store));
      dispatch(receiveProducts(data.store.products));
      dispatch(receiveLineItem(data.line_items ? data.line_items : []));

      if (data.cartChanged) {
        toast.error("Some Items in Your Cart Have Changed. Please Review Your Cart.", { autoClose: 5000 });
      }

      if (callback) {
        callback()
      }

      return data;
    });
}






//Compare Products
export const addToCompare = (product) => (dispatch) => {
    toast.success("Item Added to Compare");
    dispatch(addToCompareUnsafe(product))

}
export const addToCompareUnsafe= (product) => ({
    type: types.ADD_TO_COMPARE,
    product
});
export const removeFromCompare = product_id => ({
    type: types.REMOVE_FROM_COMPARE,
    product_id
});


// Filters
export const filterBrand = (brand) => ({
    type: types.FILTER_BRAND,
    brand
});
export const filterCategory = (category) => ({
    type: types.FILTER_CATEGORY,
    category
});

export const filterName = (name) => ({
    type: types.FILTER_NAME,
    name
});
export const filterColor = (color) => ({
    type: types.FILTER_COLOR,
    color
});
export const filterPrice = (value) => ({
    type: types.FILTER_PRICE,
    value
});
export const filterSort = (sort_by) => ({
    type: types.SORT_BY,
    sort_by
});


// Currency
export const changeCurrency = (symbol) => ({
    type: types.CHANGE_CURRENCY,
    symbol
});
