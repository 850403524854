import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    RECEIVE_CART,
    RECEIVE_LINE_ITEM,
    CLEAR_CART } from "../constants/ActionTypes";

import {
  parseMoney, addCost, multiplyByQty
} from '../components/helpers';

export default function cartReducer(state = {
    cart: [],
    cartObj: {}
}, action) {
    switch (action.type) {
        case RECEIVE_CART:
        return {  cart: action.line_items, cartObj: action.cart };

        case RECEIVE_LINE_ITEM:
        return {...state,
          cart: action.line_items
        }

        case ADD_TO_CART:
          if (!action.qty) {
            return { ...state }
          }

            if (state.cart.findIndex(product => action.id ? product.id === action.id && product.sp_id === action.product.id : product.sp_id === action.product.id) !== -1) {
              if (action.variant) {
                if (state.cart.findIndex(product => product.variant && product.variant === action.variant) !== -1) {
                  const cart = state.cart.reduce((cartAcc, product) => {
                      if (action.id ? product.id === action.id && product.sp_id === action.product.id : product.sp_id === action.product.id) {
                        if (product.variant === action.variant) {
                          let price = multiplyByQty(product.price, action.qty)
                          let tax_amount = action.product.tax_amount;

                          cartAcc.push({ ...product, tax_amount: tax_amount, qty: action.qty, variant: product.variant, sum: parseMoney(price) }) // Increment qty
                        } else {
                          cartAcc.push(product)
                        }
                      } else {
                          cartAcc.push(product)
                      }

                      return cartAcc
                  }, [])

                  return { ...state, cart }
                }
              } else {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (action.id ? product.id === action.id && product.sp_id === action.product.id : product.sp_id === action.product.id) {
                      let price = multiplyByQty(product.price, action.qty)
                      let tax_amount = action.product.tax_amount;

                      cartAcc.push({ ...product, tax_amount: tax_amount, qty:  action.qty, variant: product.variant, sum: parseMoney(price) }) // Increment qty

                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return { ...state, cart }
              }
            }

            let price = multiplyByQty(action.product.price, action.qty)

            return { ...state, cart: [...state.cart, { ...action.product, id: action.id, qty: action.qty, variant: action.variant ? action.variant : '', sum: parseMoney(price) }] }

        case REMOVE_FROM_CART:
            return {
              ...state,
              cart: state.cart.filter(item => item.id !== action.line_item_id )
            }

        case CLEAR_CART:
          return {
            ...state,
            cart: []
          }
        default:
    }
    return state;
}
