import React from 'react';
import {Link} from 'react-router-dom'

function LogoImage(props) {
    return (
      <Link aria-label="Open categories" to={`${process.env.PUBLIC_URL}/`} onClick={props.openCategories}>
        {props.logoLarge ?
          <img src={`https://swad.nyc3.cdn.digitaloceanspaces.com/patelbros/logo-nav.png`} alt="Patel Brothers Logo" className="img-fluid" style={{objectFit: 'contain', height: '125px'}} />
          :
          <img src={`https://swad.nyc3.cdn.digitaloceanspaces.com/patelbros/logo-nav-small.png`} alt="Patel Brothers Logo" className="img-fluid logo-sm-nav-img" style={{objectFit: 'contain'}} />
        }
      </Link>
    );
}

export default LogoImage;
