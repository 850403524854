import React from 'react';
import { observer } from 'mobx-react';
import pdListModel1 from '../../../models/ProductListingModel';
import { Link } from 'react-router-dom';
import ReactImageProcess from 'react-image-process';
import {
  isMobile
} from "react-device-detect";
import SearchFilter from "./search-bar";

@observer
export default class CategoryListing extends React.Component<{}, {}> {
	render() {
		const model = pdListModel1;
		const cats = model.departments.slice();
		const sub_cats = model.categories.slice();
		return (
			<div className="container pt-3 home-container">
				<div className="container-fluid">
					<div className="product-list-component">

						<div className="row ad-banner-row" style={{padding: '0'}}>
							<div className="container" style={{maxWidth: '100%', padding: '0'}}>
								<div className="row">
								{/*<div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 col-grid-box">*/}
								<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 col-grid-box" style={{padding: '0'}}>
									<Link to={`${process.env.PUBLIC_URL}/categories/all`} aria-label="See All Products" onClick={() => this.onCatClicked('All', '')} >
										<div className="img-wrapper">
											<div className="front">
											<img src={isMobile ? `https://swad.nyc3.cdn.digitaloceanspaces.com/east-windsor-banner-mobile.png` : `https://swad.nyc3.cdn.digitaloceanspaces.com/east-windsor-banner.jpg`} alt="Welcome Banner"/>
											</div>
										</div>
									</Link>
								</div>
								{/*<div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-none d-sm-none d-md-block col-grid-box">
									<img src={`${process.env.PUBLIC_URL}/assets/images/testad.png`} alt="Special Banner" />
								</div>*/}

								</div>
							</div>
						</div>

						<div className="container">
							<div className='d-block d-lg-none d-xl-none mb-3 border-bottom'>
								<SearchFilter />
							</div>

							<div className="row" style={{ marginBottom: '50px' }}>
                    {/*cats.findIndex(c => c.department.name == 'Holiday/Seasonal') != -1 ?
                      <div className={'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 col-grid-box'} key='holiday-category'>
                        {this.renderSuperCat(model.subdepartments('Holiday/Seasonal'))}
                      </div>
                      :
                      null
                    */}
										<div className={'col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 col-grid-box'} key='all-category'>
											{this.renderAllCat(sub_cats)}
										</div>
										<div className={'col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 col-grid-box'} key='produce-category'>
											{this.renderProduceCat()}
										</div>
										{cats.map((cat, index) =>
											{
												if (cat.department.name == 'produce') {
													return <div key={index}></div>
												}
												var sub_categories = model.subdepartments(cat.department.name);
												for (let i=sub_categories.length; i < 4; i++) {
													sub_categories.push({category: ''})
												}
												return (<div className={'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 col-grid-box'} key={index}>
																	{this.renderOneCategory(cat, sub_categories)}
																</div>)
										})
										}
							</div>
						</div>

					</div>
				</div>
			</div>
		);
	}

	onCatClicked(catName: string, department: string) {
		pdListModel1.setFilterCategoryAndDepartment([catName], department);
	}

	onSeeAllClicked(subs: any, department: string) {
		var catNames = subs.map(s => s.category);
		pdListModel1.setFilterCategoryAndDepartment(catNames, department);
	}

	toSlug(name: string) {
			return name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,'')
	}

  renderSuperCat(subs: any) {
    let sub_categories = subs;
    for (let i=sub_categories.length; i < 4; i++) {
      sub_categories.push({category: ''})
    }

    return (
        <div className="card border-0 mb-3" style={{overflow: 'hidden'}}>
          <Link to={`${process.env.PUBLIC_URL}/categories/holidayseasonal`} aria-label={`See all Holiday / Seasonal`} onClick={() =>{this.onSeeAllClicked(sub_categories, 'Holiday/Seasonal')}} >
            <div className="card-footer bg-white text-success font-weight-bold text-left border-success department-title" style={{textTransform: 'capitalize', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              Holiday / Seasonal
            </div>
          </Link>
          <div className="card-body text-center py-0">
            <div className="row">

              <div className="col-xl-10 col-lg-8 col-md-8 d-xs-block d-sm-block d-md-none" style={{padding: '0'}}>
                <Link to={`${process.env.PUBLIC_URL}/categories/holidayseasonal`} aria-label={`See all Holiday / Seasonal`} onClick={() => {this.onSeeAllClicked(sub_categories, 'Holiday/Seasonal')}} >
                  <div className="img-wrapper">
                    <div className="front">
                      <img src={`https://swad.nyc3.cdn.digitaloceanspaces.com/categories/HolidaySeasonalSuper.png`} className="img-fluid" alt="Category Holiday / Seasonal" style={{width: '100%'}} />
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xl-2 col-lg-4 col-md-4 d-sm-none d-md-block" style={{padding: '0'}}>
                <div className="img-wrapper pt-3 pl-3">
                  <div className="front">
                    {sub_categories.slice(0, 3).map((s, index) =>
                      <Link key={index} to={`${process.env.PUBLIC_URL}/categories/holidayseasonal/${this.toSlug(s.category)}`} aria-label={`See all Holiday / Seasonal ${s.category}`} onClick={() => s.category == '' ? this.onSeeAllClicked(sub_categories, 'Holiday/Seasonal') : this.onCatClicked(s.category, 'Holiday/Seasonal')} >
                        <p className="text-left" style={{textTransform: 'capitalize', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          {s.category == '' ?
                            (<span><br/></span>) :
                            s.category }
                        </p>
                      </Link>
                      )
                    }
                    <Link to={`${process.env.PUBLIC_URL}/categories/holidayseasonal`}  aria-label={`See All Holiday / Seasonal`} onClick={() => {this.onSeeAllClicked(sub_categories, 'Holiday/Seasonal')}} >
                      <p className="text-center" style={{fontWeight: 'bold', textTransform: 'capitalize'}}>
                        See All
                      </p>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-xl-10 col-lg-8 col-md-8 d-none d-md-block" style={{padding: '0'}}>
                <Link to={`${process.env.PUBLIC_URL}/categories/holidayseasonal`}  aria-label={`See All Holiday / Seasonal`} onClick={() => {this.onSeeAllClicked(sub_categories, 'Holiday/Seasonal')}} >
                  <div className="img-wrapper">
                    <div className="front">
                      <img src={`https://swad.nyc3.cdn.digitaloceanspaces.com/categories/HolidaySeasonalSuper.png`} className="img-fluid" alt="Category Holiday / Seasonal" style={{width: '100%'}} />
                    </div>
                  </div>
                </Link>
              </div>

            </div>
          </div>
        </div>
    );
  }

	renderAllCat(cats: any) {
		let categories = cats.slice(0, 3)
		return (
				<div className="card border-0 mb-3" style={{overflow: 'hidden'}}>
					<Link to={`${process.env.PUBLIC_URL}/categories/all`} aria-label="See All Products" onClick={() => this.onCatClicked('All', '')} >
						<div className="card-footer bg-white text-success font-weight-bold text-left border-success department-title">
							All
						</div>
					</Link>
					<div className="card-body text-center py-0">
						<div className="row">

						<div className="col-xl-7 col-lg-6 col-md-6 d-xs-block d-sm-block d-md-none" style={{padding: '0'}}>
							<Link to={`${process.env.PUBLIC_URL}/categories/all`} aria-label="See All Products" onClick={() => this.onCatClicked('All', '')} >
								<div className="img-wrapper">
									<div className="front">
										<img src={`https://swad.nyc3.cdn.digitaloceanspaces.com/categories/all.png`} className="img-fluid" alt="Category All" style={{width: '100%'}}/>
									</div>
								</div>
							</Link>
						</div>

						<div className="col-xl-5 col-lg-6 col-md-6 d-sm-none d-md-block " style={{padding: '0'}}>
								<div className="img-wrapper pt-3 pl-3">
									<div className="front">
										{categories.map((cat, index) =>
											<Link key={index} to={`${process.env.PUBLIC_URL}/categories/${this.toSlug(cat.category)}`} aria-label={`See products of ${cat.category}`} onClick={() => this.onCatClicked(cat.category, '')} >
												<p className="text-left" style={{textTransform: 'capitalize', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
													{cat.category}
												</p>
											</Link>
											)
										}
										<Link to={`${process.env.PUBLIC_URL}/categories/all`} aria-label="See All Products" onClick={() => this.onCatClicked('All', '')} >
											<p className="text-center" style={{fontWeight: 'bold', textTransform: 'capitalize'}}>
												See All
											</p>
										</Link>
									</div>
								</div>
							</div>

							<div className="col-xl-7 col-lg-6 col-md-6 d-none d-md-block" style={{padding: '0'}}>
								<Link to={`${process.env.PUBLIC_URL}/categories/all`} aria-label="See All Products" onClick={() => this.onCatClicked('All', '')} >
									<div className="img-wrapper">
										<div className="front">
											<img src={`https://swad.nyc3.cdn.digitaloceanspaces.com/categories/all.png`} className="img-fluid" alt="Category All" style={{width: '100%'}}/>
										</div>
									</div>
								</Link>
							</div>

						</div>
					</div>
				</div>
		);
	}

	renderProduceCat() {
		var subs = ['produce', '', '']
		return (
				<div className="card border-0 mb-3" style={{overflow: 'hidden'}}>
					<Link to={`${process.env.PUBLIC_URL}/categories/produce`} aria-label={`See all Produce`} onClick={() =>{pdListModel1.setFilterCategoryAndDepartment(['produce'], 'produce')}} >
						<div className="card-footer bg-white text-success font-weight-bold text-left border-success department-title" style={{textTransform: 'capitalize', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
							Fresh Produce
						</div>
					</Link>
					<div className="card-body text-center py-0">
						<div className="row">

							<div className="col-xl-7 col-lg-6 col-md-6 d-xs-block d-sm-block d-md-none" style={{padding: '0'}}>
								<Link to={`${process.env.PUBLIC_URL}/categories/produce`} aria-label={`See all Produce`} onClick={() => {pdListModel1.setFilterCategoryAndDepartment(['produce'], 'produce')}} >
									<div className="img-wrapper">
										<div className="front">
											<img src={`https://swad.nyc3.cdn.digitaloceanspaces.com/categories/produce.png`} className="img-fluid" alt="Category Produce" style={{width: '100%'}} />
										</div>
									</div>
								</Link>
							</div>

							<div className="col-xl-5 col-lg-6 col-md-6 d-sm-none d-md-block" style={{padding: '0'}}>
								<div className="img-wrapper pt-3 pl-3">
									<div className="front">
										{subs.slice(0, 3).map((s, index) =>
												<Link key={index} to={`${process.env.PUBLIC_URL}/categories/produce`} aria-label={`See all ${s == "" ? 'Produce' : s}`} onClick={() => {pdListModel1.setFilterCategoryAndDepartment(['produce'], 'produce')}} >
													<p className="text-left" style={{textTransform: 'capitalize', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
														{s == '' ?
															(<span><br/></span>) :
															s }
													</p>
												</Link>
											)
										}

										<Link to={`${process.env.PUBLIC_URL}/categories/produce`}  aria-label={`See All Produce`} onClick={() => {pdListModel1.setFilterCategoryAndDepartment(['produce'], 'produce')}} >
											<p className="text-center" style={{fontWeight: 'bold', textTransform: 'capitalize'}}>
												See All
											</p>
										</Link>
									</div>
								</div>
							</div>

							<div className="col-xl-7 col-lg-6 col-md-6 d-none d-md-block" style={{padding: '0'}}>
								<Link to={`${process.env.PUBLIC_URL}/categories/produce`} aria-label={`See all Produce`} onClick={() => {pdListModel1.setFilterCategoryAndDepartment(['produce'], 'produce')}} >
									<div className="img-wrapper">
										<div className="front">
											<img src={`https://swad.nyc3.cdn.digitaloceanspaces.com/categories/produce.png`} className="img-fluid" alt="Category Produce" style={{width: '100%'}} />
										</div>
									</div>
								</Link>
							</div>

						</div>
					</div>
				</div>
		);
	}

	renderOneCategory(cat: any, subs: any) {

		var imgSrc = `https://swad.nyc3.cdn.digitaloceanspaces.com/categories/${cat.department.name.replace('/', '').split(' ').join('')}.png`;

		return (
				<div className="card border-0 mb-3" style={{overflow: 'hidden'}}>
					<Link to={`${process.env.PUBLIC_URL}/categories/${this.toSlug(cat.department.name)}`} aria-label={`${cat.department.name}`} onClick={() => this.onSeeAllClicked(subs, cat.department.name)} >
						<div className="card-footer text-success font-weight-bold bg-white text-left department-title" style={{textTransform: 'capitalize', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
							{cat.department.name}
						</div>
					</Link>
					<div className="card-body text-center py-0">
					<div className="row">

						<div className="col-xl-5 col-lg-4 col-md-4 d-xs-block d-sm-block d-md-none" style={{padding: '0'}}>
							<Link to={`${process.env.PUBLIC_URL}/categories/${this.toSlug(cat.department.name)}`} aria-label={`See all ${cat.department.name}`} onClick={() => this.onSeeAllClicked(subs, cat.department.name)} >
								<div className="img-wrapper">
									<div className="front">
										<img src={imgSrc} className="img-fluid" alt={`Department ${cat.department.name}`}
											onError={(e) => {
												(e.target as HTMLImageElement).src = 'https://swad.nyc3.cdn.digitaloceanspaces.com/image-placeholder.jpg'
											}} />
									</div>
								</div>
							</Link>
						</div>

						<div className="col-xl-7 col-lg-8 col-md-8 d-sm-none d-md-block" style={{padding: '0'}}>
							<div className="img-wrapper pt-3 pl-3">
								<div className="front">
									{subs.slice(0, 3).map((s, index) =>
										<Link key={index} to={`${process.env.PUBLIC_URL}/categories/${this.toSlug(cat.department.name)}/${this.toSlug(s.category)}`} aria-label={`See all ${cat.department.name} ${s.category}`} onClick={() => s.category == '' ? this.onSeeAllClicked(subs, cat.department.name) : this.onCatClicked(s.category, cat.department.name)} >
											<p className="text-left" style={{textTransform: 'capitalize', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
												{s.category == '' ?
													(<span><br/></span>) :
													s.category }
											</p>
										</Link>
										)
									}
									<Link to={`${process.env.PUBLIC_URL}/categories/${this.toSlug(cat.department.name)}`} aria-label={`See All ${cat.department.name}`} onClick={() => this.onSeeAllClicked(subs, cat.department.name)} >
										<p className="text-center" style={{fontWeight: 'bold', textTransform: 'capitalize'}}>
											See All
										</p>
									</Link>
								</div>
							</div>
						</div>

						<div className="col-xl-5 col-lg-4 col-md-4 d-none d-md-block" style={{padding: '0'}}>
							<Link to={`${process.env.PUBLIC_URL}/categories/${this.toSlug(cat.department.name)}`} aria-label={`See all ${cat.department.name}`} onClick={() => this.onSeeAllClicked(subs, cat.department.name)} >
								<div className="img-wrapper">
									<div className="front">
										<img src={imgSrc} className="img-fluid" alt={`Department ${cat.department.name}`}
											onError={(e) => {
												(e.target as HTMLImageElement).src = 'https://swad.nyc3.cdn.digitaloceanspaces.com/image-placeholder.jpg'
											}} />
									</div>
								</div>
							</Link>
						</div>

					</div>
					</div>
				</div>
		);
	}
}
