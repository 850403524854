import React, {Component} from 'react';
import { Redirect} from 'react-router-dom';
import { connect } from 'react-redux'
import { logoutUser } from '../../actions'
import store from '../../store';

const axios = require('axios');

class SessionCheck extends Component {
  _isMounted = false;

    constructor(props) {
    super(props);

    this.state = {
      activeSession: true
    }
  }

  componentDidMount() {
    this._isMounted = true;

    if (!this.props.user) {
      this.setState({activeSession: false});
      axios.post('/api/shop/logout').then(data => {});
      store.dispatch(logoutUser());

    } else {
      axios.get('/api/shop/active_session')
        .then(({data}) => {})
        .catch(error => {
          if (this._isMounted) {
            this.setState({
              activeSession: false
            });
          }
          store.dispatch(logoutUser());
        });
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
      if (this.state.activeSession === false) {
        return <Redirect to={'/'} />;
      }

      return (
          <div>
          </div>
      )
  }
}


const mapStateToProps = (state) => ({
    user: state.userData.user,
})

export default connect(
  mapStateToProps, {logoutUser}
)(SessionCheck)
