import React, {Component} from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import {
  parseMoney, multiplyByQty, moneyFormat
} from '../helpers';

class orderSuccess extends Component {

    render (){
      if (!this.props.location.state.order) {
        return <Redirect to={'/'} />;
      }

        const {items, orderTotal, order, hasProduce, subtotal, tax} = this.props.location.state;

        return (
            <div>
                <section className="section-b-space light-layout">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="success-text">
                                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                                    <h2>thank you</h2>
                                    <p>Your Payment has been authorized.</p>
                                    <p>Order ID: #{order.order_num}</p>
                                    <h4 style={{padding: '10px 20px', fontWeight: 'bold', textTransform: 'none'}}>
                                      You will receive a text or e-mail letting you know your entire order is ready for pick up.
                                      <br/>Please make sure to add our e-mail shop@patelbros.com to your trusted contacts so it does not go into spam.
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                          <h3 className="order-details-title">your order details</h3>

                            <div className="col-lg-12">
                                <div className="product-order">
                                    {items.map((item, index) => {
                                      var product = item.product;

                                      var isPerLb = false;
                                      if (product.tags) {
                                        isPerLb = product.tags.findIndex(t => t && t.toLowerCase() === 'per lb') !== -1;
                                      }

                                      return <div className="row product-order-detail" key={index}>
                                                <div className="col-3">
                                                <img src={product.images[0]} alt={`Checkout-Success ${product.name}-${index}`} className="img-fluid" style={{objectFit: 'contain'}} onError={(e) => {
                                                  e.target.src = 'https://swad.nyc3.cdn.digitaloceanspaces.com/image-placeholder.jpg'
                                                }} />
                                                </div>
                                                <div className="col-3 order_detail">
                                                    <div>
                                                        <h4>{product.name}</h4>
                                                        <h5>{isPerLb ? '' : item.variant}</h5>
                                                    </div>
                                                </div>
                                                <div className="col-3 order_detail text-right">
                                                    <div style={{width: '100%'}}>
                                                        <h4>quantity</h4>
                                                        <h5>
                                                        {item.qty}
                                                        {product.tags.map(t => {
                                                            if (t === 'per lb') {
                                                              //eslint-disable-next-line
                                                              return ` Lb${item.qty != 1 ? 's' : ''}`
                                                            } else {
                                                              return ''
                                                            }
                                                          })
                                                        } @ {moneyFormat(item.price)}
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className="col-3 text-right order_detail">
                                                    <div style={{width: '100%'}}>
                                                        <h4>price</h4>
                                                        <h5>
                                                          {moneyFormat( multiplyByQty(item.price, item.qty) )} {item.tax_amount && parseMoney(item.tax_amount) > 0 ?  <span style={{fontSize: '10px'}}>+ tax</span> : null}
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                    })}
                                    <div className="success-page-totals">
                                    <div className="total-sec">
                                        <ul>
                                            <li>subtotal <span>{moneyFormat(subtotal)}</span></li>
                                            <li>Tax <span>{moneyFormat(tax)}</span></li>
                                            {hasProduce ?
                                              (
                                                <li>Pre-Authorization for Produce <span>$20.00</span></li>
                                              )
                                              : ''
                                            }
                                        </ul>
                                    </div>
                                    <div className="final-total">
                                        <h3>total <span>{moneyFormat(orderTotal)}</span></h3>
                                    </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(
    mapStateToProps
)(orderSuccess)
