import React, { Component } from 'react';
import Pace from 'react-pace-progress'
import NavBar from "./common/error-navbar";
import TopBar from "./common/topbar";
import LogoImage from "./common/logo";

class HeaderOne extends Component {

    constructor(props) {
      super(props);

  		this.state = {
  			isLoading:false
  		}
    }

    /*=====================
         Pre loader
         ==========================*/
    componentDidMount() {
      if (!window.location.pathname.includes('/processing')) {
        setTimeout(function() {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);

        this.setState({ open: true });
  		}
    }

    openNav() {
        let openmyslide = document.getElementById("mySidenav");
        if(openmyslide){
            openmyslide.classList.add('open-side')
		    }
    }

  	load = ()=>{
  		this.setState({isLoading: true});
  		fetch().then(()=>{
  			// deal with data fetched
  			this.setState({isLoading: false})
  		})
  	};

	render() {

		return (
			<div>
				<header id="sticky" className="sticky">
					{this.state.isLoading ? <Pace color="#27ae60"/> : null}
					{/*Top Header Component*/}
					<TopBar/>

					<div className="container">
						<div className="row">
							<div className="col-sm-12">
								<div className="main-menu">
									<div className="menu-left">
										<div className="brand-logo">
											<LogoImage logo={this.props.logoName} />
										</div>
									</div>
									<div className="menu-right pull-right">
										{/*Top Navigation Bar Component*/}
										<NavBar/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
			)
	}
}

export default HeaderOne;
