import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import Breadcrumb from "../common/breadcrumb";
import SessionCheck from "../common/SessionCheck";
import { connect } from 'react-redux'
import store from '../../store';
import { logoutUser } from '../../actions'

var axios = require('axios');
var moment = require('moment');

class Dashboard extends Component {

    constructor (props) {
        super (props)

        this.state = {
          orders: [],
          loadingOrders: false
        }
    }

    componentDidMount() {
      if (this.props.user) {
        this.setState({loadingOrders: true});
        axios.get('/api/shop/account_orders')
        .then(({data}) => {
          this.setState({orders: data})
          this.setState({loadingOrders: false})

        })
        .catch(error => {
          this.setState({loadingOrders: false})
        })
      }
    }

    logOut = e => {
      e.preventDefault();

      store.dispatch(logoutUser());
    }

    render (){
      if (!this.props.user) {
        return <Redirect to={'/login'} />;
      }

        return (
            <div>
              <SessionCheck />
                <Breadcrumb title={'Dashboard'}/>


                {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="account-sidebar">
                                    my account
                                </div>
                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                    <span className="filter-back">
                                        <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                    </span>
                                    </div>
                                    <div className="block-content">
                                        <ul>
                                            <li tabIndex={0} className="active"><Link to={`${process.env.PUBLIC_URL}/account/dashboard`}>Account Info</Link></li>
                                            <li tabIndex={0}><Link to={`${process.env.PUBLIC_URL}/account/orders`}>My Orders</Link></li>
                                            <li tabIndex={0}><Link to={`${process.env.PUBLIC_URL}/account/profile/change-password`}>Change Password</Link></li>
                                            <li tabIndex={0}><Link to={`${process.env.PUBLIC_URL}/account/profile/change-preferences`}>My Preferences</Link></li>
                                            <li tabIndex={0} className="last"><a href="/" onClick={this.logOut}>Log Out</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        <div className="page-title">
                                            <h2>My Dashboard</h2>
                                        </div>
                                        <div className="box-account box-info">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="box">
                                                        <div className="box-title">
                                                            <h3>Account Information</h3>
                                                        </div>
                                                        <div className="box-content">
                                                            <h5>{this.props.user.first_name} {this.props.user.last_name}</h5>
                                                            <h5>{this.props.user.email}</h5>
                                                            <h5><Link to={`${process.env.PUBLIC_URL}/account/profile/change-password`}>Change Password</Link></h5>
                                                            <h5><Link to={`${process.env.PUBLIC_URL}/account/profile/change-preferences`}>My Preferences</Link></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="box">
                                                        <div className="box-title">
                                                            <h3>Latest Order</h3>
                                                            <Link to={`${process.env.PUBLIC_URL}/account/orders`}>View All</Link>
                                                        </div>
                                                        <div className="box-content">
                                                          {this.state.orders.length > 0 ?
                                                            (
                                                              <div>
                                                                <h5><Link to={{pathname:`${process.env.PUBLIC_URL}/account/order/${this.state.orders[0].id}`, state: {order: this.state.orders[0]}}}>Order #{this.state.orders[0].order_num}</Link></h5>
                                                                <h5>{this.state.orders[0].products.length} Items - {this.state.orders[0].total}</h5>
                                                                <h5>Pickup Date:{moment(this.state.orders[0].selected_time_slot.pickup_date).format("MM/DD/YYYY")} @ {this.state.orders[0].selected_time_slot.pickup_time}</h5>
                                                                <h5>{this.state.orders[0].store.name}</h5>
                                                                <h5>{this.state.orders[0].order_status}</h5>
                                                              </div>

                                                            )
                                                            :
                                                            (
                                                              <p>
                                                              {this.state.loadingOrders ?
                                                                'Loading Orders...'
                                                                :
                                                                'You have not placed an order yet.'
                                                              }
                                                              </p>
                                                            )
                                                          }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.userData.user,
})

export default connect(
    mapStateToProps, {logoutUser}
)(Dashboard)
