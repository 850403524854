import {
    RECEIVE_USER, RESERVE_SLOT, UPDATE_PREFERENCES } from "../constants/ActionTypes";


const initialState = {
    user: null,
    slot: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_USER:
          if (action.user == null) { //null user means user logged out, so delete the slot as well
            //TODO: delete the reserved slot from the database if checkout did not happen yet
            return {
              ...state,
              user: action.user,
              slot: null
            }
          }

            return { ...state,
                user: action.user };

        case UPDATE_PREFERENCES:
          var user = state.user
          user.marketing_opt_in = action.marketing_opt_in
          user.get_order_texts = action.get_order_texts
          return {
            ...state,
            user: user,
          }

        case RESERVE_SLOT:
          return {
            ...state,
            slot: action.slot
          }
        default:
            return state;
    }
};
export default userReducer;
