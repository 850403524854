import React, {Component} from 'react';
import HeaderOne from './common/headers/header-one';
import FooterOne from "./common/footers/footer-one";
import ThemeSettings from "./common/theme-settings";
import ErrorCatch from './errorCatch';
import AuthNewCommunicator from './checkout/communicator';
import AuthNetNewCommunicator from './checkout/authnetcommunicator';

// import {
//   isMobile
// } from "react-device-detect";


class App extends Component {
    render() {
      if (window.location.pathname.includes('/processing')) {
        return (
          <div>
            {this.props.children}
          </div>
        );
      }

        return (
          <ErrorCatch>
            <AuthNewCommunicator />
            <AuthNetNewCommunicator />
            <div>
                <HeaderOne logoName={'logo.png'}/>
                {this.props.children}
                <FooterOne logoName={'logo.png'}/>

                <ThemeSettings />
            </div>
          </ErrorCatch>
        );
    }
}

export default App;
