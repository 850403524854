import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';

interface MyState {
	email: string;
	newPwd: string;
	confirmPwd: string;
	message: string;
	msgColor: string;
}

export default class ResetPassword extends React.Component<RouteComponentProps<any>, MyState> {
	constructor(props) {
		super(props)
		this.state = {
			email: '',
			newPwd: '',
			confirmPwd: '',
			message: '',
			msgColor: 'green'
		}
	}

	componentDidMount() {
		if (this.props.match.params.token.indexOf('ask-email-') == 0)
			this.setState({email: this.props.match.params.token.replace('ask-email-', '')});
	}

	render() {
		return (this.props.match.params.token.indexOf('ask-email-') == 0 ? this.renderAskEmail() : this.renderSubmitNewPwd());
	}

	renderAskEmail() {
		return (
		<div className="container mx-auto py-4">
			<h3 className='py-2'>Reset Your Password</h3>
			<div className='py-2'>
				<input type="text" name="email" value={this.state.email} className="form-control"
					id="email" placeholder="Your Email Address" required={true}
					onChange={(e) => this.setState({ email: e.target.value })} />
			</div>
			<div className="text-center">
				<p style={{ color: this.state.msgColor, textAlign: 'center' }}>{this.state.message}</p>
				{this.state.msgColor == 'black' ?
					<Link to={`${process.env.PUBLIC_URL}/register`} className="text-primary-theme" style={{fontSize: '18px'}}>Create A New Account</Link>
					:
					null
				}
			</div>
			<div className="pt-3 text-center">
				<button aria-labelledby="Reset password" onClick={() => this.resetPwd()} className="btn btn-solid">Reset Password</button>
			</div>
		</div>
		)
	}

	renderSubmitNewPwd() {
		return (
				<div className="container mx-auto py-4">
					<h3 className='py-2'>Reset Your Password</h3>
					<div>
						<input type="password" name="newPwd" value={this.state.newPwd} className="form-control"
							id="newPwd" placeholder="New Password" required={true}
							onChange={(e) => this.setState({ newPwd: e.target.value })} />
					</div>
					<div className='py-2'>
						<input type="password" name="confirmPwd" value={this.state.confirmPwd} className="form-control"
							id="confirmPwd" placeholder="Confirm New Password" required={true}
							onChange={(e) => this.setState({ confirmPwd: e.target.value })} />
					</div>
					<div>
						<p style={{ color: this.state.msgColor }}>{this.state.message}</p>
					</div>
					<div className="pt-3 text-center">
						<button aria-labelledby="Submit new password" onClick={() => this.submitNewPwd()} className="btn btn-solid">Submit</button>
					</div>
				</div>
		)
	}

	async resetPwd() {
		try {
			if (this.state.email === '' || !this.state.email.includes('@')) {
				this.setState({ message: '* Please enter the email address associated with your account.', msgColor: 'red' });
				return;
			}
			const data = { email: this.state.email };
			const resp = await axios.post('/api/shop/reset_password1', data);
			if (resp.status == 200)
				this.setState({ message: 'Please check your email to complete reseting your password!', msgColor: 'green' });
			else {
				if (resp.status == 400) {
					this.setState({ message: 'Failed to reset password: ' + resp.statusText, msgColor: 'black' });

				} else {
					this.setState({ message: 'Failed to reset password: ' + resp.statusText, msgColor: 'red' });
				}
			}
		}
		catch (ex) {
			if (ex.response && ex.response.status == 400) {
				this.setState({ message: 'Our new site will require you to sign up again, we apologize for the inconvenience.', msgColor: 'black' });
			} else {
				this.setState({ message: 'Error: ' + ex.message, msgColor: 'red' });
			}
		}
	}

	async submitNewPwd() {
		try {
			if (this.state.newPwd === '' || this.state.confirmPwd === '') {
				this.setState({ message: '* Please fill out all the fields correctly.', msgColor: 'red' });
				return;
			}
			if (this.state.newPwd.length < 6) {
				this.setState({ message: '* Your new password must be at least 6 characters long.', msgColor: 'red' });
				return;
			}
			if (this.state.newPwd !== this.state.confirmPwd) {
				this.setState({ message: '* Passwords do not match.', msgColor: 'red' });
				return;
			}

			const data = { resetToken: this.props.match.params.token, newPwd: this.state.newPwd };
			const resp = await axios.post('/api/shop/reset_password2', data);
			if (resp.status == 200) {
				this.setState({ message: 'Your password has changed!', msgColor: 'green', newPwd: '', confirmPwd: '' });
				setTimeout(() => this.props.history.push('/'), 3000);
			}
			else
				this.setState({ message: 'Failed to reset password: ' + resp.statusText, msgColor: 'red' });
		}
		catch (ex) {
			this.setState({ message: 'Error: ' + ex.message, msgColor: 'red' });
		}
	}

}
