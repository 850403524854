import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import Breadcrumb from "../common/breadcrumb";
import { connect } from 'react-redux'

class Refund extends Component {

    render (){
        return (
          <div>
              {/*SEO Support*/}
              <Helmet>
                  <title>Patel Brothers | Refund Requests</title>
              </Helmet>
              {/*SEO Support End */}

              <Breadcrumb title={'Refund Requests'}/>

              <section className="section-b-space">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <h2 style={{padding: '20px 0'}}>Refund Request</h2>
                      <h3>Please request all refunds through your dashboard.<br/></h3>
                      <h3>
                        Please note we will try to issue all refunds and address all requests within 48 hours but please allow for a maximum of 7 days.
                      </h3>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )
    }
}

const mapStateToProps = (state) => ({
    store: state.storeData.selected_store
})

export default connect(
    mapStateToProps
)(Refund)
