import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import './index.scss';
import store from './store';

import HomePage from "./components/collection/home";
import ProductPage from "./components/products/product";
import Layout from './components/app'
import Cart from './components/cart'
import Checkout from './components/checkout'
import CustomCheckout from './components/checkout/custom-checkout'
import OrderSuccess from './components/checkout/success-page'
import CustomOrderSuccess from './components/checkout/custom-success-page'
import OrderProcessing from './components/checkout/order-processing'
import Login from './components/pages/login'
import Splash from './components/pages/splash'
import Register from './components/pages/register'
import ChangePassword from './components/pages/change-password'
import ChangePreferences from './components/pages/change-preferences'
import Contact from './components/pages/contact'
import Dashboard from './components/pages/dashboard'
import Faq from './components/pages/faq'
import OrderDetails from './components/orders/order-details'
import Orders from './components/orders/orders'
import Terms from './components/pages/policies/terms'
import Privacy from './components/pages/policies/privacy-policy'
import Refund from './components/pages/policies/refund-policy'
import RefundRequest from './components/pages/refund-request'
import PageNotFound from './components/pages/404'
import ResetPassword from './components/pages/reset-password'

class Root extends React.Component {
    render() {
        return(
        	<Provider store={store}>
    				<BrowserRouter basename={'/'} >
    					<ScrollContext>
                <Layout>
                  <Switch>
                    {/* TEST ONLY */}
                    <Route exact path={`${process.env.PUBLIC_URL}/splash`} component={Splash} />


                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomePage}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/categories/:department`} component={HomePage}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/categories/:department/:subdepartment`} component={HomePage}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/search/product/:product_name`} component={HomePage}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/reset/:token`} component={ResetPassword}/>

    								{/*Routes For Single Product*/}
                    <Route exact path={`${process.env.PUBLIC_URL}/product/:id`} component={ProductPage}/>

    								{/*Routes For custom Features*/}
    								<Route exact path={`${process.env.PUBLIC_URL}/cart`} component={Cart}/>
    								<Route exact path={`${process.env.PUBLIC_URL}/checkout`} component={Checkout}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/order/success`} component={OrderSuccess}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/order/:order_num/checkout`} component={CustomCheckout}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/order/completed`} component={CustomOrderSuccess}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/processing/:order_num`} component={OrderProcessing}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/register`} component={Register}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/account/dashboard`} component={Dashboard}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/account/orders`} component={Orders}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/account/order/:id`} component={OrderDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/account/profile/change-password`} component={ChangePassword}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/account/profile/change-preferences`} component={ChangePreferences}/>

    								{/*Routes For Extra Pages*/}
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={Faq}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/policies/terms-of-service`} component={Terms}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/policies/privacy-policy`} component={Privacy}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/policies/refund-policy`} component={Refund}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/refunds`} component={RefundRequest}/>
                    <Route path="*" component={PageNotFound} />
                  </Switch>
                </Layout>
					  </ScrollContext>
					</BrowserRouter>
  			</Provider>
    	);
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
