import React, {Component, useEffect, useState } from 'react';
import store from '../../store';
import { connect } from 'react-redux'
import {reserveTimeSlot, clearTimeSlot} from "../../actions";

const moment = require('moment');

function CountdownTimer(props) {
  const calculateTimeLeft = () => {

    const difference = moment().diff(props.reservedSlot.selected_at, 'seconds');
    let timeLeft = {};

    if (difference < 1800) { //1800 seconds = 30 minutes
      timeLeft = {
        minutes: 29 - (Math.floor((difference / 60) % 60)),
        seconds: 59 - (Math.floor((difference) % 60))
      };
    }


    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  if (timeLeft.minutes > 0) {
    Object.keys(timeLeft).forEach(interval => {
      if (!timeLeft[interval]) {
        return;
      }

      timerComponents.push(
        <span key={interval}>
          {timeLeft[interval]} {interval}{" "}
        </span>
      );
    });
  }

  if (timerComponents.length <=  0) {
    store.dispatch(clearTimeSlot(props.reservedSlot.id));
  }

  return (
    <div>
    </div>
  );
}

class SlotCountdownTimer extends Component {
  _isMounted = false;

  constructor() {
    super();

    this.state = {
      didRefresh: false
    }
  }

  render() {
    if (!this.props.reservedSlot) {
      return (
        <div>
        </div>
      );
    }

    return (
      <div>
        <CountdownTimer reservedSlot={this.props.reservedSlot} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    reservedSlot: state.userData.slot,
    user: state.userData.user,
})

export default connect(
    mapStateToProps, {reserveTimeSlot, clearTimeSlot}
)(SlotCountdownTimer)
