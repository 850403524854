import React, {Component} from 'react';
import HeaderOne from './common/headers/error-header';
import FooterOne from "./common/footers/footer-one";

var axios = require('axios');


class ErrorCatch extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    axios.post("/api/shop/log_error", {error: error, info: info})
    .then(({data}) => {
    })
    .catch(e => {
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <HeaderOne logoName={'logo.png'}/>
            <section className="p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="error-section">
                                <h1 style={{fontSize: '60px'}}>Something Went Wrong...</h1>
                                <h2>We're working on it!</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          <FooterOne logoName={'logo.png'}/>
        </div>
      )
    }
    return this.props.children;
  }
}

export default ErrorCatch;
