import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import { connect } from 'react-redux'
import {Redirect} from 'react-router-dom';
import * as EmailValidator from 'email-validator';

const axios = require('axios');

class Contact extends Component {

    constructor (props) {
        super (props)

        this.state = {
          name: this.props.user ? this.props.user.name : '',
          email: this.props.user ? this.props.user.email : '',
          message: '',
          warning: null,
          successText: null
        }
    }

    onChange = (e) => {
      var obj = {};
      obj[e.target.name] = e.target.value;
      this.setState(obj);
    }


    sendMessage = () => {
      //eslint-disable-next-line
      if (this.state.name == "")  {
        this.setState({warning: "* Please fill out your full name"})
        return;
      }
      //eslint-disable-next-line
      if (this.state.email == "")  {
        this.setState({warning: "* Please fill out your email"})
        return;
      }
      //eslint-disable-next-line
      if (this.state.message == "")  {
        this.setState({warning: "* Please enter a message to send"})
        return;
      }

      if (EmailValidator.validate(this.state.email)) {
        var storeEmail = "shop@patelbros.com";
        if (this.props.user && this.props.store.email) {
           if (this.props.store.email != '') {
             storeEmail = this.props.store.email
           }
        }

        axios.post("/api/shop/contact", {store_email: storeEmail, name: this.state.name, email: this.state.email, message: this.state.message})
        .then(({data}) => {
          this.setState({successText: "Thank you for contacting us. We endeavor to reply to all of our customers within a 24 to 48 hour time period.  Rest assured we will review your correspondence and get back to you as soon as practicable. Thank you for reaching out to us. We appreciate your patience and support, and hope you continue to shop with us."})
        })
        .catch(error => {
          this.setState({warning: `* Unable to send your message at this time.  Please contact ${storeEmail} if you continue to have problems. We appreciate your patience and support.`})
        })

      } else {
        this.setState({warning: "* Please enter a valid email address"})
      }
    }

    render (){
      if (!this.props.store.id) {
        return (
          <Redirect to={'/'} />
        )
      }

        return (
            <div>
                <Breadcrumb title={'Contact Us'}/>


                {/*Forget Password section*/}
                <section className=" contact-page section-b-space">
                    <div className="container">
                        {this.props.store && this.props.user ?
                          (
                            <div className="row section-b-space">
                              <div className="col-lg-12 map" style={{minHeight: '300px'}}>
                                <iframe
                                  title="map"
                                  frameBorder="0"
                                  scrolling="no"
                                  marginHeight="0"
                                  marginWidth="0"
                                  src={`https://google.com/maps/embed/v1/place?key=AIzaSyDcAOXawolOlbOZov_Pt2qp3yEMg7ptVkQ&q=${this.props.store.lat},${this.props.store.lng}`}
                                 >
                                 </iframe>
                              </div>
                              <div className="col-lg-12" style={{marginTop: '20px'}}>
                              <center>

                                    <div className="media-body" style={{display: 'inline-block', margin: '0 20px'}}>
                                        <p style={{fontSize: '24px'}}>{this.props.store.address}</p>
                                    </div>

                                    <div className="media-body" style={{display: 'inline-block', margin: '0 20px'}}>
                                        <p style={{fontSize: '24px'}}>{this.props.store.email}</p>
                                    </div>

                                    <div className="media-body" style={{display: 'inline-block', margin: '0 20px'}}>
                                        <p style={{fontSize: '24px'}}>{this.props.store.phone_number}</p>
                                    </div>
                                  </center>
                              </div>
                            </div>
                          )
                          :
                          <h2 style={{paddingBottom: '40px'}}>Contact Patel Brothers</h2>
                        }


                        <div className="row">
                            <div className="col-sm-12">
                                {this.state.successText ?
                                  <h3 style={{textAlign: 'center'}}>{this.state.successText}</h3>
                                  :
                                  (
                                    <div className="theme-form">
                                        <div className="form-row">
                                          {this.props.user ?
                                            ''
                                            :
                                            (
                                              <div className="col-md-12">
                                                  <label htmlFor="name" style={{fontSize: '20px'}}>Full Name:</label>
                                                  <input className="form-control" placeholder="Your Full Name" name="name" required="true"  onChange={this.onChange} />
                                                  <label htmlFor="email" style={{fontSize: '20px'}}>Email Address:</label>
                                                  <input className="form-control" placeholder="Your Email Address" name="email" required="true" onChange={this.onChange} />
                                              </div>
                                            )
                                          }

                                            <div className="col-md-12">
                                                <label htmlFor="message" style={{fontSize: '20px'}}>How Can We Help (Questions / Issues / Feedback)?</label>
                                                <textarea className="form-control" placeholder="How can we help ?" name="message" rows="6" required="true" onChange={this.onChange}></textarea>
                                            </div>
                                            <div className="col-md-12">
                                              {this.state.warning ?
                                                <p style={{color: 'red'}}>{this.state.warning}</p> : ''
                                              }
                                                <button className="btn btn-solid" onClick={this.sendMessage}>Send Your Message</button>
                                            </div>
                                        </div>
                                    </div>
                                  )
                                }
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    store: state.storeData.selected_store,
    user: state.userData.user,
})

export default connect(
    mapStateToProps
)(Contact);
