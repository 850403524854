import React, {Component} from 'react';
import Slider from 'react-slick';
import '../common/index.scss';
import {connect} from "react-redux";
import { Redirect } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import SessionCheck from "../common/SessionCheck";
import DetailsWithPrice from "./common/product/details-price";
import { addToCart } from '../../actions'
import ImageZoom from './common/product/image-zoom'
import SmallImages from './common/product/small-image'

class ProductPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });

    }


    render(){
        const {symbol, item, addToCart} = this.props

        let products = {
            fade: true
        };

        let productsnav = {
            slidesToShow: 3,
            slidesToScroll:1,
            swipeToSlide:true,
            draggable:true,
            focusOnSelect: true
        };

        if (!item) {
          return <Redirect to={'/'} />
        }

        return (
            <div>
              <SessionCheck />
                <Breadcrumb product={true} title={item.name} />

                {/*Section Start*/}
                <section className='section-b-space'>
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">
                                <div aria-label="Product Images Container" className="col-lg-6 product-thumbnail">
                                    <Slider {...products} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)} className="product-slick">
                                        {item.images.map((vari, index) =>
                                            <div key={index}>
                                                <ImageZoom image={vari} className="img-fluid image_zoom_cls-0"/>
                                            </div>
                                        )}
                                    </Slider>
                                    <SmallImages item={item} settings={productsnav} navOne={this.state.nav1} />
                                </div>
                                <DetailsWithPrice symbol={symbol} item={item} navOne={this.state.nav1} addToCartClicked={addToCart} cart_obj={this.props.cart} cart={this.props.cartItems} store={this.props.store} />
                            </div>
                        </div>
                    </div>
                </section>
                {/*Section End*/}


                {/* <RelatedProduct /> */}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let productId = ownProps.match.params.id;
    return {
        // eslint-disable-next-line
        item: state.data.products.find(el => el.store_products.findIndex(s => s.id == productId) !== -1),
        symbol: state.data.symbol,
        store: state.storeData.selected_store,
        cartItems: state.cartList.cart,
        cart: state.cartList.cartObj
    }
}

export default connect(mapStateToProps, {addToCart}) (ProductPage);
