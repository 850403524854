import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import {getCartTotal} from "../../services";

class OrderProcessing extends Component {
	    constructor(props) {
		  super (props)

		  let queryString = window.location.search;

		  let urlParams = {
				  'HostedPaymentStatus': this.getParameterByName('HostedPaymentStatus'),
				  'TransactionID': this.getParameterByName('TransactionID'),
				  'LastFour': this.getParameterByName('LastFour'),
				  'ApprovedAmount': this.getParameterByName('ApprovedAmount'),
				  'OrderNum': this.props.match.params.order_num,
				}

		  console.log(queryString)

		  var evt = document.createEvent('CustomEvent');
		  evt.initCustomEvent('processorder', false, false, { urlParams: urlParams });
		  window.parent.dispatchEvent(evt);
		}

	    getParameterByName = (name) => {
		  name = name.replace(/[\[\]]/g, '\\$&');
		  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
				  results = regex.exec(window.location.href);
		  if (!results) return null;
		  if (!results[2]) return '';
		  return decodeURIComponent(results[2].replace(/\+/g, ' '));
		}

	    render() {
			return (
					  <div style={{backgroundColor: 'white'}}>
						<center>
						  <ReactLoading color="#27ae60"/>
						  <h3 style={{textAlign: 'center', paddingTop: '20px'}}>Processing Order..</h3>
						</center>
					  </div>
					);
		}
}

const mapStateToProps = (state) => ({
	    cartItems: state.cartList.cart,
	    cart: state.cartList.cartObj,
	    user: state.userData.user,
	    products: state.data.products,
	    reservedSlot: state.userData.slot,
	    store: state.storeData.selected_store,
	    symbol: state.data.symbol,
	    total: getCartTotal(state.cartList.cart)
})

export default connect(
	    mapStateToProps
)(withRouter(OrderProcessing))
