import {
    RECEIVE_STORES,
    FETCH_SINGLE_STORE,
    CLEAR_STORES,
  GET_SINGLE_STORE} from "../constants/ActionTypes";

const moment = require('moment');

const initialState = {
    stores: [],
    selected_store: {},
    change_store: false,
};

const storeReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_STORES:
            return { ...state,
                stores: action.stores };
        case GET_SINGLE_STORE:
          return {
            ...state,
            selected_store: action.store,
            change_store: false,
          }
        case FETCH_SINGLE_STORE:
            if (state.stores.findIndex(store => store.id === action.storeId) !== -1) {
              //eslint-disable-next-line
                const singleItem = state.stores.filter(store => store.id == action.storeId).reduce((itemAcc, store) => {
                  let pickup_slots = action.pickup_slots.sort((a, b) => {
                    return moment(a.pickup_time.split(' - ')[0], "h:mm a").isBefore(moment(b.pickup_time.split(' - ')[0], 'h:mm a')) ? -1 : 1
                  })
                    store.pickup_slots = pickup_slots;
                    return store
                }, [])

                return { ...state,
                    selected_store: singleItem,
                    change_store: false,
                   };
            }

            return {};

        case CLEAR_STORES:
          return {
             ...state,
            selected_store: {},
            change_store: true
          };

        default:
            return state;
    }
};
export default storeReducer;
