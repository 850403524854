import React, {Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'
import store from '../../store';
import { getOrLoginUser, selectAStore, receiveUser, receiveCart, getAllStores, reserveTimeSlot, selectAStoreAsGuest } from '../../actions'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading';
import StoreListing from '../collection/common/store-listing';
import { withRouter } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import {
  isMobile
} from "react-device-detect";

class Splash extends Component {

    constructor (props) {
        super (props)

        this.state = {
          email: '',
          password: '',
          zipcode: '',
          warning: '',
          loadingPreferredStore: false,
          showLogin: false,
          enteredZip: false,
          selectedStore: { value: 45, label: "East Windsor", address: "72 Princeton-Hightstown Road East Windsor, NJ 08520" },
          loadingSelectedStore: false,
          openShopifyModal: false,
          agreeShopifyTerms: false,
          shopifyWarning: false,
          comingSoonModal: false
        };

    }

    onCloseModal = () => {
      this.setState({openShopifyModal: false, comingSoonModal: false});
    }

    handleInputChange = e => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    };

    handleSubmit = e => {
      e.preventDefault();

      const { email, password } = this.state;

      axios.post('/api/shop/login', {email: email.toLowerCase(), password: password, mobile: isMobile})
        .then(({data}) => {
          if (data.error) {
            this.setState({warning: "Couldn't log in. Please try again."})

            return;
          }

          store.dispatch(getAllStores());

          if (data.cart) {
            if (data.user.preferred_store) {
              this.setState({loadingPreferredStore: true})

              store.dispatch(selectAStore(data.user.preferred_store, data.cart.id, true, function() {
                this.setState({loadingPreferredStore: false})
                store.dispatch(receiveUser(data.user));
  	            if (this.props.user) {
                  this.setState({loggedIn: true});
                }
              }.bind(this)));
            } else {
              store.dispatch(receiveUser(data.user));

    	        if (this.props.user) {
          		    this.setState({loggedIn: true});
          		}
    	      }

            store.dispatch(receiveCart(data.cart, data.line_items ? data.line_items : []));

            axios.post('/api/shop/refresh_slot', {cart_id: data.cart.id})
            .then(({data}) => {
              store.dispatch(reserveTimeSlot(data, true));
            }).catch(e => {
              store.dispatch(reserveTimeSlot(null))
            })

          } else {
            store.dispatch(receiveUser(data.user));

            if (this.props.user) {
              this.setState({loggedIn: true});
            }
          }

        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.isNew) {
            this.setState({warning: "Our site is brand new and will require you to sign up again, your old Shopify login won't work. We apologize for the inconvenience."})

          } else {
            this.setState({warning: "* Couldn't log in. Please check your email and password and try again."})
          }
        })
    };

    backToSplash = () => {
      this.setState({showLogin: false, enteredZip: false, zipcode: ''})
    }

    selectOption = (option) => {
      if (!this.state.selectedStore || this.state.loadingSelectedStore) {
        return;
      }

      if (option == 1) { //East Windsor store id
        this.setState({loadingSelectedStore: true});
        store.dispatch(selectAStoreAsGuest(45, null, true));

      } else if (option == 2) { //North Brunswick
        this.setState({loadingSelectedStore: true});
        store.dispatch(selectAStoreAsGuest(49, null, true));

      } else if (option == 3) { //Jersey City
        this.setState({loadingSelectedStore: true});
        store.dispatch(selectAStoreAsGuest(48, null, true));
      }
    }

    render () {
      if (this.state.loadingPreferredStore) {
        return (
          <div style={{padding: '20px 0'}}>
            <center>
              <ReactLoading type={'spin'} color={'#129e51'} height={300} width={300} />
            </center>
          </div>
        )
      }

      if (this.state.enteredZip && this.state.zipcode.length >= 5) {
        return (
          <section className={`splash-page section-b-space`}>
            <div className="splash-login-btn">
              <Link className="btn btn-solid" to={`${process.env.PUBLIC_URL}/login`}>
                Log in
              </Link>
            </div>

            <StoreListing zipcode={this.state.zipcode} backToSplash={this.backToSplash} />
          </section>
        );
      }

        return (
            <div>
                {/*Login section*/}
                <section className={`splash-page section-b-space`} style={{backgroundImage: `${isMobile ? 'url(https://swad.nyc3.digitaloceanspaces.com/mobile-splash-bg.wbp)' : 'url(https://swad.nyc3.digitaloceanspaces.com/splash-bg.wbp)'}`}}>

                  <div className="splash-login-btn">
                    <p className="btn btn-solid" to={`${process.env.PUBLIC_URL}/login`} onClick={() => this.setState({showLogin: !this.state.showLogin})}>
                      Log in
                    </p>
                  </div>

                  <div className="container">
                      <div className="row">
                          {!this.state.showLogin ?
                            <div className="col-md-12 splash-col">
                              <div className="card splash-card">
                                <div className="card-body splash-card-body">
                                  <img src={`https://swad.nyc3.cdn.digitaloceanspaces.com/patelbros/logo.png`} width="318px" height="150px" className="img-fluid" alt="Patel Brothers Logo"/>
                                  {/*<form>
                                    <input name="zipcode" type="text" className="form-control" id="zipcode" placeholder="Please Enter Your 5 Digit Zip Code"
                                           required onChange={this.handleInputChange} />
                                    <button className="btn btn-success" type="submit" onClick={() => {this.setState({enteredZip: true})}}>Continue</button>
                                  </form>*/}
                                  <p style={{textAlign: 'center', textTranform: 'none', fontWeight: 'bold'}}>Please Select Your Nearest Patel Brothers</p>

                                  <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                      <button className={`btn btn-success ${this.state.loadingSelectedStore ? 'disabled' : ''}`} type="submit" onClick={() => this.selectOption(1)}>East Windsor</button>
                                      <p style={{textAlign: 'center', textTranform: 'none', fontSize: '12px', paddingTop: '5px'}}>
                                        72 Princeton-Hightstown Road East Windsor, NJ 08520
                                      </p>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                      <button className={`btn btn-success ${this.state.loadingSelectedStore ? 'disabled' : ''}`} type="submit" onClick={() => this.selectOption(2)}>North Brunswick</button>
                                      <p style={{textAlign: 'center', textTranform: 'none', fontSize: '12px', paddingTop: '5px'}}>
                                        2800 State Route 27 North Brunswick, NJ 08902
                                      </p>
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                      <button className={`btn btn-success ${this.state.loadingSelectedStore ? 'disabled' : ''}`} type="submit" onClick={() => this.selectOption(3)}>Jersey City</button>
                                      <p style={{textAlign: 'center', textTranform: 'none', fontSize: '12px', paddingTop: '5px'}}>
                                        780-782 Newark Avenue Jersey City, NJ 07306
                                      </p>
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                    </div>
                                  </div>

                                  <div className="account-note-holder">
                                    <p className="account-note">
                                      Already have an account?
                                      <span onClick={() => this.setState({showLogin: !this.state.showLogin})}>
                                        {" "}Log in
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            :
                            <div className="col-md-12 splash-col">
                              <div className="card login-splash-card">
                                <div className="card-body splash-card-body">
                                  <h3 style={{padding: '20px 0'}}> <i style={{marginRight: '8px', cursor: 'pointer'}} onClick={() => this.setState({showLogin: !this.state.showLogin})} className="fa fa-angle-left"></i> Login</h3>

                                  <form onSubmit={this.handleSubmit} className="theme-form">
                                      <div className="form-group">
                                          <label htmlFor="email">Email</label>
                                          <input name="email" type="text" className="form-control" id="email" placeholder="Email"
                                                 required onChange={this.handleInputChange} />
                                      </div>
                                      <div className="form-group">
                                          <label htmlFor="review">Password</label>
                                          <input name="password" type="password" className="form-control" id="review"
                                                 placeholder="Enter your password" required onChange={this.handleInputChange} />
                                      </div>
                                      <p className="text-danger">{this.state.warning}</p>
                                      {this.state.warning == "Our site is brand new and will require you to sign up again, your old Shopify login won't work. We apologize for the inconvenience." ?
                                      <button className="btn pull-right" style={{backgroundColor: 'white', color: "#129e51"}}
                                        onClick={() => window.location.href = `${process.env.PUBLIC_URL}/register`}>
                                          Create A New Account
                                        </button>
                                        :
                                        null
                                      }
                                      <button className="btn btn-success" type="submit">Login</button>
                                      <button className="btn pull-right" style={{backgroundColor: 'white', color: "#129e51"}}
                                        onClick={() => window.location.href = `${process.env.PUBLIC_URL}/reset/ask-email-${this.state.email.toLowerCase()}`}>
                                        Forgot Password
                                      </button>
                                  </form>

                                </div>
                              </div>
                            </div>
                          }
                      </div>
                  </div>
                  <h3 className="tag-line">"Celebrating Our Food...Our Culture"</h3>
                </section>

                <Modal open={this.state.openShopifyModal} onClose={this.onCloseModal} center>
                  <div className="modal-header">
                    <h2 style={{fontSize: '30px'}}>Patel Brothers Jersey City</h2>
                  </div>
                  <div className="modal-body store-modal-body">
                    <h3>
                      Curbside Pickup for Patel Brothers Jersey City will be available soon. Please check back tomorrow.
                    </h3>
                    <center>
                      <button className="modal-btn btn btn-danger" onClick={this.onCloseModal}>Close</button>
                    </center>
                  </div>
                </Modal>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.userData.user,
})

export default withRouter(connect(
    mapStateToProps, {getOrLoginUser, getAllStores, reserveTimeSlot, selectAStoreAsGuest}
)(Splash))
