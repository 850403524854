import { observable, action, computed } from 'mobx';
var numeral = require('numeral');

class ProductFilters {
	department: string;
	categories: string[];
	name: string;
	sortBy: string;
}

class ProductListingModel {
	@observable currentProductPage = 0;
	@observable products = [];
	@observable filters = new ProductFilters();
	perPage = 24;

	@action setProducts(data: any[]) {
		// product list -> detail -> Continue shoping -> Home page refresh
		// we want to keep the user on the last current page using the length as a cheap solution
		if (this.products && data && this.products.length != data.length) {
			this.currentProductPage = 0;
			this.filters = { categories: [], sortBy: '', name: '', department: '' };
		}
		this.products = data;
	}

	@action setCurrentPage(pageNum: number) {
		this.currentProductPage = pageNum;
	}

	@action setFilterCategory(category: string[]) {
		// setting category clears name search
		this.filters.categories = category;
		this.filters.name = '';
		this.filters.department = '';
		this.currentProductPage = 0;
	}

	@action setFilterCategoryAndDepartment(category: string[], department: string) {
		// setting category clears name search
		this.filters.categories = category;
		this.filters.department = department;
		this.filters.name = '';
		this.currentProductPage = 0;
	}

	@action setFilterName(name: string) {
		// name search resets category to all
		this.filters.categories = ['All'];
		this.filters.department = '';
		this.filters.name = name;
		this.currentProductPage = 0;
	}

	@action setFilterSortBy(sortBy: string) {
		this.filters.sortBy = sortBy;
		this.currentProductPage = 0;
	}

	@action showSale() {
		this.filters.categories = ['Sale'];
		this.filters.department = '';
	}

	@computed get actualFilterCategories() {
		let filterCategories = this.filters.categories;
		const allChecked = filterCategories && filterCategories.length == 1 && filterCategories[0] == 'All';
		if (allChecked)
			filterCategories = [];

		return filterCategories;
	}

	@computed get filteredAndSortedProducts() {
		return this.products.filter(product => {
			let categoryMatch = true;
			const filterCategories = this.actualFilterCategories;
			if (filterCategories.length > 0) {
				categoryMatch = filterCategories.some(c => {
					if (c == 'Sale') {
						if (product.sale_price != null) {
							return true;
						}

						return product.store_products.filter(sp => sp.sale_price != null).length > 0
					}

					if (this.filters.department.length > 0) {
						if (product.department) {
							return product.category === c && product.department.name == this.filters.department
						} else {
							return false
						}
					}
					return product.category === c
				});
			}

			let nameOrTagMatch = false;
			if (this.filters.name.length > 0) {
				const lowerName = this.filters.name.toLowerCase();
				if (product.name.toLowerCase().includes(lowerName))
					nameOrTagMatch = true;
				else if (product.tags.find((t) => t.toLowerCase().includes(lowerName)))
					nameOrTagMatch = true;
				else if (product.store_products && product.store_products.findIndex(sp => sp.variant && sp.variant.toLowerCase().includes(lowerName)) !== -1 )
					nameOrTagMatch = true;
			}
			else
				nameOrTagMatch = true;

			return categoryMatch && nameOrTagMatch;
		})
		.sort((product1, product2) => {
			const sortBy = this.filters.sortBy;
			if (sortBy === 'HighToLow') {
				return numeral(product2.price).value() < numeral(product1.price).value() ? -1 : 1;
			}
			else if (sortBy === 'LowToHigh') {
				return numeral(product2.price).value() > numeral(product1.price).value() ? -1 : 1;
			}
			else if (sortBy === 'Newest') {
				return product2.id < product1.id ? -1 : 1;
			}
			else if (sortBy === 'AscOrder') {
				return product1.name.localeCompare(product2.name);
			}
			else if (sortBy === 'DescOrder') {
				return product2.name.localeCompare(product1.name);
			}
			else {
				if (product1.name.toLowerCase().includes('swad') && !product2.name.toLowerCase().includes('swad')) {
					return -1
				} else if (!product1.name.toLowerCase().includes('swad') && product2.name.toLowerCase().includes('swad')) {
					return 1
				}	else {
					return 0
				}
			}
		});
	}

	@computed get categories() {
		let uniqueCategories = [];
		this.products.map((product, index) => {
			if (!uniqueCategories.find((v, i, a) => v.category == product.category)) {
				uniqueCategories.push(product);
			}
			return product;
		})

		const sorted = uniqueCategories.sort((a, b) => {
			if (a.category.toLowerCase() > b.category.toLowerCase())
				return 1;
			if (a.category.toLowerCase() < b.category.toLowerCase())
				return -1;

			return 0;
		});
		return sorted;
	}

	@computed get departments() {
		let uniqueDepartments = [];
		let produceDepartment = [];
		this.products.map((product, index) => {
			if (product.department) {
				if (!uniqueDepartments.find((v, i, a) => v.department.name == product.department.name)) {
					uniqueDepartments.push(product);
				}
			}

			return product;
		})

		const sorted = uniqueDepartments.sort((a, b) => {
			if (a.department.name == 'produce' && b.department.name != "produce")
				return -1
			if (a.department.name != 'produce' && b.department.name == 'produce')
				return 1
			if (a.department.name.toLowerCase() > b.department.name.toLowerCase())
				return 1;
			if (a.department.name.toLowerCase() < b.department.name.toLowerCase())
				return -1;

			return 0;
		});
		return sorted;
	}

	@computed get departmentSlugs() {
		let slugs = {}
		this.departments.map((product, index) => {
			if (product.department) {
				if (product.department.name) {
					slugs[`${product.department.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,'')}`] = product.department.name;
				}
			}

			return product;
		});

		return slugs
	}

	@action subdepartments(department: string) {
		let uniqueCategories = [];

		this.products.map((product, index) => {
			if (product.department) {
				if (product.department.name == department) {
					if (!uniqueCategories.find((v, i, a) => v.category == product.category)) {
						uniqueCategories.push(product);
					}
				}
			}
			return product;
		})

		const sorted = uniqueCategories.sort((a, b) => {
			if (a.category.toLowerCase() > b.category.toLowerCase())
				return 1;
			if (a.category.toLowerCase() < b.category.toLowerCase())
				return -1;

			return 0;
		});
		return sorted;
	}


		@action subdepartmentSlugs(department: string) {
			let slugs = []
			this.products.map((product, index) => {
				if (product.department) {
					if (product.department.name == department) {
						slugs[`${product.category.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,'')}`] = product.category
					}
				}
				return product;
			})

			return slugs
		}

}

var pdListModel1 = new ProductListingModel();

export default pdListModel1;
