import React, {Component} from 'react';
import Modal from 'react-responsive-modal';

const axios = require('axios');

class StoreListItem extends Component {

    constructor(props){
        super(props)

        this.state = {
            open: false,
        }
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    selectNewStore = () => {
      this.setState({open: false});

      if (this.props.reservedSlot) {
        axios.post('/api/shop/expire_slot', {slot_id: this.props.reservedSlot.id})
          .then(() => {
            this.props.clearTimeSlot();
          })
          .catch(error => {

          });
      }

      this.props.selectStore(this.props.store.id, this.props.cart_id, true)
    }

    render() {
        const {store, selectStore, cart_id} = this.props;
        const {open} = this.state;

        if (!store) {
          return (<div></div>)
        }

        return (
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="store-detail">
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                      <h3>{store.name}</h3>
                      <h4>{store.address}</h4>
                      {store.distance ?
                        <p>Approx. {store.distance.toFixed(1)} Miles Away</p>
                        : null
                      }
                      {store.remainingSlots ? <p>{store.remainingSlots} Slots Available</p> : ''}

                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-4 col-4' style={{textAlignLast: 'right', alignSelf: 'center'}}>
                      {store.is_live ?
                        (
                          <button aria-labelledby="Select store modal open" className='btn select-store-btn' onClick={this.onOpenModal}>
                            Select Store
                          </button>
                        )
                        :
                        (
                          <button aria-labelledby="Disabled pickup" aria-disabled={true} className='btn select-store-btn disabled' disabled>
                            Curbside Pickup Unavailable
                          </button>
                        )
                      }
                    </div>
                  </div>
              </div>

              <Modal open={open} onClose={this.onCloseModal} center>
                <div className="modal-header">
                  <h2 style={{fontSize: '30px'}}>{store.name}</h2>
                </div>
                <div className="modal-body store-modal-body">
                  <h3 style={{fontSize: '20px'}}>
                    Address: {store.address}
                    <br/><br/>
                    Are you sure you want to select this store?
                    <br/><br/>
                    If you change your store, items in your cart are subject to change per the availability and price of the new store selected.
                    <br/><br/>
                    We cannot guarantee any item availability or price, and cannot guarantee time slot availability when switching.
                    <br/><br/>
                    There is a $9.99 restocking fee that will
                    be automatically charged if you fail to show up during your designated pickup time.
                  </h3>
                  {/*  SHOULD WE ALSO SHOW THE PICKUP SCHEDULE AND # OF SLOTS REMAINING? */}
                  <center>
                    <button aria-labelledby="Clear Time slot" className="modal-btn btn btn-success" onClick={() => {
                      this.props.clearTimeSlot();
                      selectStore(store.id, cart_id || 0, true);
                    }}>Continue</button>
                    <button aria-labelledby="Close pickup modal" className="modal-btn btn btn-danger" onClick={this.onCloseModal}>Cancel</button>
                  </center>
                </div>
              </Modal>

          </div>
        )
    }
}

export default StoreListItem;
