import React, {Component} from 'react';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import Breadcrumb from "../common/breadcrumb";
import {reserveTimeSlot, completeCheckout, getOrLoginUser, clearStores} from "../../actions";
import {
  parseMoney as currency, multiplyByQty, subtractCost, addCost, moneyFormat, getTaxAmount
} from '../helpers';

const randomize = require('randomatic');
const XMLParser = require('react-xml-parser');

const axios = require('axios');

class checkOut extends Component {

    constructor (props) {
        super (props)

        this.state = {
            hostedToken: null,
            save_card: false,

            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            country:'United States',
            address: '',
            city: '',
            state: '',
            zipcode: '',

            warning: '',

            hasProduce: false,
            hasTaxable: false,

            subtotal: 0.0,
            tax: 0.0,
            total: 0.0,

            openMobileForm: false,

            order: null,
            store: {},
            billingDiv: 'none',
            authnet: true
        }

    }

    componentDidMount() {
      if (this.props.match.params.order_num) {

        var orderID = this.props.match.params.order_num;
        axios.post('/api/shop/get_order', {order_num: orderID})
          .then(({data}) => {

            const order = data.order;

            var subtotal = 0.0;
            var tax = 0.0;
            var preAuth = 0.0;
            var total = currency(order.total)

            for (let li of order.products) {
              let product = li.product;
              subtotal = addCost(subtotal, multiplyByQty(li.price, li.qty))

              if (li.tax_amount) {
                tax = addCost(tax, li.tax_amount)
              }

              if (product.category.toLowerCase() === "produce") {
                preAuth = 20.0
              }
            }

            total = addCost(total, preAuth)

            this.setState({
              order: order,
              store: order.store,

              first_name: order.user ? order.user.first_name : '',
              last_name:order.user ? order.user.last_name : '',
              email: order.user ? order.user.email : '',
              country:'United States',

              hasProduce: preAuth > 0,
              hasTaxable: tax > 0,

              subtotal: subtotal,
              tax: tax,
              total: total,

              authnet: false,
              billingDiv: 'block'
            });

            if (this.state.authnet) {
              this.getPaymentPage(orderID);
            }
          })
          .catch(error => {

          });


        window.addEventListener('processorder', function (e) {
          this.completeCheckout(e.detail.urlParams);
        }.bind(this), false);

      }
    }

    getWorldpayPaymentPage = (orderID) => {
      this.setState({billingDiv: 'none'});
      let checkoutTotal = currency(this.state.total);

      axios.post('/api/shop/checkout-hosted-page', {orderID: orderID, storeId: this.state.store.id, transactionAmount: checkoutTotal.toFixed(2), address: this.state.address, city: this.state.city, state: this.state.state, zip_code: this.state.zipcode})
      .then(({data}) => {
        var xmlDoc = new XMLParser().parseFromString(data);

        let transactionSetupID = xmlDoc.getElementsByTagName("TransactionSetupID")[0].value;
        let hostedURL = `https://transaction.hostedpayments.com/?transactionSetupID=${transactionSetupID}`;

        this.setState({hostedURL: hostedURL, hostedToken: hostedURL});
        $('#add_payment').attr('src', hostedURL)

        $("#add_payment").css('opacity', 1);
      })

    }

    completeCheckout = (urlParams) => {
      if (urlParams['HostedPaymentStatus'] == 'Complete') {
        var transactionId = urlParams['TransactionID'];
        var last_four = urlParams['LastFour'];
        var authorized = true;
        var checkoutTotal = urlParams['ApprovedAmount'];

        var orderID = urlParams['OrderNum'];

        if (urlParams["CVVResponseCode"] == "N") {
          //No Match, reverse transaction and try again.
        }

        var total = currency(checkoutTotal);
        var original_auth_amount = total;

        var subtotal = currency(this.state.subtotal)
        var tax = currency(this.state.tax)
        var totalWithoutPreAuth = addCost(subtotal, tax)

        let full_billing_address = `${this.state.address} ${this.state.city}, ${this.state.state} ${this.state.zipcode}`

        // complete checkout payment
        axios.post('/api/shop/checkout_email', {
          order_num: orderID, last_four: last_four, original_auth_amount: original_auth_amount, totalTax: tax, transaction_id: transactionId, authorized: true,
          line_items: this.state.order.products, subtotal: subtotal, total: totalWithoutPreAuth, user_id: this.state.order.user.id, store_id: this.state.store.id, hasProduce: this.state.hasProduce
        })
        .then(({data}) => {
          this.props.history.push({
              pathname: `/order/completed`,
                  state: { user: data.user, hasProduce: this.state.hasProduce, hasTaxable: tax > 0, order: this.state.order, items: this.state.order.products,
                    products: this.state.order.products.map(li => li.product), orderTotal: original_auth_amount, subtotal: this.state.subtotal, tax: this.state.tax, symbol: '$' }
          })
        })
        .catch(error => {
          axios.post("/api/shop/log_error", {error: error, info: `${this.state.order.user.id} - ERROR ON CHECKOUT`}).then(() => {}).catch(e => {})

          this.setState({warning: "* Unable to submit payment. Please try again at another time."});
        })
      } else {
        this.props.history.push({
          pathname: '/'
        });
      }
    }


    openMobileForm = () => {
      this.setState({openMobileForm: !this.state.openMobileForm})
    }

    handleInputChange = e => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    };

    render () {
      if (!this.props.match.params.order_num) {
        return <div>
            <Breadcrumb title={''}/>

            <section className="p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="error-section">
                                <h2>We Are Unable to Process Your Request At This Time.</h2>
                                <a aria-labelledby="Back to home" href="/" className="btn btn-solid">back to home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
      }

      if (!this.state.order) {
        return <div>
            <Breadcrumb title={''}/>

            <section className="p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="error-section">
                              <center>
                                <ReactLoading color="#27ae60"/>
                                <h3 style={{textAlign: 'center', paddingTop: '20px'}}>Loading Checkout..</h3>
                              </center>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
      }

        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>Patel Brothers | Checkout</title>
                </Helmet>
                {/*SEO Support End */}

                <Breadcrumb  title={'Checkout'}/>

                <Tabs>
                    <TabList className="nav nav-tabs tab-coupon" >
                      <Tab className="nav-link" style={{cursor: 'pointer'}}>Curbside Pickup</Tab>
                    </TabList>

                    <TabPanel>

                      <section >
                      <div className="container checkout-container">
                        <div className="row checkout-row">
                        <div className={`col-lg-12 col-md-12 col-sm-12 checkout-mobile-order-summary checkout-item-list  ${this.state.openMobileForm ? 'active' : ''}`}>
                          <div
                            role="button"
                            aria-labelledby="Order Summary"
                            aria-label="Order Summary"
                            className="mobile-form-label btn btn-success"
                            onClick={() => this.openMobileForm()}
                          >
                            Order Summary <i className={`fa ${this.state.openMobileForm ? 'fa-angle-up' : 'fa-angle-down'}`} aria-hidden="true"></i>
                          </div>
                          <div className="final-total" style={{marginBottom: '10px'}}>
                              <h3>total <span>${this.state.total}</span></h3>
                          </div>


                                                    <div className="product-order" style={{paddingBottom: '20px'}}>
                                                    <div className="row product-order-detail">
                                                              <div className="col-3">

                                                              </div>
                                                              <div className="col-3 order_detail">
                                                                  <div>
                                                                      <h4>Product</h4>
                                                                  </div>
                                                              </div>
                                                              <div className="col-3 order_detail text-right">
                                                                  <div style={{width: '100%'}}>
                                                                      <h4>quantity</h4>
                                                                  </div>
                                                              </div>
                                                              <div className="col-3 order_detail text-right">
                                                                  <div style={{width: '100%'}}>
                                                                      <h4>price</h4>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                    {this.state.order.products.map((item, index) => {
                                                      let product = item.product;

                                                      var perLb = product.tags.findIndex(t => t && t.toLowerCase() === 'per lb') !== -1;
                                                      return (
                                                        <div className="row product-order-detail" key={index}>
                                                                  <div className="col-3">
                                                                  <img src={product.images[0]} alt={`Checkout ${product.name}-${index}`} className="img-fluid" style={{objectFit: 'contain'}} onError={(e) => {
                                                                    e.target.src = 'https://swad.nyc3.cdn.digitaloceanspaces.com/image-placeholder.jpg'
                                                                  }} />
                                                                  </div>
                                                                  <div className="col-3 order_detail">
                                                                      <div>
                                                                          <h5>
                                                                            {product.name} <span style={{fontSize: '12px', textTransform: 'lowercase', }}>
                                                                            {moneyFormat(item.tax_amount) > 0.0 ? '+ tax ' : ' '}</span>
                                                                          </h5>
                                                                          <h5>{perLb ? '' : item.variant}</h5>
                                                                      </div>
                                                                  </div>
                                                                  <div className="col-3 order_detail text-right">
                                                                      <div style={{width: '100%'}}>
                                                                          <h5>
                                                                          {item.qty}
                                                                          {product.tags.map(t => {
                                                                              if (t === 'per lb') {
                                                                                //eslint-disable-next-line
                                                                                return ` Lb${item.qty != 1 ? 's' : ''}`
                                                                              } else {
                                                                                return ''
                                                                              }
                                                                            })
                                                                          } x {moneyFormat(item.price)} {perLb ? '/ lb' : null}
                                                                          </h5>
                                                                      </div>
                                                                  </div>
                                                                  <div className="col-3 order_detail text-right">
                                                                      <div style={{width: '100%'}}>
                                                                          <h5>
                                                                            {moneyFormat( multiplyByQty(item.price, item.qty) )}
                                                                          </h5>
                                                                      </div>
                                                                  </div>
                                                              </div>)
                                                    })}
                                                    </div>

                                                                              <div className="total-sec">
                                                                                  <ul>
                                                                                  <li>subtotal <span>{moneyFormat(this.state.subtotal)}</span></li>
                                                                                  <li>Tax <span>{moneyFormat(this.state.tax)}</span></li>
                                                                                      {this.state.hasProduce ?
                                                                                        (
                                                                                          <li>Pre-Authorization for Produce <span>$20.00</span></li>
                                                                                        )
                                                                                        : ''
                                                                                      }
                                                                                  </ul>
                                                                              </div>
                                                                              <div className="final-total">
                                                                              <h3>total <span>{moneyFormat(this.state.total)}</span></h3>
                                                                              </div>
                                                                              {this.state.warning !== '' ?
                                                                                <p className="pickup-warning text-danger">{this.state.warning}</p>
                                                                                :
                                                                                ''
                                                                              }
                        </div>

                        <div className={`col-lg-7 col-md-12 col-sm-12 col-12 authnet-col`}>

                        <h3 className="checkout-label">
                          Payment
                          <i className="fa fa-cc-visa" />
                          <i className="fa fa-cc-mastercard" />
                          <i className="fa fa-cc-amex" />
                          <i className="fa fa-cc-discover" />
                          <br/>
                          <span>All transactions are secure and encrypted.</span>

                        </h3>


                            <div className="checkout-label" aria-labelledby="Your Billing Address" style={{display: `${this.state.billingDiv}`}}>
                              <label for="address" style={{width: '100%'}}>Billing Address:
                              <br/>
                              <input id="address" type="text" name="address" defaultValue={this.state.address} onChange={this.handleInputChange} required />
                              </label>
                              <label for="city" style={{width: '45%', marginRight: '5%'}}>City:
                              <input id="city" type="text" name="city" defaultValue={this.state.city} onChange={this.handleInputChange} required />
                              </label>
                              <label for="state" style={{width: '45%'}}>State:
                              <input id="state" type="text" name="state" defaultValue={this.state.state} onChange={this.handleInputChange} required />
                              </label>
                              <label for="zipcode" style={{width: '100%'}}>Zip Code:
                              <br/>
                              <input id="zipcode" type="text" name="zipcode" defaultValue={this.state.zipcode} onChange={this.handleInputChange} required />
                              </label>
                              <center>
                                <div
                                  className="btn btn-success"
                                  onClick={() => this.getWorldpayPaymentPage(this.state.order.order_num)}
                                  role="button"
                                  aria-labelledby="Submit Billing and Go To Payment Details"
                                >
                                  Next
                                </div>
                              </center>
                            </div>


                          <div id="iframe_holder" className="center-block" >
                            <iframe title="payment_holder" id="add_payment" className="embed-responsive-item panel" name="add_payment" width="100%" height="100%" frameBorder="0">
                            </iframe>
                          </div>

                        </div>

                        <div className="col-lg-5 col-md-12 col-sm-12 col-12 checkout-item-list checkout-desktop-order-summary">


                          <div className="product-order" style={{paddingBottom: '20px'}}>
                          <div className="row product-order-detail">
                                    <div className="col-3">

                                    </div>
                                    <div className="col-3 order_detail">
                                        <div>
                                            <h4>Product</h4>
                                        </div>
                                    </div>
                                    <div className="col-3 order_detail text-right">
                                        <div style={{width: '100%'}}>
                                            <h4>quantity</h4>
                                        </div>
                                    </div>
                                    <div className="col-3 order_detail text-right">
                                        <div style={{width: '100%'}}>
                                            <h4>price</h4>
                                        </div>
                                    </div>
                                </div>
                          {this.state.order.products.map((item, index) => {
                            let product = item.product;

                            var perLb = product.tags.findIndex(t => t && t.toLowerCase() === 'per lb') !== -1;
                            return (
                              <div className="row product-order-detail" key={index}>
                                        <div className="col-3">
                                        <img src={product.images[0]} alt={`Checkout ${product.name}-${index}`} className="img-fluid" style={{objectFit: 'contain'}} onError={(e) => {
                                          e.target.src = 'https://swad.nyc3.cdn.digitaloceanspaces.com/image-placeholder.jpg'
                                        }} />
                                        </div>
                                        <div className="col-3 order_detail">
                                            <div>
                                                <h5>
                                                  {product.name} <span style={{fontSize: '12px', textTransform: 'lowercase', }}>
                                                  {moneyFormat(item.tax_amount) > 0.0 ? '+ tax ' : ' '}</span>
                                                </h5>
                                                <h5>{perLb ? '' : item.variant}</h5>
                                            </div>
                                        </div>
                                        <div className="col-3 order_detail text-right">
                                            <div style={{width: '100%'}}>
                                                <h5>
                                                {item.qty}
                                                {product.tags.map(t => {
                                                    if (t === 'per lb') {
                                                      //eslint-disable-next-line
                                                      return ` Lb${item.qty != 1 ? 's' : ''}`
                                                    } else {
                                                      return ''
                                                    }
                                                  })
                                                } x {moneyFormat(item.price)} {perLb ? '/ lb' : null}
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="col-3 order_detail text-right">
                                            <div style={{width: '100%'}}>
                                                <h5>
                                                  {moneyFormat( multiplyByQty(item.price, item.qty) )}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>)
                          })}
                          </div>
                              <div style={{position: 'absolute', bottom: '18px', width: '100%'}}>
                                                    <div className="total-sec">
                                                        <ul>
                                                            <li>subtotal <span>{moneyFormat(this.state.subtotal)}</span></li>
                                                            <li>Tax <span>{moneyFormat(this.state.tax)}</span></li>
                                                            {this.state.hasProduce ?
                                                              (
                                                                <li>Pre-Authorization for Produce <span>$20.00</span></li>
                                                              )
                                                              : ''
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="final-total" aria-labelledby="Total amount">
                                                        <h3>total <span>{moneyFormat(this.state.total)}</span></h3>
                                                    </div>
                                                        {this.state.warning !== '' ?
                                                          <p className="pickup-warning text-danger">{this.state.warning}</p>
                                                          :
                                                          ''
                                                        }
                                          </div>

                                     </div>

                        </div>
                        </div>
                      </section>

                    </TabPanel>

                  </Tabs>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(
    mapStateToProps, {reserveTimeSlot, completeCheckout, getOrLoginUser, clearStores}
)(checkOut)
