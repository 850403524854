import React from 'react';
import { observer } from 'mobx-react';
import pdListModel1 from '../../../models/ProductListingModel';
import  { Redirect } from 'react-router-dom'
import {
  isMobile
} from "react-device-detect";

type SearchState = {
	search: string
}

@observer
export default class SearchFilter extends React.Component<{}, SearchState> {

	componentWillMount() {
		this.setState({
			search: isMobile ? (pdListModel1.filters.name || null) : null
		});
	}

	onChange = (e) => {
		if (!isMobile) {
			pdListModel1.setFilterName(e.target.value);
		}

		this.setState({ search : e.target.value })
	}

	onEnterPress = (e) => {
		if(e.keyCode == 13){
			if (window.location.pathname != '/' || isMobile) {
				let name = e.target.value;
				pdListModel1.setFilterName(name);
				window.location.href = `${process.env.PUBLIC_URL}/search/product/${encodeURI(name)}`;
			}
		}
	}

	clickSearch = () => {
		if (this.state.search) {
			if (window.location.pathname != '/' || isMobile) {
				let name = this.state.search;
				pdListModel1.setFilterName(name);
				window.location.href = `${process.env.PUBLIC_URL}/search/product/${encodeURI(name)}`;
			}
		}
	}

	render() {
		const model = pdListModel1;
		return (
			<div className="search-filter">
				<div className="search-content d-flex flex-row">

					<input aria-label="Search Input" type="text" className="form-control product_name" name="product_name"
						placeholder="Search For A Product" value={isMobile ? this.state.search : (model.filters.name || '')} onChange={(e) => this.onChange(e)}
						onKeyDown={(e) => this.onEnterPress(e)}
						 />
					<i role="button" aria-label="Search" className="fa fa-search border-0 px-2" style={{cursor: 'pointer'}} onClick={() => this.clickSearch()}></i>
				</div>
			</div>
		)
	}
}
