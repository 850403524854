import * as types from '../constants/ActionTypes'


const filtersReducerDefaultState = {
    category: [],
    sortBy: "",
    name: ""
};

const filtersReducer = (state = filtersReducerDefaultState, action) => {
    switch (action.type) {
        case types.FILTER_CATEGORY:
            return {
                ...state,
                category: action.category
            };
        case types.FILTER_NAME:
            return {
                ...state,
                name: action.name
            };
        case types.SORT_BY:
            return {
                ...state,
                sortBy: action.sort_by
            };
        default:
            return state;
    }
}

export default filtersReducer;
