import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux'
import { getAllStores } from '../../../services';
import storeDispatch from '../../../store';
import { selectAStore, selectAStoreAsGuest, clearTimeSlot, receiveStores, logoutUser } from '../../../actions'
import StoreListItem from "./store-list-item";
import Modal from 'react-responsive-modal';


const zipcodes = require('zipcodes');
const axios = require('axios');

class StoreListing extends Component {
	constructor(props) {
		super(props)

		let zipcode = null;
		if (this.props.zipcode) {
			zipcode = this.props.zipcode;
		} else {
			zipcode = this.props.user ? (this.props.user.zip_code ? this.props.user.zip_code : null) : null
		}

		this.state = {
			offset: 0, limit: 20, hasMoreItems: true, perPage: 20,
			enteredZip: zipcode,
			loadingText: '',
			loadingStore: '',
			stores: [],
			loadingPage: true,
			zipcode: this.props.zipcode || '',
			loadingSelectedStore: false,
			openShopifyModal: false,
			agreeShopifyTerms: false,
			shopifyWarning: false
		};
	}

	componentDidMount() {
		let enteredZip = null;
		let storesByDistance = [];

		axios.get('/api/shop/all_stores')
			.then(({ data }) => {
				storeDispatch.dispatch(receiveStores(data));

				if (this.props.zipcode) {
					enteredZip = this.props.zipcode;
					let zipByRadius = zipcodes.radius(enteredZip, 10); //10 mile radius of the users zipcode

					let findStores = this.props.stores.filter(s => zipByRadius.findIndex(z => z.toString() === s.zip_code.toString()) !== -1)
					for (let store of findStores) {
						let dist = zipcodes.distance(enteredZip, store.zip_code);
						store.distance = dist;
						let distIndex = storesByDistance.findIndex(s => s.distance > dist);

						if (distIndex === -1) {
							storesByDistance.push(store)
						} else {
							storesByDistance.splice(distIndex, 0, store);
						}
					}

					if (storesByDistance.length === 0) {
						for (let store of this.props.stores) {
							if (store.zip_code) {
								let dist = zipcodes.distance(enteredZip, store.zip_code);
								store.distance = dist;
								let distIndex = storesByDistance.findIndex(s => s.distance > dist);

								if (distIndex === -1) {
									storesByDistance.push(store)
								} else {
									storesByDistance.splice(distIndex, 0, store);
								}
							}
						}
					}


				} else {
					if (this.props.user) {
						if (this.props.user.zip_code) {
							enteredZip = this.props.user.zip_code;
							let zipByRadius = zipcodes.radius(enteredZip, 10); //10 mile radius of the users zipcode

							let findStores = this.props.stores.filter(s => zipByRadius.findIndex(z => z.toString() === s.zip_code.toString()) !== -1)
							for (let store of findStores) {
								let dist = zipcodes.distance(enteredZip, store.zip_code);
								store.distance = dist;
								let distIndex = storesByDistance.findIndex(s => s.distance > dist);

								if (distIndex === -1) {
									storesByDistance.push(store)
								} else {
									storesByDistance.splice(distIndex, 0, store);
								}
							}

							if (storesByDistance.length === 0) {
								for (let store of this.props.stores) {
									if (store.zip_code) {
										let dist = zipcodes.distance(enteredZip, store.zip_code);
										store.distance = dist;
										let distIndex = storesByDistance.findIndex(s => s.distance > dist);

										if (distIndex === -1) {
											storesByDistance.push(store)
										} else {
											storesByDistance.splice(distIndex, 0, store);
										}
									}
								}
							}
						}
					}

				}

				this.setState({ stores: storesByDistance });

				this.setState({ loadingPage: false });

			})
			.catch(error => {

				this.setState({ loadingPage: false });
			})
	}


	    onCloseModal = () => {
	      this.setState({openShopifyModal: false});
	    }

	handlePageClick = data => {
		let selected = data.selected;
		this.setState({
			offset: Math.ceil(selected * this.state.perPage),
			limit: Math.ceil((selected + 1) * this.state.perPage),

		});
		window.scrollTo(0, 0)
	};

	searchZipCodes = (e) => {
		if (e.target.value.length == 0) {
			this.setState({enteredZip: null, loadingText: 'Please enter your 5 digit zip code.', stores: []})
		}

		if (e.target.value.length === 5) {
			this.setState({ loadingText: `Searching for stores in ${e.target.value}...` });

			let storesByDistance = []
			let zipByRadius = zipcodes.radius(e.target.value, 10); //10 mile radius of the users zipcode

			let	enteredZip = e.target.value.substring(0, 5);

			let findStores = this.props.stores.filter(s => zipByRadius.findIndex(z => z.toString() === s.zip_code.toString()) !== -1)
			for (let store of findStores) {
				let dist = zipcodes.distance(enteredZip, store.zip_code);
				store.distance = dist;
				let distIndex = storesByDistance.findIndex(s => s.distance > dist);

				if (distIndex === -1) {
					storesByDistance.push(store)
				} else {
					storesByDistance.splice(distIndex, 0, store);
				}
			}

			if (storesByDistance.length === 0) {
				for (let store of this.props.stores) {
					if (store.zip_code) {
						let dist = zipcodes.distance(e.target.value, store.zip_code);
						store.distance = dist;
						let distIndex = storesByDistance.findIndex(s => s.distance > dist);

						if (distIndex === -1) {
							storesByDistance.push(store)
						} else {
							storesByDistance.splice(distIndex, 0, store);
						}
					}
				}
			}


			if (storesByDistance.length > 0) {
				this.setState({ loadingText: '', enteredZip: e.target.value, stores: storesByDistance });

				axios.post('/api/shop/update_zip_code', { zip_code: e.target.value })
					.then(() => {}).catch(error => {console.log(error)});

			} else {
				this.setState({ stores: [],
					loadingText: "We couldn't find any stores near this zip code that offer curbside pickup. Your request will be saved, and we'll let you know when a store near you begins curbside pickup!" })

			}
		 }
		// else {
		// 	this.setState({ loadingText: 'Please enter your 5 digit zip code', enteredZip: false, stores: [] });
		// }
	}


	    selectOption = (option) => {
	      // if (!this.state.selectedStore || this.state.loadingSelectedStore) {
	      //   return;
	      // }

	      if (option == 1) { //East Windsor store id
	        this.setState({loadingSelectedStore: true});
					if (this.props.user) {
						storeDispatch.dispatch(selectAStore(45, null, true));
					} else {
						storeDispatch.dispatch(selectAStoreAsGuest(45, null, true));
					}
	      } else {
	        this.setState({openShopifyModal: true});
	      }
	    }

	render() {
		if (this.state.loadingPage) {
			return (
				<div style={{ padding: '20px 0' }}>
					<center>
						<ReactLoading type={'spin'} color={'#129e51'} height={300} width={300} />
					</center>
				</div>
			)
		}

		const { selectAStore, selectAStoreAsGuest, cart } = this.props;

		const stores = this.state.stores.filter(s => s.is_live);

		return (
			<div className='container py-3 store-listings' style={{minHeight: '70vh'}}>
				<div className="store-listings-row row">
					<div className="store-listings-col col-md-12">
						<div className="store-listing-card">
							{/*<h4 className='py-1 text-center font-weight-bold text-black'> {this.props.backToSplash ? <i style={{marginRight: '8px', cursor: 'pointer'}} onClick={this.props.backToSplash} className="fa fa-angle-left"></i> : null} Please enter your 5 digit zip code to search for the nearest Patel Brother location near you</h4>*/}
							<h4 className='py-1 text-center font-weight-bold text-black'> {this.props.backToSplash ? <i role="button" tabIndex={0} aria-labelledby="Close Store" style={{marginRight: '8px', cursor: 'pointer'}} onClick={this.props.backToSplash} className="fa fa-angle-left"></i> : null} Please Select Your Nearest Patel Brothers</h4>

							<div className="product-wrapper-grid">
								<div className="container-fluid">
									<div>
										<input defaultValue={`${this.state.enteredZip ? this.state.enteredZip : ''}`}
											className="form-control mx-auto" type="text"
											placeholder="Please enter your 5 digit zip code" style={{ marginTop: '10px', marginBottom: '10px', width: '300px' }}
											onChange={(e) => this.searchZipCodes(e)} />

											{/*<div className="row" style={{textAlign: 'center'}}>
												<div className="col-md-12 col-sm-12">
													<button style={{width: '300px'}} className={`btn btn-success ${this.state.loadingSelectedStore ? 'disabled' : ''}`} onClick={() => this.selectOption(1)}>East Windsor</button>
													<p style={{textAlign: 'center', textTranform: 'none', fontSize: '12px', paddingTop: '5px'}}>
														72 Princeton-Hightstown Road<br/>East Windsor, NJ 08520
													</p>
												</div>
												<div className="col-md-12 col-sm-12">
													<button style={{width: '300px'}} className={`btn btn-success ${this.state.loadingSelectedStore ? 'disabled' : ''}`} onClick={() => this.selectOption(2)}>North Brunswick</button>
													<p style={{textAlign: 'center', textTranform: 'none', fontSize: '12px', paddingTop: '5px'}}>
														2800 State Route 27<br/>North Brunswick, NJ 08902
													</p>
												</div>
											</div>*/}

									</div>
									{!this.state.enteredZip ?
										<div>{this.state.loadingText}</div>
										:
										<div>
											{stores.length > 0 ?
												<div className="product-list-component">
													<div className="row" style={{ marginBottom: '50px' }}>
														{stores.slice(this.state.offset, this.state.limit).map((s, index) =>
															<StoreListItem store={s} cart_id={cart.id} selectStore={this.props.user ? selectAStore : selectAStoreAsGuest} key={index}
															clearTimeSlot={() => storeDispatch.dispatch(clearTimeSlot(this.props.reservedSlot ? this.props.reservedSlot.id : null))} />)
														}
													</div>

													<ReactPaginate
														previousLabel={'previous'}
														nextLabel={'next'}
														breakLabel={'...'}
														breakClassName={'break-me'}
														pageCount={Math.ceil(stores.length / this.state.perPage)}
														marginPagesDisplayed={2}
														pageRangeDisplayed={5}
														onPageChange={this.handlePageClick}
														containerClassName={'pagination justify-content-end'}
														pageClassName={'page-item'}
														pageLinkClassName={'page-link'}
														nextLinkClassName={'page-link'}
														previousLinkClassName={'page-link'}
														disabledClassName={'page-item disabled'}
														activeClassName={'page-item active'}
													/>
												</div>
												:
												<div className="row">
													<div className="col-sm-12 text-center section-b-space mt-5 no-found" >
														<img alt="No Stores Found" src={`https://swad.nyc3.cdn.digitaloceanspaces.com/patelbros/icons/empty-search.jpg`}
															className="img-fluid mb-4" />
														<h3>No Stores are Available at this time.</h3>
													</div>
												</div>
											}
										</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>

				<Modal open={this.state.openShopifyModal} onClose={this.onCloseModal} center>
					<div className="modal-header">
						<h2 style={{fontSize: '30px'}}>Patel Brothers North Brunswick</h2>
					</div>
					<div className="modal-body store-modal-body">
						<ol>
							<li style={{ display: "list-item"}}>
								You are now being re-directed to an independently run online storefront that is run by Patel Brothers North Brunswick
							</li>
							<li style={{ display: "list-item"}}>
								You agree to abide by the terms and conditions set forth at:
								<ol>
									<li style={{ display: "list-item"}}>
										<a rel="noopener noreferrer" href={`https://patel-brothers-north-brunswick.myshopify.com/pages/rules-and-how-it-works`} target="_blank">Rules & How It Works</a>
									</li>
									<li style={{ display: "list-item"}}>
										<a rel="noopener noreferrer"  href={`https://patel-brothers-north-brunswick.myshopify.com/policies/terms-of-service`} target="_blank">Terms of Service</a>
									</li>
									<li style={{ display: "list-item"}}>
										<a rel="noopener noreferrer"  href={`https://patel-brothers-north-brunswick.myshopify.com/policies/privacy-policy`} target="_blank">Privacy Policy</a>
									</li>
								</ol>
							</li>
						</ol>
						<center>
							<input type="checkbox" id="agree-shopify-terms" name="agree-shopify-terms" onChange={(e) => {
								this.setState({agreeShopifyTerms: e.target.checked, shopifyWarning: false});
							}} />
							<label for="agree-shopify-terms" style={{marginLeft: '10px'}}> I agree to these conditions.</label>
							{this.state.shopifyWarning ? <p style={{color: 'red'}}>* You must check this box to continue to the store.</p> : null}
							<br/>
							<button aria-labelledby="Agree terms and service" className="modal-btn btn btn-success" onClick={() => {
								if (this.state.agreeShopifyTerms) {
									window.open('https://patel-brothers-north-brunswick.myshopify.com', "_blank")
								} else {
									this.setState({shopifyWarning: true});
								}
							}}>Accept</button>
							<button aria-labelledby="Close Terms modal" className="modal-btn btn btn-danger" onClick={this.onCloseModal}>Cancel</button>
						</center>
					</div>
				</Modal>

			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	stores: getAllStores(state.storeData),
	cart: state.cartList.cartObj,
	user: state.userData.user,
	reservedSlot: state.userData.slot,
})

export default connect(
	mapStateToProps, { selectAStore, selectAStoreAsGuest, clearTimeSlot, receiveStores, logoutUser }
)(StoreListing)
