import React from 'react';
import { observer } from 'mobx-react';
import pdListModel1 from '../../../models/ProductListingModel';
import { Link } from 'react-router-dom';

@observer
export default class Filter extends React.Component<{}, {}> {
	closeFilter = () => {
		(document.querySelector(".collection-filter") as HTMLElement).setAttribute('style', "left: -100%");
		(document.querySelector(".close-overlay-collection-filter") as HTMLElement).setAttribute('style', "left: -100%");
	}

	onCatChecked(event) {
		if (event.target.value == 'All') {
			pdListModel1.setFilterCategory(['All']);
		}
		else {
			let department = pdListModel1.filters.department;
			let categories = pdListModel1.filters.categories.slice();
			var index = categories.indexOf(event.target.value);
			if (event.target.checked)
				categories.push(event.target.value); // push in array checked value
			else
				categories.splice(index, 1); // removed in array unchecked value

			pdListModel1.setFilterCategory(categories.slice());
		}
	}

	onCatClicked(catName: string, department: string) {
		pdListModel1.setFilterCategoryAndDepartment([catName], department);

		if (window.innerWidth <= 991) {
			(document.querySelector(".collection-filter") as HTMLElement).setAttribute('style', "left: -100%");
			(document.querySelector(".close-overlay-collection-filter") as HTMLElement).setAttribute('style', "left: -100%");
		}
		window.scrollTo(0, 0);
	}

	onSeeAllClicked(subs: any, department: string) {
		var catNames = subs.map(s => s.category);
		pdListModel1.setFilterCategoryAndDepartment(catNames, department);
		if (window.innerWidth <= 991) {
			(document.querySelector(".collection-filter") as HTMLElement).setAttribute('style', "left: -100%");
			(document.querySelector(".close-overlay-collection-filter") as HTMLElement).setAttribute('style', "left: -100%");
		}
		window.scrollTo(0, 0);
	}

	toSlug(name: string) {
			return name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,'')
	}

	render() {
		const categories = pdListModel1.departments;
		const department = pdListModel1.filters.department;
		const filterCategories = pdListModel1.actualFilterCategories;;
		const allChecked = filterCategories == null || filterCategories.length == 0;

		var selectedSubs = pdListModel1.subdepartments(department) || [];

		var findCategories = filterCategories == null ? [] : filterCategories;
		return (
			<div className="collection-filter-block">
				{/*brand filter start*/}
				<div className="collection-mobile-back">
					<span aria-label="Close Filter" role="button" tabIndex={0} className="filter-back" onClick={(e) => this.closeFilter()} >
						<i className="fa fa-angle-left" aria-hidden="true"></i> back
                    </span>
				</div>
				<div className="collection-collapse-block">
					<h3 className="collapse-block-title" style={{paddingBottom: '10px'}}>Current Category</h3>
					<div className="collection-collapse-block-content">
						<div className="collection-brand-filter">

							{department && department != '' ?
								<div style={{marginBottom: '20px'}} >
									<div className={`category-department-list-filter active`}>
											<Link aria-label={`Show ${department} products`} className={`active`} to={`${process.env.PUBLIC_URL}/categories/${this.toSlug(department)}`} onClick={() => {this.onSeeAllClicked(selectedSubs, department)}}>
											{department}
										</Link>
										<Link aria-label="Show all products" style={{color: '#777777', marginLeft: '8px' }} to={`${process.env.PUBLIC_URL}/categories/all`} onClick={() => { this.onCatClicked('All', '') }}>
										 [X]
										</Link>
									</div>
									{selectedSubs.map((s, i) => {
										return (
											<div className={`category-subcategory-list-filter ${findCategories.findIndex(c => c == s.category) !== -1 ? `active` : ''}`} key={i}>
												<Link aria-label={`Show ${department} products`} className={`${findCategories.findIndex(c => c == s.category) !== -1 ? `active` : ''}`} to={`${process.env.PUBLIC_URL}/categories/${this.toSlug(department)}/${this.toSlug(s.category)}`} onClick={() => {this.onCatClicked(s.category, department)}}>
													{s.category}
												</Link>
											</div>
										)
									})}
								</div>
								: null
							}

							<div className={`category-department-list-filter ${department == '' ? 'active' : ''}`}>
								<Link aria-label="Show All products" className={`${department == '' ? 'active' : ''}`} to={`${process.env.PUBLIC_URL}/categories/all`} onClick={() => { this.onCatClicked('All', '') }}>
									All Products
								</Link>
							</div>

							{categories.map((pd, index) => {
								var subs = pdListModel1.subdepartments(pd.department.name);
								return (
									<div key={index} style={{paddingTop: '18px'}}>
										<div style={{float: 'right'}}>
											<p tabIndex={0} role="button" aria-label={`Select subcategory ${pd.department.name}`} id={`show-subs-${index}`} style={{textAlign: 'right', cursor: 'pointer'}} onClick={(e) => {
												var subList = document.getElementById(`subs-${index}`);
											  if (subList.style.display === "none") {
													e.currentTarget.innerHTML = '-';
											    subList.style.display = "block";
											  } else {
													e.currentTarget.innerHTML = '+';
											    subList.style.display = "none";
											  }
											 }}>
												+
											</p>
										</div>
										<div role="button" aria-label={`Show all ${pd.department.name} products`} className={`category-department-list-filter ${department == pd.department.name ? 'active' : ''}`} onClick={() => this.onSeeAllClicked(subs, pd.department.name)}>
											<Link aria-label={`Select ${pd.department.name}`} className={`${department == pd.department.name ? 'active' : ''}`} to={`${process.env.PUBLIC_URL}/categories/${this.toSlug(pd.department.name)}`} onClick={() => {
												var subList = document.getElementById(`subs-${index}`);
												if (subList.style.display === "none") {
													 document.getElementById(`show-subs-${index}`).innerHTML = '-';
													subList.style.display = "block";
												} else {
													 document.getElementById(`show-subs-${index}`).innerHTML = '+';
													subList.style.display = "none";
												}
												this.onSeeAllClicked(subs, pd.department.name)
											}}>
												{pd.department.name}
											</Link>
										</div>
										<div id={`subs-${index}`} style={{display: 'none'}}>
											{subs.map((s, i) => {
												return (
													<div className={`category-subcategory-list-filter ${findCategories.findIndex(c => c == s.category && department == pd.department.name) !== -1 ? `active` : ''}`} key={i}>
														<Link aria-label={`Show ${pd.department.name} products`} className={`${findCategories.findIndex(c => c == s.category && department == pd.department.name) !== -1 ? `active` : ''}`} to={`${process.env.PUBLIC_URL}/categories/${this.toSlug(pd.department.name)}/${this.toSlug(s.category)}`} onClick={() => {this.onCatClicked(s.category, pd.department.name)}}>
															{s.category}
														</Link>
													</div>
												)
											})}
										</div>

									</div>
								)
							})}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
