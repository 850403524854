import React, {Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'
import store from '../../store';
import { getOrLoginUser, selectAStore, receiveUser, receiveCart, getAllStores, reserveTimeSlot } from '../../actions'
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux'
import ReactLoading from 'react-loading';
import {
  isMobile
} from "react-device-detect";

import Breadcrumb from "../common/breadcrumb";
import { withRouter } from 'react-router-dom';

class Login extends Component {

    constructor (props) {
        super (props)

        // localStorage.removeItem("state");
        this.state = {
          email: '',
          password: '',
          warning: '',
          loadingPreferredStore: false,
        };
    }

    handleInputChange = e => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    };

    handleSubmit = e => {
      e.preventDefault();

      const { email, password } = this.state;

      let loginData = {email: email.toLowerCase(), password: password, mobile: isMobile};
      if (this.props.cart.length > 0) {
        loginData.itemsInCart = this.props.cart;
      }

      axios.post('/api/shop/login', loginData)
        .then(({data}) => {
          if (data.error) {
            this.setState({warning: "Couldn't log in. Please try again."})

            return;
          }

          store.dispatch(getAllStores());

          if (data.cart) {
            if (data.user.preferred_store) {
              this.setState({loadingPreferredStore: true})

              store.dispatch(selectAStore(data.user.preferred_store, data.cart.id, true, function() {
                this.setState({loadingPreferredStore: false})
                store.dispatch(receiveUser(data.user));
		            if (this.props.user) {
                  this.setState({loggedIn: true});
                }
              }.bind(this)));
            } else {
              store.dispatch(receiveUser(data.user));
              if (this.props.user) {
                this.setState({loggedIn: true});
              }
            }

            store.dispatch(receiveCart(data.cart, data.line_items ? data.line_items : []));

            axios.post('/api/shop/refresh_slot', {cart_id: data.cart.id})
            .then(({data}) => {
              store.dispatch(reserveTimeSlot(data, true));
            }).catch(e => {
              store.dispatch(reserveTimeSlot(null))
            })

          } else {
            store.dispatch(receiveUser(data.user));

            if (this.props.user) {
              this.setState({loggedIn: true});
            }
          }

        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.isNew) {
            this.setState({warning: "Our site is brand new and will require you to sign up again, your old Shopify login won't work. We apologize for the inconvenience."})

          } else {
            this.setState({warning: "* Couldn't log in. Please check your email and password and try again."})
          }
        })
    };

    render (){
      // eslint-disable-next-line
      // if (this.props.location !== '/' && this.props.user != null) {
      //   return <Redirect to={'/account/dashboard'} />
      // }
      if (this.props.user) {
        return <Redirect to={'/'} />
      }

      if (this.state.loadingPreferredStore) {
        return (
          <div style={{padding: '20px 0'}}>
            <center>
              <ReactLoading type={'spin'} color={'#129e51'} height={300} width={300} />
            </center>
          </div>
        )
      }

        return (
            <div>
                <Breadcrumb title={'Login'}/>

                {/*Login section*/}
                <section className="login-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3>Login</h3>
                                <div className="theme-card">
                                    <form onSubmit={this.handleSubmit} className="theme-form">
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input name="email" type="text" className="form-control" id="email" placeholder="Email"
                                                   required onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="review">Password</label>
                                            <input name="password" type="password" className="form-control" id="review"
                                                   placeholder="Enter your password" required onChange={this.handleInputChange} />
                                        </div>
                                        <p className="text-danger">{this.state.warning}</p>
                                        <button aria-labelledby="Login" className="btn btn-solid" type="submit">Login</button>
										<button aria-labelledby="Forgot password" className="btn pull-right"
											onClick={() => this.props.history.push(`reset/ask-email-${this.state.email.toLowerCase()}`)}>
											Forgot Password
										</button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 right-login">
                                <h3>New Customer</h3>
                                <div className="theme-card authentication-right">
                                    <h6 className="title-font">Create An Account</h6>
                                    <p>
                                      You must sign up for a free account and select the store closest to your location for Curbside Pickup.
                                    </p>
                                    <Link to={`${process.env.PUBLIC_URL}/register`} className="btn btn-solid">Create an Account</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.userData.user,
    store: state.storeData.selected_store,
    cart: state.cartList.cart,
})

export default withRouter(connect(
    mapStateToProps, {getOrLoginUser, getAllStores, reserveTimeSlot}
)(Login))
