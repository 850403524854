import React, {Component} from 'react';

class QtyMeasure extends Component {
    constructor (props) {
        super (props)

        this.state = {
            open:false,
            nav3: null,
            totalLbs: '1 lb',
            hasPurchaseLimit: this.props.purchaseLimit > 0
        }

    }

    componentDidMount() {
        this.setState({
            nav3: this.slider3
        });
    }

    render (){
        return (
            <div>
                <h6 className="product-title">quantity</h6>
                <div className="qty-box">
                    <div className="input-group">
                        <span className="input-group-prepend">
                          <button aria-label="Decrease quantity by one" aria-labelledby="Decrease quantity" type="button" className={`btn quantity-left-minus ${this.state.hasPurchaseLimit ? (parseFloat(this.props.purchaseLimit) === parseFloat(this.props.quantity) ? 'disabled' : '') : ''}`} onClick={this.props.minusQty} data-type="minus" data-field="">
                           <i className="fa fa-angle-left"></i>
                          </button>
                        </span>

                        <input type="text" name="quantity" value={this.props.quantity ? this.props.quantity : '0'} onChange={this.props.changeQty}
                        className="form-control " disabled={this.state.hasPurchaseLimit ? (parseFloat(this.props.purchaseLimit) === parseFloat(this.props.quantity) ? true : false) : false} />

                        <span className="input-group-prepend">
                        <button aria-label="Increase quantity by one" aria-labelledby="Increase quantity" type="button" className={`btn quantity-right-plus ${this.state.hasPurchaseLimit ? (parseFloat(this.props.purchaseLimit) === parseFloat(this.props.quantity) ? 'disabled' : '') : ''}`} onClick={this.props.plusQty} data-type="plus" data-field="">
                        <i className="fa fa-angle-right"></i>
                        </button>
                       </span>
                       {this.props.tags.map((t, i) => {
                           if (t === 'per lb') {
                             return <span key={i} style={{marginLeft: '10px', alignSelf: 'center', fontSize: '18px'}}>
                              {/* eslint-disable-next-line */}
                              lb{this.props.quantity != 1 ? 's' : ''}
                             </span>
                           } else {
                             return ''
                           }
                         })
                       }

                    </div>
                </div>
            </div>
        )
    }
}


export default QtyMeasure;
