//GET user
export const RECEIVE_USER = 'RECEIVE_USER'
export const REGISTER_USER = 'REGISTER_USER'
export const FETCH_USER = 'FETCH_USER'
export const CLEAR_USER = 'CLEAR_USER'
export const UPDATE_PREFERENCES = 'UPDATE_PREFERENCES'

//Get stores
export const FETCH_STORES_BEGIN = 'FETCH_STORES_BEGIN'
export const RECEIVE_STORES = 'RECEIVE_STORES'
export const FETCH_SINGLE_STORE = 'FETCH_SINGLE_STORE'
export const CLEAR_STORES = 'CLEAR_STORES'
export const GET_SINGLE_STORE = 'GET_SINGLE_STORE'

// Get Products
export const FETCH_PRODUCTS_BEGIN = 'FETCH_PRODUCTS_BEGIN'
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const FETCH_SINGLE_PRODUCT = 'FETCH_SINGLE_PRODUCT'
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'

// FILTERS
export const FILTER_BRAND = 'FILTER_BRAND'
export const FILTER_COLOR = 'FILTER_COLOR'
export const FILTER_PRICE = 'FILTER_PRICE'
export const FILTER_CATEGORY = 'FILTER_CATEGORY'
export const FILTER_NAME = 'FILTER_NAME'
export const SORT_BY = 'SORT_BY'

export const CHANGE_CURRENCY = 'CHANGE_CURRENCY'


// Cart
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const INCREMENT_QTY = 'INCREMENT_QTY'
export const DECREMENT_QTY = 'DECREMENT_QTY'
export const CLEAR_CART = 'CLEAR_CART'
export const RECEIVE_CART= 'RECEIVE_CART'
export const UPDATE_QTY = "UPDATE_QTY"
export const RECEIVE_LINE_ITEM = "RECEIVE_LINE_ITEM"

// Time slots
export const RESERVE_SLOT = 'RESERVE_SLOT'


// WishList
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST'
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST'


// Compare
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE'
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE'



// CheckOut Process
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST'
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS'
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE'
