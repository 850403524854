import React, {Component} from 'react';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import { connect } from 'react-redux';
import {Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import Modal from 'react-responsive-modal';
import ReactLoading from 'react-loading';
import storeDispatch from '../../store';
import StoreListing from '../collection/common/store-listing.jsx';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import SessionCheck from '../common/SessionCheck';
import Breadcrumb from "../common/breadcrumb";
import {getCartTotal} from "../../services";
import {reserveTimeSlot, completeCheckout, getOrLoginUser, clearStores, receiveStores, refreshHomeStore, refreshGuestHomeStore, logoutUser} from "../../actions";
import {
  parseMoney, addCost, multiplyByQty, moneyFormat, getTaxAmount
} from '../helpers';

const moment = require('moment');
const axios = require('axios');
const randomize = require('randomatic');
const XMLParser = require('react-xml-parser');

class checkOut extends Component {
    constructor (props) {
        super (props)
        let cartItemsAsProducts = [];
        if (this.props.cartItems && this.props.products) {
          //eslint-disable-next-line
          cartItemsAsProducts = this.props.products.filter(p => p.store_products.find(sp => this.props.cartItems.find(el => el.sp_id == sp.id)));
        }

        this.state = {
            subtotal: 0.0,
            tax: 0.0,
            checkoutTotal: 0.0,
            authorizeNetTotal: 0.0,
            hostedToken: null,
            use_new_card: this.props.user ? (this.props.user.customer_payment_profile ? false : true) : true,
            save_card: false,
            cc: '',
            exp: '',
            sc: '',
            name_on_card: '',
            first_name: this.props.user ? this.props.user.first_name : '',
            last_name:this.props.user ? this.props.user.last_name : '',
            phone: this.props.user ? (this.props.user.phone_number ? this.props.user.phone_number : '') : '',
            email: this.props.user ? this.props.user.email : '',
            country:'United States',
            address: this.props.user ? (this.props.user.billing_address ? this.props.user.billing_address : '') : '',
            city: this.props.user ? (this.props.user.city ? this.props.user.city : '') : '',
            state: this.props.user ? (this.props.user.state ? this.props.user.state : '') : '',
            zipcode: this.props.user ? (this.props.user.zip_code ? this.props.user.zip_code : '') : '',
            date: new Date(),
            open: false,
            loadingPickupSlots: true,
            reselectStore: false,
            showDates: [],
            warning: '',
            activeSelected: -1, //the active date selected in the select date modal, if -1, that means no slots are available this week, try again next sunday
            hasProduce: cartItemsAsProducts.filter(p => p.category.toLowerCase() === "produce").length > 0,
            hasTaxable: cartItemsAsProducts.filter(p => p.taxable ).length > 0,
            cartItemsAsProducts: cartItemsAsProducts,
            openMobileForm: false,
            hostedURL: '',
            billingDiv: this.props.store ? 'block' : 'none'
        }

        this.validator = new SimpleReactValidator();

        if (this.props.reservedSlot) {
          axios.post('/api/shop/refresh_slot', {slot_id: this.props.reservedSlot.id})
          .then(({data}) => {
            storeDispatch.dispatch(reserveTimeSlot(data, true));
          }).catch(e => {
            storeDispatch.dispatch(reserveTimeSlot(null))
          })
        }
    }

    componentWillMount() {
        axios.post('/api/shop/session_refresh', { user_id: null })
          .then(({ data }) => {

            let orderErrorCheck = 0;
            if (this.props.cartItems) {
                orderErrorCheck = this.props.cartItems.length;
            }

            storeDispatch.dispatch(refreshHomeStore(data));

            if (this.props.cart) {
              axios.post('/api/shop/refresh_slot', {cart_id: this.props.cart.id})
              .then(({data}) => {
                storeDispatch.dispatch(reserveTimeSlot(data, true));
              }).catch(e => {
                storeDispatch.dispatch(reserveTimeSlot(null));
              })

                if (orderErrorCheck > 0 && this.props.cartItems.length == 0) {
                storeDispatch.dispatch(reserveTimeSlot(null));
                    this.props.history.push({pathname: '/account/orders'});
                }

              let cartItemsAsProducts = this.props.products.filter(p => p.store_products.find(sp => this.props.cartItems.find(el => el.sp_id == sp.id)));
              this.setState({
                first_name: this.props.user ? this.props.user.first_name : '',
                last_name:this.props.user ? this.props.user.last_name : '',
                phone: this.props.user ? (this.props.user.phone_number ? this.props.user.phone_number : '') : '',
                email: this.props.user ? this.props.user.email : '',
                country:'United States',
                address: this.props.user ? (this.props.user.billing_address ? this.props.user.billing_address : '') : '',
                city: this.props.user ? (this.props.user.city ? this.props.user.city : '') : '',
                state: this.props.user ? (this.props.user.state ? this.props.user.state : '') : '',
                zipcode: this.props.user ? (this.props.user.zip_code ? this.props.user.zip_code : '') : '',
                hasProduce: cartItemsAsProducts.filter(p => p.category.toLowerCase() === "produce").length > 0,
                hasTaxable: cartItemsAsProducts.filter(p => p.taxable ).length > 0,
                cartItemsAsProducts: cartItemsAsProducts,
              })
            } else {
                if (orderErrorCheck > 0) {
                    storeDispatch.dispatch(reserveTimeSlot(null));
                    this.props.history.push({pathname: '/account/orders'});
                }
              }
          })
          .catch(error => {
            if (this.props.selected_store) {
              storeDispatch.dispatch(refreshGuestHomeStore(this.props.selected_store));
            } else {
              storeDispatch.dispatch(refreshHomeStore());
            }

            if (error.response) {
              if (error.response.status === 401) {
                storeDispatch.dispatch(logoutUser());
              }
            }
          });
    }

    getWorldpayPaymentPage = () => {
      this.setState({billingDiv: 'none'});
      const orderID = `${this.props.store.order_prefix}${randomize('0', 5)}`;
      let subtotal = parseMoney(this.props.total);
      let checkoutTotal = subtotal;
      if (this.state.hasProduce) {
        checkoutTotal = addCost(checkoutTotal, 20); //add $20 pre-auth cost since this is a produce item
      }

      if (this.props.total < this.props.store.minimum_required && (this.props.store.shopping_fee && parseMoney(this.props.store.shopping_fee) > 0)) {
        checkoutTotal = addCost(checkoutTotal, this.props.store.shopping_fee);
      }

      let tax = 0.0;
      if (this.state.hasTaxable) {
        for (let item of this.props.cartItems) {
          if (item.tax_amount) {
            tax = addCost(tax, item.tax_amount);

          } else {
            let product = this.props.products.find(el => el.store_products.findIndex(s => parseInt(s.id) === parseInt(item.sp_id)) !== -1);
            tax = addCost(tax, getTaxAmount(item, product));
          }
        }
      }

      checkoutTotal = addCost(checkoutTotal, tax); //add the total tax to the order total

      axios.post('/api/shop/checkout-hosted-page', {orderID: orderID, storeId: this.props.store.id, transactionAmount: checkoutTotal.toFixed(2), address: this.state.address, city: this.state.city, state: this.state.state, zip_code: this.state.zipcode})
      .then(({data}) => {
        var xmlDoc = new XMLParser().parseFromString(data);

        let transactionSetupID = xmlDoc.getElementsByTagName("TransactionSetupID")[0].value;
        let hostedURL = `https://transaction.hostedpayments.com/?transactionSetupID=${transactionSetupID}`;

        this.setState({hostedURL: hostedURL, hostedToken: hostedURL});
        $('#add_payment').attr('src', hostedURL)

        if (this.props.reservedSlot && this.props.total >= this.props.store.minimum_required) {
          $("#add_payment").css('opacity', 1);
        } else {
          if (this.props.store.shopping_fee && parseMoney(this.props.store.shopping_fee) > 0) {
            $("#add_payment").css('opacity', 1);
          } else {
            $("#add_payment").css('opacity', 0);
          }
        }
      })

    }

    onOpenModal = () => {
        this.refreshSlots();
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onChange = date => this.setState({ date })

    setStateFromCheckbox = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.checked;
        this.setState(obj);
      }

    refreshSlots = () => {
      if (!this.props.user) {
        return;
      }

      this.setState({loadingPickupSlots: true});
      //get weekday slots so it doesn't sort 30 times for each month in the valid function below
      let store = this.props.store;

      let pickup_slots = store.pickup_slots ? store.pickup_slots : [];

      let today = moment();
      let getSlotsForDates = []; //for getting available slots for selectable dates

      let showDates = []; //for showing the dates, even if they are not selectable;

      if (parseInt(store.rolling_slots) === 0) {
  			// for (var i = 0; i <= 7; i++) {
  			for (let i = today.day(); i <= (today.day()+7); i++) {
  				//since the slots are not rolling and are only opened for the week on Sunday, only get the dates in between those days
  				let day = moment().add((i - today.day()), 'day');
  				if (i <= today.day()) {
  					showDates.push({
  						date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
  						enabled: false, pickup_times: [], slotsAvailable: 0, slotsUsed: 0 });
  					continue;
  				}
          if (store.cutoff_slot_time && i === today.day()+1) { //if there is a cutoff time, and the current slot we're checking for is tomorrow
            if ( moment(new Date(), "h:mm a").isAfter(moment(store.cutoff_slot_time, 'h:mm a')) ) {
              showDates.push({
    						date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
    						enabled: false, pickup_times: [], slotsAvailable: 0, slotsUsed: 0 });
              continue;
            }
          }

  				//eslint-disable-next-line
  				let pickup_times = pickup_slots.filter(s => s.pickup_day === moment().weekday(i).format('dddd')).map(s => {
  					return { pickup_time: s.pickup_time, slotsAvailable: s.slots, slotsUsed: 0, enabled: s.slots > 0 };
  				})

  				let availableSlots = pickup_times.map(s => s.slotsAvailable).reduce((x, y) => {
  					return x + y;
  				}, 0)

  				showDates.push({
  					date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
  					enabled: availableSlots > 0, pickup_times: pickup_times, slotsAvailable: availableSlots, slotsUsed: 0 });
  				getSlotsForDates.push(day.format('MM/DD/YYYY'));
  			}

  		} else {
  			let rollingDays = parseInt(store.rolling_slots);
  			let idx = 0;
  			for (let i = today.day(); i <= (today.day()+7); i++) {
  				//since the slots are rolling, we need to start from today and get either the slots OR when the slot will open for the week
  				let day = moment().add((i - today.day()), 'day');
  				if (i <= today.day()) {
  					showDates.push({
  						date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
  						enabled: false, pickup_times: [], slotsAvailable: 0, slotsUsed: 0 });
  					continue;
  				}
          if (store.cutoff_slot_time && i === today.day()+1) { //if there is a cutoff time, and the current slot we're checking for is tomorrow
            if ( moment(new Date(), "h:mm a").isAfter(moment(store.cutoff_slot_time, 'h:mm a')) ) {
              showDates.push({
    						date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
    						enabled: false, pickup_times: [], slotsAvailable: 0, slotsUsed: 0 });
                idx += 1;
              continue;
            }
          }

  				if (idx >= rollingDays) {
  					let pickup_times = pickup_slots.filter(s => s.pickup_day === moment().weekday(i).format('dddd')).map(s => {
  						return { pickup_time: s.pickup_time, slotsAvailable: s.slots, slotsUsed: 0, enabled: false };
  					})

  					let availableSlots = pickup_times.map(s => s.slotsAvailable).reduce((x, y) => {
  						return x + y;
  					}, 0)
  					showDates.push({
  						date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
  						enabled: false, pickup_times: [], slotsAvailable: availableSlots, slotsUsed: 0 });
  					continue;
  				}

  				let pickup_times = pickup_slots.filter(s => s.pickup_day === moment().weekday(i).format('dddd')).map(s => {
  					return { pickup_time: s.pickup_time, slotsAvailable: s.slots, slotsUsed: 0, enabled: s.slots > 0 };
  				})

  				let availableSlots = pickup_times.map(s => s.slotsAvailable).reduce((x, y) => {
  					return x + y;
  				}, 0)
  				showDates.push({
  					date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
  					enabled: availableSlots > 0, pickup_times: pickup_times, slotsAvailable: availableSlots, slotsUsed: 0 });
  				getSlotsForDates.push(day.format('MM/DD/YYYY'));

  				idx += 1;
  			}
  		}

      axios.post('/api/shop/reserved_slots_in_dates', {store_id: store.id, dates: getSlotsForDates})
      .then(({data}) => {
        var activeIndex = -1;

        showDates.map((sD, i) => {
          let compareDates = data.filter(d => {
            return d.pickup_date === sD.pickup_date;
          });

          if (compareDates.length > 0) {
            sD.slotsUsed = compareDates.length;

            //get the pickup times used, and put them in the slots used for each specific time
            //eslint-disable-next-line
            sD.pickup_times.map(time => {
              let compareTimes = compareDates.filter(d => {
                return d.pickup_time === time.pickup_time;
              })

              time.slotsUsed = compareTimes.length;

              if (time.slotsAvailable <= time.slotsUsed) {
                time.enabled = false;
              }
            });
            //

            if (sD.slotsAvailable <= sD.slotsUsed) {
              if(this.props.reservedSlot) {
                if (this.props.reservedSlot.pickup_date === sD.pickup_date) {
                  activeIndex = i
                }
              }
              sD.enabled = false;
            } else {
              if (activeIndex === -1) {
                activeIndex = i
              }
            }

          } else {
            if (sD.slotsAvailable > sD.slotsUsed) {

              if (activeIndex === -1) {
                activeIndex = i
              }
            } else {
              if(this.props.reservedSlot) {
                if (this.props.reservedSlot.pickup_date === sD.pickup_date) {
                  activeIndex = i
                }
              }
            }
          }

          return sD;
        });

        if (activeIndex === -1) {
          axios.post('/api/shop/find_nearby_open_slots', {dates: getSlotsForDates})
          .then(({data}) => {

            var numberOfStores = 0;
            for (var key in data.remainingByStore) {
              if (data.remainingByStore[key] > 0) {
                numberOfStores += 1;
              }
            }

            if (numberOfStores > 0) {
              storeDispatch.dispatch(receiveStores(data.allStores));

              this.setState({alternateStoreSlotsAvailable: numberOfStores})
            } else {
              this.setState({alternateStoreSlotsAvailable: 0})
            }
          })
        }

        this.setState({loadingPickupSlots: false, showDates: showDates});
      })
      .catch(error => {
        this.setState({loadingPickupSlots: false, showDates: showDates})
      });
    }

    selectDateSlot = index => {
      this.setState({activeSelected: index});
    }

    handleInputChange = e => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    };

    reserveTimeSlot = index => {
      let selected_date = this.state.showDates[this.state.activeSelected];
      let selected_time_details = selected_date.pickup_times[index];

      let pickup_date = selected_date.pickup_date;
      let pickup_day = selected_date.pickup_day;
      let pickup_time = selected_time_details.pickup_time;

      let store_id = this.props.store.id;

      let cart_id;
      if (this.props.cart) {

        if (this.props.reservedSlot) { //expire an existing slot if changing
          axios.post('/api/shop/expire_slot', {slot_id: this.props.reservedSlot.id})
            .then(() => {
            })
            .catch(error => {

            });
        }

        cart_id = this.props.cart.id;
        axios.post('/api/shop/reserve_slot', {pickup_date, pickup_day, pickup_time, store_id, cart_id})
          .then(({data}) => {
            storeDispatch.dispatch(reserveTimeSlot(data));
            this.setState({open: false})
            this.refreshSlots();
            if (this.props.total >= this.props.store.minimum_required) {
              $("#add_payment").css('opacity', 1);
            } else {
              if (this.props.store.shopping_fee && parseMoney(this.props.store.shopping_fee) > 0) {
                $("#add_payment").css('opacity', 1);
              } else {
                $("#add_payment").css('opacity', 0);
              }
            }
            // window.location.reload();
          })
          .catch(error => {

          });

      } else {
        storeDispatch.dispatch(getOrLoginUser(this.props.user.email));
        this.setState({open: false, warning: "Couldn't select the slot. Please try again."})

      }

    }

    changeStore = () => {
      this.setState({loadingPickupSlots: true, reselectStore: true})
    }

    openMobileForm = () => {
      this.setState({openMobileForm: !this.state.openMobileForm})
    }

    render () {
        if (!this.props.store || !this.props.user) {
          return <Redirect to={'/'} />
        }

        const {products, cartItems, total} = this.props;

        let subtotal = parseMoney(total);
        let checkoutTotal = subtotal;
        if (this.state.hasProduce) {
          checkoutTotal = addCost(checkoutTotal, 20); //add $20 pre-auth cost since this is a produce item
        }

        if (total < this.props.store.minimum_required && (this.props.store.shopping_fee && parseMoney(this.props.store.shopping_fee) > 0)) {
          checkoutTotal = addCost(checkoutTotal, this.props.store.shopping_fee);
        }

        let tax = 0.0;
        if (this.state.hasTaxable) {
          for (let item of this.props.cartItems) {
            if (item.tax_amount) {
              tax = addCost(tax, item.tax_amount);

            } else {
              let product = this.props.products.find(el => el.store_products.findIndex(s => parseInt(s.id) === parseInt(item.sp_id)) !== -1);
              tax = addCost(tax, getTaxAmount(item, product));
            }
          }
        }

        checkoutTotal = addCost(checkoutTotal, tax); //add the total tax to the order total

        if (!this.props.reservedSlot) {
          $("#add_payment").css('opacity', 0);
        } else {
          if (this.props.total >= this.props.store.minimum_required) {
            $("#add_payment").css('opacity', 1);
          } else {
            if (this.props.store.shopping_fee && parseMoney(this.props.store.shopping_fee) > 0) {
              $("#add_payment").css('opacity', 1);
            } else {
              $("#add_payment").css('opacity', 0);
            }
          }
        }

        if (this.props.cartItems.length > 0) {
          if (this.state.authorizeNetTotal > 0) {
            if (parseMoney(this.state.authorizeNetTotal) != parseMoney(checkoutTotal)) {
              this.props.history.push({pathname: '/cart'});
            }
          }
        }

        return (
            <div>
              <SessionCheck />

                {/*SEO Support*/}
                <Helmet>
                    <title>Patel Brothers | Checkout</title>
                </Helmet>
                {/*SEO Support End */}

                <Breadcrumb  title={'Checkout'}/>

                <Tabs>
                    <TabList className="nav nav-tabs tab-coupon" >
                      <Tab className="nav-link" style={{cursor: 'pointer'}}>Curbside Pickup</Tab>
                    </TabList>

                    <TabPanel>

                      <section >
                      <div className="container checkout-container">
                        <div className="row checkout-row">
                        <div className={`col-lg-12 col-md-12 col-sm-12 checkout-mobile-order-summary checkout-item-list  ${this.state.openMobileForm ? 'active' : ''}`}>
                          <div
                            className="mobile-form-label btn btn-success"
                            onClick={() => this.openMobileForm()}
                            role="button"
                            aria-labelledby="Order Summary"
                            tabIndex={0}
                          >
                            Order Summary <i className={`fa ${this.state.openMobileForm ? 'fa-angle-up' : 'fa-angle-down'}`} aria-hidden="true"></i>
                          </div>
                          <div className="final-total" style={{marginBottom: '10px'}}>
                              <h3>total <span>{moneyFormat(checkoutTotal)}</span></h3>
                          </div>


                                                    <div className="product-order" style={{paddingBottom: '20px'}}>
                                                    <div className="row product-order-detail">
                                                              <div className="col-3">

                                                              </div>
                                                              <div className="col-3 order_detail">
                                                                  <div>
                                                                      <h4>Product</h4>
                                                                  </div>
                                                              </div>
                                                              <div className="col-3 order_detail text-right">
                                                                  <div style={{width: '100%'}}>
                                                                      <h4>quantity</h4>
                                                                  </div>
                                                              </div>
                                                              <div className="col-3 order_detail text-right">
                                                                  <div style={{width: '100%'}}>
                                                                      <h4>price</h4>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                    {cartItems.map((item, index) => {
                                                      //eslint-disable-next-line
                                                      let product = products.find(el => el.store_products.findIndex(s => s.id == item.sp_id) !== -1);
                                                      if (!product) {
                                                        return (<li key={index}>{item.sp_id}</li>)
                                                      }

                                                      let store_product = product.store_products.find(el => el.id == item.sp_id);

                                                      var perLb = product.tags.findIndex(t => t && t.toLowerCase() === 'per lb') !== -1;
                                                      return (
                                                        <div className="row product-order-detail" key={index}>
                                                                  <div className="col-3">
                                                                  <img src={product.images[0]} alt={`Checkout ${product.name}-${index}`} className="img-fluid" style={{objectFit: 'contain'}} onError={(e) => {
                                                                    e.target.src = 'https://swad.nyc3.cdn.digitaloceanspaces.com/image-placeholder.jpg'
                                                                  }} />
                                                                  </div>
                                                                  <div className="col-3 order_detail">
                                                                      <div>
                                                                          <h5>
                                                                            {product.name} <span style={{fontSize: '12px', textTransform: 'lowercase', }}>
                                                                            {parseMoney(item.tax_amount) > 0.0 ? '+ tax ' : ' '}</span>
                                                                          </h5>
                                                                          <h5>{perLb ? '' : item.variant}</h5>
                                                                      </div>
                                                                  </div>
                                                                  <div className="col-3 order_detail text-right">
                                                                      <div style={{width: '100%'}}>
                                                                          <h5>
                                                                          {item.qty}
                                                                          {product.tags.map(t => {
                                                                              if (t === 'per lb') {
                                                                                //eslint-disable-next-line
                                                                                return ` Lb${item.qty != 1 ? 's' : ''}`
                                                                              } else {
                                                                                return ''
                                                                              }
                                                                            })
                                                                          } x {item.price} {perLb ? '/ lb' : null} {store_product.sale_price ? <span style={{textDecoration: 'line-through', color: 'red'}}>{store_product.price}</span> : null}
                                                                          </h5>
                                                                      </div>
                                                                  </div>
                                                                  <div className="col-3 order_detail text-right">
                                                                      <div style={{width: '100%'}}>
                                                                          <h5>{moneyFormat(multiplyByQty(item.price, item.qty))}</h5>
                                                                      </div>
                                                                  </div>
                                                              </div>)
                                                    })}
                                                    </div>

                                                                              <div className="total-sec">
                                                                                  <ul>
                                                                                      <li>subtotal <span>{moneyFormat(subtotal)}</span></li>
                                                                                      <li>Tax <span>{moneyFormat(tax)}</span></li>
                                                                                      {this.state.hasProduce ?
                                                                                        (
                                                                                          <li>Pre-Authorization for Produce <span>$20.00</span></li>
                                                                                        )
                                                                                        : ''
                                                                                      }
                                                                                      {total < this.props.store.minimum_required && (this.props.store.shopping_fee && parseMoney(this.props.store.shopping_fee) > 0) ?
                                                                                        (
                                                                                          <li>Personal Shoppers Fee <span>{moneyFormat(this.props.store.shopping_fee)}</span></li>
                                                                                        )
                                                                                        : ''
                                                                                      }
                                                                                  </ul>
                                                                              </div>
                                                                              <div className="final-total">
                                                                                  <h3>total authorized amount <span>{moneyFormat(checkoutTotal)}</span></h3>
                                                                              </div>

                                                                                  {(total >= this.props.store.minimum_required)?
                                                                                    ''
                                                                                    :
                                                                                    <p className="pickup-warning text-danger">
                                                                                      {this.props.store.shopping_fee && parseMoney(this.props.store.shopping_fee) > 0 ?
                                                                                        ''
                                                                                        :
                                                                                        `* There is a ${moneyFormat(this.props.store.minimum_required)} minimum required to check out.`
                                                                                      }
                                                                                    </p>
                                                                                  }
                                                                                  {this.state.warning !== '' ?
                                                                                    <p className="pickup-warning text-danger">{this.state.warning}</p>
                                                                                    :
                                                                                    ''
                                                                                  }
                        </div>

                        <div className={`col-lg-7 col-md-12 col-sm-12 col-12 authnet-col`}>

                        {this.props.reservedSlot ?
                          (
                            <h3>Your slot has been reserved. Please checkout within 30 minutes to keep your slot.</h3>
                          )
                          :
                          (
                            <h3 style={{color: 'red'}}>Please Select a Curbside Pickup Date and Time Slot To Continue Checkout</h3>
                          )
                        }
                        <center>
                          <input
                            type="text"
                            style={{textAlign: 'center', fontWeight: `${this.props.reservedSlot ? 'bold' : ''}`}}
                            readOnly={true}
                            value={this.props.reservedSlot ? `${moment(this.props.reservedSlot.pickup_date).format('ddd MM/DD')} @ ${this.props.reservedSlot.pickup_time}` : ''}
                            placeholder="Please Select Your Pickup Date & Time &#9660;"
                            className="date-picker-checkout"
                            onClick={this.onOpenModal}
                            aria-haspopup={true}
                            aria-labelledby="Select pickup date"
                          />

                        </center>
                        <h3 className="checkout-label" aria-labelledby="Payment are secure!">
                          Payment
                          <i className="fa fa-cc-visa" />
                          <i className="fa fa-cc-mastercard" />
                          <i className="fa fa-cc-amex" />
                          <i className="fa fa-cc-discover" />
                          <br/>
                          <span>All transactions are secure and encrypted.</span>
                        </h3>

                        <div>
                        {this.props.reservedSlot ?
                          (
                            <div className="checkout-label" aria-labelledby="Your Billing Address" style={{display: `${this.state.billingDiv}`}}>
                              <label for="address" style={{width: '100%'}}>Billing Address:
                              <br/>
                              <input id="address" type="text" name="address" defaultValue={this.state.address} onChange={this.handleInputChange} required />
                              </label>
                              <label for="city" style={{width: '45%', marginRight: '5%'}}>City:
                              <input id="city" type="text" name="city" defaultValue={this.state.city} onChange={this.handleInputChange} required />
                              </label>
                              <label for="state" style={{width: '45%'}}>State:
                              <input id="state" type="text" name="state" defaultValue={this.state.state} onChange={this.handleInputChange} required />
                              </label>
                              <label for="zipcode" style={{width: '100%'}}>Zip Code:
                              <br/>
                              <input id="zipcode" type="text" name="zipcode" defaultValue={this.state.zipcode} onChange={this.handleInputChange} required />
                              </label>
                              <center>
                                <div
                                  className="btn btn-success"
                                  onClick={() => this.getWorldpayPaymentPage()}
                                  role="button"
                                  aria-labelledby="Submit Billing and Go To Payment Details"
                                >
                                  Next
                                </div>
                              </center>
                            </div>
                          )
                          : null
                        }
                        </div>


                          {this.state.hostedToken ?
                            ''
                            :
                            <div style={{paddingTop: '30px'}}>
                            {
                              this.state.billingDiv == 'none' ?
                              (
                                <center>
                                  <ReactLoading color="#27ae60"/>
                                  <h3 style={{textAlign: 'center', paddingTop: '20px'}}>Loading Payment Form...</h3>
                                </center>
                              )
                              : null
                            }
                            </div>
                          }

                          <div id="iframe_holder" className="center-block" >
                            <iframe title="payment_holder" id="add_payment" className="embed-responsive-item panel" name="add_payment" width="100%" height="100%" frameBorder="0">
                            </iframe>
                          </div>
                          {/*this.props.store.id != 48 || this.props.store.id != 49 ?
                            (
                              <form id="send_token" action="" method="post" target="add_payment" >
                                <input type="hidden" name="token" value="" />
                              </form>
                            ) :
                            null
                          */}
                        </div>

                        <div className="col-lg-5 col-md-12 col-sm-12 col-12 checkout-item-list checkout-desktop-order-summary">


                          <div className="product-order" style={{paddingBottom: '20px'}}>
                          <div className="row product-order-detail">
                                    <div className="col-3">

                                    </div>
                                    <div className="col-3 order_detail">
                                        <div>
                                            <h4>Product</h4>
                                        </div>
                                    </div>
                                    <div className="col-3 order_detail text-right">
                                        <div style={{width: '100%'}}>
                                            <h4>quantity</h4>
                                        </div>
                                    </div>
                                    <div className="col-3 order_detail text-right">
                                        <div style={{width: '100%'}}>
                                            <h4>price</h4>
                                        </div>
                                    </div>
                                </div>
                          {cartItems.map((item, index) => {
                            //eslint-disable-next-line
                            let product = products.find(el => el.store_products.findIndex(s => s.id == item.sp_id) !== -1);
                            if (!product) {
                              return (<li key={index}>{item.sp_id}</li>)
                            }
                            let store_product = product.store_products.find(el => el.id == item.sp_id);

                            var perLb = product.tags.findIndex(t => t && t.toLowerCase() === 'per lb') !== -1;
                            return (
                              <div className="row product-order-detail" key={index}>
                                        <div className="col-3">
                                        <img src={product.images[0]} alt={`Checkout ${product.name}-${index}`} className="img-fluid" style={{objectFit: 'contain'}} onError={(e) => {
                                          e.target.src = 'https://swad.nyc3.cdn.digitaloceanspaces.com/image-placeholder.jpg'
                                        }} />
                                        </div>
                                        <div className="col-3 order_detail">
                                            <div>
                                                <h5>{product.name} <span style={{fontSize: '12px', textTransform: 'lowercase', }}>{parseMoney(item.tax_amount) > 0.0 ? '+ tax' : ''}</span></h5>
                                                <h5>{perLb ? '' : item.variant}</h5>
                                            </div>
                                        </div>
                                        <div className="col-3 order_detail text-right">
                                            <div style={{width: '100%'}}>
                                                <h5>
                                                {item.qty}
                                                {product.tags.map(t => {
                                                    if (t === 'per lb') {
                                                      //eslint-disable-next-line
                                                      return ` Lb${item.qty != 1 ? 's' : ''}`
                                                    } else {
                                                      return ''
                                                    }
                                                  })
                                                } x {item.price} {perLb ? '/ lb' : null} {store_product.sale_price ? <span style={{textDecoration: 'line-through', color: 'red'}}>{store_product.price}</span> : null}
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="col-3 order_detail text-right">
                                            <div style={{width: '100%'}}>
                                                <h5>{moneyFormat( multiplyByQty(item.price, item.qty) )}</h5>
                                            </div>
                                        </div>
                                    </div>)
                          })}
                          </div>
                              <div className="checkout-totals-div">
                                                    <div className="total-sec">
                                                        <ul>
                                                            <li>subtotal <span>{moneyFormat(subtotal)}</span></li>
                                                            <li>Tax <span>{moneyFormat(tax)}</span></li>
                                                            {this.state.hasProduce ?
                                                              (
                                                                <li>Pre-Authorization for Produce <span>$20.00</span></li>
                                                              )
                                                              : ''
                                                            }
                                                            {total < this.props.store.minimum_required && (this.props.store.shopping_fee && parseMoney(this.props.store.shopping_fee) > 0) ?
                                                              (
                                                                <li>Personal Shoppers Fee <span>{moneyFormat(this.props.store.shopping_fee)}</span></li>
                                                              )
                                                              : ''
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="final-total">
                                                        <h3>total authorized amount <span>{moneyFormat(checkoutTotal)}</span></h3>
                                                    </div>

                                                        {(total >= this.props.store.minimum_required)?
                                                          ''
                                                          :
                                                          <p className="pickup-warning text-danger">
                                                          {this.props.store.shopping_fee && parseMoney(this.props.store.shopping_fee) > 0 ?
                                                            ''
                                                            :
                                                            `* There is a ${moneyFormat(this.props.store.minimum_required)} minimum required to check out.`
                                                          }
                                                          </p>
                                                        }
                                                        {this.state.warning !== '' ?
                                                          <p className="pickup-warning text-danger">{this.state.warning}</p>
                                                          :
                                                          ''
                                                        }
                                          </div>

                                     </div>

                        </div>
                        </div>
                      </section>

                      <Modal open={this.state.open} onClose={this.onCloseModal} center classNames={{modal: "pickup-slot-modal"}}>
                        <div className="modal-header">
                          <h3>
                            Select Your Curbside Pickup Date & Time
                          </h3>
                        </div>
                        {this.state.loadingPickupSlots ?
                          (
                            <div className="modal-body">
                            {this.state.reselectStore ?
                              (
                                <StoreListing />
                              )
                              :
                              (
                                <center>
                                  <ReactLoading type={'spin'} color={'#129e51'} height={200} width={200} />
                                </center>
                              )
                            }
                            </div>
                          )
                          :
                          (
                            <div className="modal-body" style={{display: 'inline'}}>
                              <div className="container">
                                <div className="row">
                                {this.state.activeSelected === -1 ?
                                  <div className="col-lg-12 col-md-12 col-sm-12 col-12" id="pickup-dates-col" ref={this.dateSlotMenu}>
                                    {this.state.showDates.map((date, i) => {
                                      if (!date.enabled) {
                                        return (
                                          <div key={i} className="pickup-box disabled">
                                            <h3>{moment().isoWeekday(date.pickup_day).format('ddd')}, {date.date}</h3>
                                            <p>{date.slotsAvailable ? `Slots ${parseInt(date.slotsAvailable) === parseInt(date.slotsUsed) ? 'Will Re-' : ''}Open ${moment().isoWeekday(date.pickup_day).subtract(2, 'day').format('dddd')}` : 'No Slots Available'}</p>
                                          </div>
                                        );
                                      }
                                      else {
                                        var slotsLeft = date.slotsAvailable - date.slotsUsed;
                                        return (
                                          <div
                                            role="button"
                                            aria-labelledby={`Select ${moment().isoWeekday(date.pickup_day).format('ddd')}`}
                                            tabIndex={0}
                                            key={i}
                                            className={`pickup-box`}
                                            onClick={() => this.selectDateSlot(i)}
                                          >
                                            <h3>{moment().isoWeekday(date.pickup_day).format('ddd')}, {date.date}</h3>
                                            <p>{slotsLeft} Slot{slotsLeft === 1 ? '' : 's'} Available</p>
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                  :
                                  (
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12"  ref={this.timeSlotMenu}>
                                      {this.state.activeSelected >= 0 ?
                                        <div className="timeslot-box">
                                          <p role="button" tabIndex={0} aria-labelledby="Remove timeslot" className="back-btn" onClick={() => this.setState({activeSelected: -1})}>BACK</p>
                                          <h3 className="timeslot-title">
                                            {this.state.showDates[this.state.activeSelected].date},
                                            {moment().year()} - {this.state.showDates[this.state.activeSelected].pickup_day}
                                          </h3>
                                          {
                                            this.state.showDates[this.state.activeSelected].pickup_times.map((time, i) => {
                                              var isSelected = false;

                                              if (this.props.reservedSlot) {
                                                if (this.props.reservedSlot.pickup_time === time.pickup_time && this.props.reservedSlot.pickup_date === this.state.showDates[this.state.activeSelected].pickup_date) {
                                                  isSelected = true;
                                                }
                                              }

                                              if (!time.enabled ) {
                                                return (
                                                  <div key={i} className="timeslot-box selectable disabled">
                                                    <div className="row">
                                                      <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                                                        <h3>{time.pickup_time}</h3>
                                                      </div>
                                                      <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{alignSelf: 'center'}}>
                                                        {isSelected ?
                                                          (
                                                            <p className="btn btn-dark" style={{cursor: 'default'}}>Your reserved slot</p>
                                                          )
                                                          :
                                                          (
                                                            <p>No Slots Available</p>
                                                          )
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                );

                                              } else {
                                                var slotsLeft = time.slotsAvailable - time.slotsUsed;
                                                return (
                                                  <div key={i} className='timeslot-box selectable'>
                                                    <div className="row">
                                                      <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                                                        <h3>{time.pickup_time}</h3>
                                                        <p>{slotsLeft} Slot{slotsLeft === 1 ? '' : 's'} Available</p>
                                                      </div>
                                                      <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{alignSelf: 'center'}}>
                                                      {isSelected ?
                                                        (
                                                          <p className="btn btn-dark" style={{cursor: 'default'}}>Your reserved slot</p>
                                                        )
                                                        :
                                                        (
                                                          <p role="button" aria-labelledby="Reserve Timeslot" tabIndex={0} className="btn btn-success" onClick={() => this.reserveTimeSlot(i)} style={{cursor: 'pointer'}}>Reserve this Slot</p>
                                                        )
                                                      }
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            })
                                          }
                                        </div>
                                        :
                                        <div className="timeslot-box empty">
                                          <h3 className='error'>
                                            There Are No Timeslots Available This Week.
                                            <br/><br/>
                                            Please try again on this upcoming Sunday.
                                            <br/><br/>
                                            {this.state.alternateStoreSlotsAvailable > 0 ?
                                              (
                                                <div>
                                                <p className="text-danger" style={{fontSize: '16px', padding: '10px'}}>
                                                  {/* eslint-disable-next-line */}
                                                  There {this.state.alternateStoreSlotsAvailable == 1 ? 'is' : 'are'} {this.state.alternateStoreSlotsAvailable} other store{this.state.alternateStoreSlotsAvailable == 1 ? '' : 's'} near you that have some curbside pickup slots, would you like to change your store?
                                                  <br/><br/>
                                                  WARNING: We cannot guarantee pickup availability, or the price, quantity, and availability of items - including those in your cart - if you change your store.
                                                </p>
                                                <p role="button" aria-labelledby="Change store" tabIndex={0} className="btn btn-success" onClick={() => this.changeStore()} style={{cursor: 'pointer'}}>Change Store</p>
                                                </div>
                                              )
                                              :
                                              ''
                                            }
                                          </h3>
                                        </div>
                                      }
                                    </div>
                                  )
                                }
                                </div>
                              </div>

                            </div>
                          )
                        }
                      </Modal>

                    </TabPanel>

                  </Tabs>

            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    cart: state.cartList.cartObj,
    reservedSlot: state.userData.slot,
    user: state.userData.user,
    products: state.data.products,
    store: state.storeData.selected_store,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps, {reserveTimeSlot, completeCheckout, getOrLoginUser, clearStores, refreshHomeStore, refreshGuestHomeStore, logoutUser}
)(checkOut)
