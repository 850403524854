import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import {Link, Redirect} from 'react-router-dom'
import storeDispatch from '../../store';
import Modal from 'react-responsive-modal';
import ReactLoading from 'react-loading';

import Breadcrumb from "../common/breadcrumb";
import SessionCheck from "../common/SessionCheck";
import {getCartTotal} from "../../services";
import {removeFromCart, editQty, silentEditQty, getOrLoginUser, removeSilent, reserveTimeSlot, receiveStores, emptyCart} from '../../actions'
import NumberFormat from 'react-number-format';
import StoreListing from '../collection/common/store-listing.jsx';
import {
  parseMoney, multiplyByQty, moneyFormat, getTaxAmount, addCost
} from '../helpers';

const moment = require('moment');
const axios = require('axios');

class cartComponent extends Component {
    constructor (props) {
        super (props)
        this.state = {
          open: false,
          loadingCheckout: false,
          loadingPickupSlots: true,
          reselectStore: false,
          showDates: [],
          warning: '',
          alternateStoreSlotsAvailable: 0,
          activeSelected: -1 //the active date selected in the select date modal, if -1, that means no slots are available this week, try again next sunday
        }
    }

    componentDidMount() {
      this.refreshSlots();
    }

    onOpenModal = () => {
        this.refreshSlots();
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onChange = date => this.setState({ date })

    refreshSlots = () => {

      if (!this.props.user) {
        return;
      }

      this.setState({loadingPickupSlots: true});
      //get weekday slots so it doesn't sort 30 times for each month in the valid function below
      var store = this.props.store;

      var pickup_slots = store.pickup_slots ? store.pickup_slots : [];

      var today = moment();
      var getSlotsForDates = []; //for getting available slots for selectable dates

      var showDates = []; //for showing the dates, even if they are not selectable;

      if (parseInt(store.rolling_slots) === 0) {
  			// for (var i = 0; i <= 7; i++) {
  			for (let i = today.day(); i <= (today.day()+7); i++) {
  				//since the slots are not rolling and are only opened for the week on Sunday, only get the dates in between those days
  				let day = moment().add((i - today.day()), 'day');
  				if (i <= today.day()) {
  					showDates.push({
  						date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
  						enabled: false, pickup_times: [], slotsAvailable: 0, slotsUsed: 0 });
  					continue;
  				}

          if (store.cutoff_slot_time && i === today.day()+1) { //if there is a cutoff time, and the current slot we're checking for is tomorrow
            if ( moment(new Date(), "h:mm a").isAfter(moment(store.cutoff_slot_time, 'h:mm a')) ) {
              showDates.push({
    						date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
    						enabled: false, pickup_times: [], slotsAvailable: 0, slotsUsed: 0 });

              continue;
            }
          }

  				//eslint-disable-next-line
  				let pickup_times = pickup_slots.filter(s => s.pickup_day === moment().weekday(i).format('dddd')).map(s => {
  					return { pickup_time: s.pickup_time, slotsAvailable: s.slots, slotsUsed: 0, enabled: s.slots > 0 };
  				})

  				let availableSlots = pickup_times.map(s => s.slotsAvailable).reduce((x, y) => {
  					return x + y;
  				}, 0)

  				showDates.push({
  					date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
  					enabled: availableSlots > 0, pickup_times: pickup_times, slotsAvailable: availableSlots, slotsUsed: 0 });
  				getSlotsForDates.push(day.format('MM/DD/YYYY'));
  			}

  		} else {
  			var rollingDays = parseInt(store.rolling_slots);
  			var idx = 0;
  			for (let i = today.day(); i <= (today.day()+7); i++) {
  				//since the slots are rolling, we need to start from today and get either the slots OR when the slot will open for the week
  				let day = moment().add((i - today.day()), 'day');
  				if (i <= today.day()) {
  					showDates.push({
  						date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
  						enabled: false, pickup_times: [], slotsAvailable: 0, slotsUsed: 0 });
  					continue;
  				}

          if (store.cutoff_slot_time && i === today.day()+1) { //if there is a cutoff time, and the current slot we're checking for is tomorrow
            if ( moment(new Date(), "h:mm a").isAfter(moment(store.cutoff_slot_time, 'h:mm a')) ) {
              showDates.push({
    						date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
    						enabled: false, pickup_times: [], slotsAvailable: 0, slotsUsed: 0 });
                idx += 1;

              continue;
            }
          }

  				if (idx >= rollingDays) {
  					let pickup_times = pickup_slots.filter(s => s.pickup_day === moment().weekday(i).format('dddd')).map(s => {
  						return { pickup_time: s.pickup_time, slotsAvailable: s.slots, slotsUsed: 0, enabled: false };
  					})

  					let availableSlots = pickup_times.map(s => s.slotsAvailable).reduce((x, y) => {
  						return x + y;
  					}, 0)
  					showDates.push({
  						date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
  						enabled: false, pickup_times: [], slotsAvailable: availableSlots, slotsUsed: 0 });
  					continue;
  				}

  				//eslint-disable-next-line
  				let pickup_times = pickup_slots.filter(s => s.pickup_day === moment().weekday(i).format('dddd')).map(s => {
  					return { pickup_time: s.pickup_time, slotsAvailable: s.slots, slotsUsed: 0, enabled: s.slots > 0 };
  				})

  				let availableSlots = pickup_times.map(s => s.slotsAvailable).reduce((x, y) => {
  					return x + y;
  				}, 0)
  				showDates.push({
  					date: day.format('MMM DD'), pickup_date: day.format('MM/DD/YYYY'), pickup_day: moment().weekday(i).format('dddd'),
  					enabled: availableSlots > 0, pickup_times: pickup_times, slotsAvailable: availableSlots, slotsUsed: 0 });
  				getSlotsForDates.push(day.format('MM/DD/YYYY'));

  				idx += 1;
  			}
  		}

      axios.post('/api/shop/reserved_slots_in_dates', {store_id: store.id, dates: getSlotsForDates})
      .then(({data}) => {
        var activeIndex = -1;

        showDates.map((sD, i) => {
          let compareDates = data.filter(d => {
            return d.pickup_date === sD.pickup_date;
          });

          if (compareDates.length > 0) {
            sD.slotsUsed = compareDates.length;

            //get the pickup times used, and put them in the slots used for each specific time
            // eslint-disable-next-line
            sD.pickup_times.map(time => {
              let compareTimes = compareDates.filter(d => {
                return d.pickup_time === time.pickup_time;
              })

              time.slotsUsed = compareTimes.length;

              if (time.slotsAvailable <= time.slotsUsed) {
                time.enabled = false;
              }
            });
            //

            if (sD.slotsAvailable <= sD.slotsUsed) {
              if(this.props.reservedSlot) {
                if (this.props.reservedSlot.pickup_date === sD.pickup_date) {
                  activeIndex = i
                }
              }

              sD.enabled = false;
            } else {
              if (activeIndex === -1) {
                activeIndex = i
              }
            }

          } else {
            if (sD.slotsAvailable > sD.slotsUsed) {

              if (activeIndex === -1) {
                activeIndex = i
              }
            } else {
              if(this.props.reservedSlot) {
                if (this.props.reservedSlot.pickup_date === sD.pickup_date) {
                  activeIndex = i
                }
              }
            }
          }

          return sD;
        });

        if (activeIndex === -1) {
          axios.post('/api/shop/find_nearby_open_slots', {dates: getSlotsForDates})
          .then(({data}) => {

            var numberOfStores = 0;
            for (var key in data.remainingByStore) {
              if (data.remainingByStore[key] > 0) {
                numberOfStores += 1;
              }
            }

            if (numberOfStores > 0) {
              storeDispatch.dispatch(receiveStores(data.allStores));

              this.setState({alternateStoreSlotsAvailable: numberOfStores})
            } else {
              this.setState({alternateStoreSlotsAvailable: 0})
            }
          })
        }

        this.setState({loadingPickupSlots: false, showDates: showDates});
      })
      .catch(error => {
        this.setState({loadingPickupSlots: false, showDates: showDates})
      });
    }

    selectDateSlot = index => {
      this.setState({activeSelected: index});
    }

    reserveTimeSlot = index => {
      let selected_date = this.state.showDates[this.state.activeSelected];
      let selected_time_details = selected_date.pickup_times[index];

      let pickup_date = selected_date.pickup_date;
      let pickup_day = selected_date.pickup_day;
      let pickup_time = selected_time_details.pickup_time;

      let store_id = this.props.store.id;

      let cart_id;
      if (this.props.cart) {

        if (this.props.reservedSlot) { //expire an existing slot if changing
          axios.post('/api/shop/expire_slot', {slot_id: this.props.reservedSlot.id})
            .then(() => {
            })
            .catch(error => {
            });
        }

        cart_id = this.props.cart.id;
        axios.post('/api/shop/reserve_slot', {pickup_date, pickup_day, pickup_time, store_id, cart_id})
          .then(({data}) => {
            storeDispatch.dispatch(reserveTimeSlot(data));
            this.setState({open: false})
            this.refreshSlots();
          })
          .catch(error => {
          });

      } else {
        storeDispatch.dispatch(getOrLoginUser(this.props.user.email));
        this.setState({open: false, warning: "Couldn't select the slot. Please try again."})

      }

    }

    emptyCart = () => {
  		storeDispatch.dispatch(emptyCart(this.props.cart.id))
    }

    changeStore = () => {
      this.setState({loadingPickupSlots: true, reselectStore: true})
    }

    editQty(item, product, selectedVariant, incDec) {
      // eslint-disable-next-line
      var storeProduct = product.store_products.find(s => item.sp_id == s.id)

      product.id = storeProduct.id; // Since we will be using this id for line_items, let's make this the default id

      if (!storeProduct.price) {
        product.price = product.original_price;
      } else {
        product.price = storeProduct.price;
      }

      if (storeProduct.sale_price) {
        product.sale_price = storeProduct.sale_price;
      }

      product.stock = storeProduct.stock;
      product.sp_id = storeProduct.id;


      var isProduce = product.tags.findIndex(t => t === 'per lb') !== -1;

      var num = isProduce ? 0.5 : 1;
      if (incDec === 'decrement') {
        if (item.qty - num <= 0) {
          return;
        }
      }

      this.props.editQty(item, product, selectedVariant, isProduce ? 0.5 : 1, incDec, this.props.cart ? this.props.cart.id : null)
    }

    changeQty(item, product, selectedVariant, totalStock, values) {
      if (values.floatValue < 0) {
        return;
      }

      if (totalStock > 0) {
        if (values.floatValue > totalStock) {
          this.props.silentEditQty(item, product, selectedVariant, totalStock, 'update', this.props.cart ? this.props.cart.id : null)

        } else {
          this.props.silentEditQty(item, product, selectedVariant, values.floatValue, 'update', this.props.cart ? this.props.cart.id : null)
        }

      } else {
        this.props.silentEditQty(item, product, selectedVariant, values.floatValue, 'update', this.props.cart ? this.props.cart.id : null)

      }
    }

    goToCheckout() {
      if (this.props.total < this.props.store.minimum_required) {
        if (this.props.store.shopping_fee && parseMoney(this.props.store.shopping_fee) > 0) {
        } else {
          return;
        }
      }

      if (!this.props.reservedSlot) {
        this.setState({warning: "* Please Select A Curbside Pickup Date And Time To Checkout. If One Is Not Available, Please Try Again This Upcoming Sunday."});
        return;
      }

      this.setState({loadingCheckout: true})
      //MAKE SURE THIS ISN'T DOING NYTHING BAD FOR US!!!
      var hasEmpty = this.props.cartItems.filter(c => parseFloat(c.qty) === 0)
      if (hasEmpty.length > 0) {
        for (var remove of hasEmpty) {
          axios.post('/api/shop/remove_line_item', {line_item_id: remove.id})
            //eslint-disable-next-line
            .then(({data}) => {
              if (data.error) {
                return [];
              }

              this.props.removeSilent(remove)
              this.props.history.push('checkout');

            })

        }
      } else {
        this.props.history.push('checkout');
      }

    }

    render (){
      if (!this.props.store.id) {
        return <Redirect to={'/'} />
      }

        const {cartItems, total, products} = this.props;

        if (!products || products[0] == null) {
          return <Redirect to={'/'} />
        }

        let tax = 0.0;
        // let hasProduce = false;
        for (let item of cartItems) {
          let product = products.find(el => el.store_products.findIndex(s => parseInt(s.id) === parseInt(item.sp_id)) !== -1);
          // if (product.category.toLowerCase() === "produce") {
          //   hasProduce = true;
          // }

          if (item.tax_amount) {
            tax = addCost(tax, item.tax_amount);

          } else {
            tax = addCost(tax, getTaxAmount(item, product));
          }
        }

        let estimateTotal = addCost(total, tax);
        // if (hasProduce) {
        //   estimateTotal = addCost(estimateTotal, 20);
        // }


        return (
            <div>
              {this.props.user ? <SessionCheck /> : null }

                {/*SEO Support*/}
                <Helmet>
                    <title>Patel Brothers | Cart</title>
                    <meta name="description" content="Patel Brothers - Celebrating Our Food...Our Culture. Curbside Pickup is now available in certain Patel Brother locations." />
                </Helmet>
                {/*SEO Support End */}

                <Breadcrumb title={'Cart'}/>

                {cartItems.length>0 ?
                <section className="cart-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div
                              role="button"
                              tabIndex={0}
                              aria-labelledby="Empty Cart"
                              style={{textAlign: 'right', width: '100%', marginRight: '20px', cursor: 'pointer', color: '#777777', fontSize: '10px'}}
                              onClick={this.emptyCart}
                            >
                              Empty Cart
                            </div>

                            <div className="col-sm-12">
                                <table className="table cart-table table-responsive-xs">
                                    <thead>
                                    <tr className="table-head">
                                      <th scope="col"></th>
                                      <th scope="col">product</th>
                                      <th scope="col">price</th>
                                      <th scope="col">quantity</th>
                                      <th scope="col">total</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {cartItems.map((item, index) => {
                                      var allProducts = products.slice();
                                      //eslint-disable-next-line
                                      var product = allProducts.find(el => el.store_products.findIndex(s => item.sp_id == s.id) !== -1);
                                      //eslint-disable-next-line
                                      var storeProduct = product.store_products.find(s => item.sp_id == s.id)

                                      var saleOriginalPrice = null;
                											if (storeProduct.sale_price) {
                												saleOriginalPrice = storeProduct.price;
                											}


                                      var totalStock = storeProduct.stock;

                                      var selectedVariant = null;
                                      if (product.variants) {
                                        if (product.variants.length > 0) {
                                          selectedVariant = product.variants.filter(v => v === item.variant)[0];
                                        }
                                      }

                                      var isProduce = product.tags.findIndex(t => t === 'per lb') !== -1
                                        return (
                                            <tr key={index}>
                                              <td className="cart-product-row" style={{padding: '0.2rem'}}>
                                                <Link to={`${process.env.PUBLIC_URL}/product/${product.id}`}>
                                                    <img src={product.images[0]} alt={`Cart ${product.name}`} onError={(e) => {
                                                      e.target.src = 'https://swad.nyc3.cdn.digitaloceanspaces.com/image-placeholder.jpg'
                                                    }} />
                                                </Link>
                                                <div className="mobile-cart-only">
                                                  <Link to={`${process.env.PUBLIC_URL}/product/${product.id}`}>
                                                    <h3 style={{textAlign: 'center'}}>{product.name} {isProduce ? ' ' : (item.variant ? `${item.variant}` : ' ')} </h3>
                                                  </Link>

                                                  {saleOriginalPrice ?
                                                    <h5>
                                                      <span className="td-color" style={{marginRight: '2px'}}>
                                                        { moneyFormat(multiplyByQty(item.price, item.qty)) }
                                                      </span>
                                                      {product.taxable ? <span style={{fontSize: '10px', textTransform: 'lowercase'}}> + tax </span> : null }
                                                      <span className="smaller">
                                                        ({item.price} {saleOriginalPrice ? <span className="smaller" style={{textDecoration: 'line-through', color: 'red', fontSize: '10px'}}> {saleOriginalPrice}</span> : null}{isProduce ? ` / lb` : ` ea.`})
                                                      </span>
                                                    </h5>
                                                    :
                                                    <h5>
                                                      <span className="td-color">{ moneyFormat(multiplyByQty(item.price, item.qty)) }</span>
                                                      {product.taxable ? <span style={{fontSize: '10px', textTransform: 'lowercase'}}> + tax </span> : null }
                                                      <span className="smaller">({isProduce ? `${item.price} / lb` : `${item.price} ea.`})</span>
                                                    </h5>
                                                  }

                                                  <div className="qty-box">
                                                      <div className="input-group">

                                                      <span className="input-group-prepend">
                                                          <button aria-label="Decrease Quantity" aria-labelledby="Decrease Quantity" type="button" className='btn quantity-left-minus disable-dbl-tap-zoom' onClick={() => this.editQty(item, product, selectedVariant,'decrement')} data-type="minus" data-field="">
                                                           <i className="fa fa-angle-left"></i>
                                                          </button>
                                                      </span>

                                                      <NumberFormat aria-labelledby="Quantity" allowNegative={false} value={item.qty} decimalScale={isProduce ? 2 : 0} className="form-control input-number" onValueChange={(values) => this.changeQty(item, product, selectedVariant, totalStock, values )}/>

                                                      <span className="input-group-prepend">
                                                      {/*eslint-disable-next-line*/}
                                                      { totalStock > 0 && item.qty == totalStock ?
                                                        (<button aria-label="Increase Quantity" className={`btn quantity-right-plus disabled disable-dbl-tap-zoom`}  data-type="plus">
                                                        <i className="fa fa-angle-right"></i>
                                                        </button>
                                                        )
                                                        :
                                                        (<button aria-label="Increase Quantity" aria-labelledby="Increase Quantity" className={`btn quantity-right-plus disable-dbl-tap-zoom`} onClick={() => this.editQty(item, product, selectedVariant, 'increment')}  data-type="plus">
                                                        <i className="fa fa-angle-right"></i>
                                                        </button>
                                                      )
                                                      }

                                                      {product.tags.map(t => {
                                                          if (t === 'per lb') {
                                                            return <span style={{marginLeft: '10px', alignSelf: 'center', fontSize: '18px'}}>
                                                             {/* eslint-disable-next-line */}
                                                             Lb{item.qty != 1 ? 's' : ''}
                                                            </span>
                                                          } else {
                                                            return ''
                                                          }
                                                        })
                                                      }
                                                      </span>

                                                     </div>
                                                  </div>
                                                  <p role="button" tabIndex={0} aria-labelledby="Remove Item" className="remove-from-cart icon" onClick={() => this.props.removeFromCart(item)}>
                                                      Remove
                                                  </p>
                                                </div>
                                              </td>
                                              <td style={{padding: '0'}}>
                                                <div className="product-in-cart-title">
                                                  <Link to={`${process.env.PUBLIC_URL}/product/${product.id}`}><h3>{product.name}</h3><h5>{isProduce ? '' : (item.variant ? item.variant : '')}</h5></Link>
                                                  <p role="button" tabIndex={0} aria-labelledby="Remove Item" className="remove-from-cart icon" onClick={() => this.props.removeFromCart(item)}>
                                                      Remove
                                                  </p>
                                                </div>
                                                    <div className="mobile-cart-content row">
                                                        <div className="col-xs-3">
                                                            <div className="qty-box">
                                                                <div className="input-group">
                                                                    <input
                                                                      type="text"
                                                                      name="quantity"
                                                                      className="form-control input-number"
                                                                      defaultValue={item.qty}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-3">
                                                          <h5 className="td-color">{isProduce ? `${item.price} / lb` : item.price}</h5>
                                                        </div>
                                                        <div className="col-xs-3">
                                                            <h2 className="td-color">
                                                                {/* eslint-disable-next-line */}
                                                                <a aria-label="Remove Item from Cart" href="#" className="icon" onClick={() => this.props.removeFromCart(item)}>
                                                                    <i className="icon-close"></i>
                                                                </a>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{padding: '0'}}>
                                                  {saleOriginalPrice ?
                                                    <h4 style={{textTransform: 'lowercase'}}>
                                                      {item.price} <span className="smaller" style={{textDecoration: 'line-through', color: 'red'}}> {saleOriginalPrice}</span>{isProduce ? ` / lb` : ` ea.`}
                                                    </h4>
                                                    :
                                                    <h4 style={{textTransform: 'lowercase'}}>
                                                      {item.price} {isProduce ? `/ lb` : 'ea.'}
                                                    </h4>
                                                  }
                                                </td>
                                                <td style={{padding: '0'}}>
                                                    <div className="qty-box">
                                                        <div className="input-group">
                                                            <span className="input-group-prepend">
                                                                <button aria-label="Decrease Quantity" type="button" className='btn quantity-left-minus disable-dbl-tap-zoom' onClick={() => this.editQty(item, product, selectedVariant,'decrement')} data-type="minus" data-field="">
                                                                 <i className="fa fa-angle-left"></i>
                                                                </button>
                                                            </span>

                                                            <NumberFormat aria-label="Quantity" allowNegative={false} value={item.qty} decimalScale={isProduce ? 2 : 0} className="form-control input-number" onValueChange={(values) => this.changeQty(item, product, selectedVariant, totalStock, values )}/>

                                                            <span className="input-group-prepend">
                                                            {/*eslint-disable-next-line*/}
                                                            { totalStock > 0 && item.qty == totalStock ?
                                                              (<button aria-label="Increase Quantity" className={`btn quantity-right-plus disabled disable-dbl-tap-zoom`}  data-type="plus">
                                                              <i className="fa fa-angle-right"></i>
                                                              </button>
                                                              )
                                                              :
                                                              (<button aria-label="Increase Quantity" className={`btn quantity-right-plus disable-dbl-tap-zoom`} onClick={() => this.editQty(item, product, selectedVariant, 'increment')}  data-type="plus">
                                                              <i className="fa fa-angle-right"></i>
                                                              </button>
                                                            )
                                                            }
                                                            {product.tags.map(t => {
                                                                if (t === 'per lb') {
                                                                  return <span style={{marginLeft: '10px', alignSelf: 'center', fontSize: '18px'}}>
                                                                   {/* eslint-disable-next-line */}
                                                                   Lb{item.qty != 1 ? 's' : ''}
                                                                  </span>
                                                                } else {
                                                                  return ''
                                                                }
                                                              })
                                                            }
                                                           </span>

                                                        </div>
                                                        {totalStock > 0 ? ((item.qty >= totalStock) ? <p aria-labelledby="Purchase limit" className="text-danger icon remove-from-cart">You have reached the purchase limit.</p> : '') : ''}

                                                    </div>
                                                </td>
                                                {item.qty ? <td><h2 className="td-color">{ moneyFormat(multiplyByQty(item.price, item.qty)) }</h2></td> : <td><h2 className="td-color">$0.00</h2></td> }
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                                <table className="table cart-table table-responsive-md" style={{marginBottom: '20px'}}>
                                    <tfoot>
                                    <tr>
                                      <td>subtotal :</td>
                                      <td style={{fontWeight: 'bold'}}>
                                        {total ? `${moneyFormat(total)}` : '$0.00'}<br/>
                                      </td>
                                    </tr>
                                    <tr>
                                        <td style={{paddingTop: '0'}}>est. tax :</td>
                                        <td style={{fontWeight: 'bold',paddingTop: '0'}}>
                                          {moneyFormat(tax)}<br/>
                                        </td>
                                      </tr>
                                      {/*hasProduce ?
                                        <tr>
                                            <td style={{paddingTop: '0'}}>Pre-Auth for Produce :</td>
                                            <td style={{fontWeight: 'bold',paddingTop: '0'}}>
                                              {moneyFormat(20)}<br/>
                                            </td>
                                          </tr>
                                          :
                                          null
                                      */}
                                      <tr>
                                        <td style={{paddingTop: '0'}}>est. total :</td>
                                        <td style={{fontWeight: 'bold',paddingTop: '0'}}>
                                          {moneyFormat(estimateTotal)}<br/>
                                        </td>
                                      </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            {(total >= this.props.store.minimum_required) || (total < this.props.store.minimum_required && (this.props.store.shopping_fee && parseMoney(this.props.store.shopping_fee) > 0)) ? '' : (<p className="text-danger cart-warning">{`* There is a ${moneyFormat(this.props.store.minimum_required)} minimum required to check out.`}</p>) }
                          </div>
                        </div>

                        {this.props.user ?
                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                              {this.props.reservedSlot ?
                                (
                                  <h3 style={{textAlign: 'center', textTransform: 'uppercase', fontWeight: 'bold'}}>Your slot has been reserved. Please checkout within 30 minutes to keep your slot.</h3>
                                )
                                :
                                (
                                  <h3 style={{textAlign: 'center', textTransform: 'capitalize', fontWeight: 'bold'}}>Please Select a Curbside Pickup Date and Time Slot</h3>
                                )
                              }
                              <input aria-haspopup={true} type="text" style={{textAlign: 'center', width: '100%'}} readOnly={true}
                                value={this.props.reservedSlot ? `${moment(this.props.reservedSlot.pickup_date).format('ddd MM/DD')} @ ${this.props.reservedSlot.pickup_time}` : ''}
                                placeholder="Please Select Your Pickup Date & Time &#9660;" className="date-picker-checkout" onClick={this.onOpenModal} />
                                <h4 className="pickup-warning text-danger">{this.state.warning}</h4>
                            </div>
                          </div>
                          : null
                        }


                        <div className="row cart-buttons">
                            <div className="col-6">
                                <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid" style={{backgroundColor: 'yellow'}}>continue shopping</Link>
                            </div>
                            {this.props.user ?
                              <div className="col-6">
                              {this.state.loadingCheckout ?
                                (<button aria-labelledby="Loading Checkout" className={`btn btn-solid disabled`}>Loading Checkout...</button>)
                                :
                                (<button aria-labelledby="Checkout" onClick={() => this.goToCheckout()} className={`btn btn-solid ${total < this.props.store.minimum_required && (!this.props.store.shopping_fee || parseMoney(this.props.store.shopping_fee) == 0) ? 'disabled' : ''}`}>check out</button>)
                              }
                              </div>
                              :
                              <div className="col-6">
                                <Link className={`btn btn-solid`} to={`${process.env.PUBLIC_URL}/login`}>Login or Register</Link>
                              </div>
                            }
                        </div>
                    </div>
                </section>
                :
                <section className="cart-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div >
                                    <div className="col-sm-12 empty-cart-cls text-center">
                                        <img src={`https://swad.nyc3.cdn.digitaloceanspaces.com/patelbros/icons/empty-cart.png`} className="img-fluid mb-4" alt="Empty Cart" />
                                        <h3>
                                            <strong>Your Cart is Empty</strong>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                }

                {this.props.user ?
                  <Modal open={this.state.open} onClose={this.onCloseModal} center classNames={{modal: "pickup-slot-modal"}}>
                    <div className="modal-header">
                      <h3>
                        Select Your Curbside Pickup Date & Time
                      </h3>
                    </div>
                    {this.state.loadingPickupSlots ?
                      (
                        <div className="modal-body">
                        {this.state.reselectStore ?
                          (
                            <StoreListing />
                          )
                          :
                          (
                            <center>
                              <ReactLoading type={'spin'} color={'#129e51'} height={200} width={200} />
                            </center>
                          )
                        }
                        </div>
                      )
                      :
                      (
                        <div className="modal-body" style={{display: 'inline'}}>
                          <div className="container">
                            <div className="row">
                            {this.state.activeSelected === -1 ?
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12" id="pickup-dates-col" ref={this.dateSlotMenu}>
                                {this.state.showDates.map((date, i) => {
                                  if (!date.enabled) {
                                    return (
                                      <div key={i} className="pickup-box disabled">
                                        <h3>{moment().isoWeekday(date.pickup_day).format('ddd')}, {date.date}</h3>
                                        <p>{date.slotsAvailable ? `Slots ${parseInt(date.slotsAvailable) === parseInt(date.slotsUsed) ? 'Will Re-' : ''}Open ${moment().isoWeekday(date.pickup_day).subtract(2, 'day').format('dddd')}` : 'No Slots Available'}</p>
                                      </div>
                                    );
                                  }
                                  else {
                                    var slotsLeft = date.slotsAvailable - date.slotsUsed;
                                    return (
                                      <div role="button" tabIndex={i} aria-labelledby={moment().isoWeekday(date.pickup_day).format('ddd')} key={i} className={`pickup-box`}
                                        onClick={() => this.selectDateSlot(i)}>
                                        <h3>{moment().isoWeekday(date.pickup_day).format('ddd')}, {date.date}</h3>
                                        <p>{slotsLeft} Slot{slotsLeft === 1 ? '' : 's'} Available</p>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                              :
                              (
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12"  ref={this.timeSlotMenu}>
                                  {this.state.activeSelected >= 0 ?
                                    <div className="timeslot-box">
                                      <p role="button" aria-labelledby="Back Button" className="back-btn" style={{cursor: 'pointer'}} onClick={() => this.setState({activeSelected: -1})}>BACK</p>
                                      <h3 className="timeslot-title">
                                        {this.state.showDates[this.state.activeSelected].date},
                                        {moment().year()} - {this.state.showDates[this.state.activeSelected].pickup_day}
                                      </h3>
                                      {
                                        this.state.showDates[this.state.activeSelected].pickup_times.map((time, i) => {
                                          var isSelected = false;

                                          if (this.props.reservedSlot) {
                                            if (this.props.reservedSlot.pickup_time === time.pickup_time && this.props.reservedSlot.pickup_date === this.state.showDates[this.state.activeSelected].pickup_date) {
                                              isSelected = true;
                                            }
                                          }

                                          if (!time.enabled ) {
                                            return (
                                              <div key={i} className="timeslot-box selectable disabled">
                                                <div className="row">
                                                  <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                                                    <h3>{time.pickup_time}</h3>
                                                  </div>
                                                  <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{alignSelf: 'center'}}>
                                                    {isSelected ?
                                                      (
                                                        <p className="btn btn-dark" style={{cursor: 'default'}}>Your reserved slot</p>
                                                      )
                                                      :
                                                      (
                                                        <p>No Slots Available</p>
                                                      )
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            );

                                          } else {
                                            var slotsLeft = time.slotsAvailable - time.slotsUsed;
                                            return (
                                              <div key={i} className='timeslot-box selectable'>
                                                <div className="row">
                                                  <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                                                    <h3>{time.pickup_time}</h3>
                                                    <p>{slotsLeft} Slot{slotsLeft === 1 ? '' : 's'} Available</p>
                                                  </div>
                                                  <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{alignSelf: 'center'}}>
                                                  {isSelected ?
                                                    (
                                                      <p className="btn btn-dark" style={{cursor: 'default'}}>Your reserved slot</p>
                                                    )
                                                    :
                                                    (
                                                      <p role="button" aria-labelledby="Reserve TimeSlot" className="btn btn-success" onClick={() => this.reserveTimeSlot(i)} style={{cursor: 'pointer'}}>Reserve this Slot</p>
                                                    )
                                                  }
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        })
                                      }
                                    </div>
                                    :
                                    <div className="timeslot-box empty">
                                      <h3 className='error'>
                                        There Are No Timeslots Available This Week.
                                        <br/><br/>
                                        Please try again on this upcoming Sunday.
                                        <br/><br/>
                                        {this.state.alternateStoreSlotsAvailable > 0 ?
                                          (
                                            <div>
                                            <p className="text-danger" style={{fontSize: '16px', padding: '10px'}}>
                                              {/* eslint-disable-next-line */}
                                              There {this.state.alternateStoreSlotsAvailable == 1 ? 'is' : 'are'} {this.state.alternateStoreSlotsAvailable} other store{this.state.alternateStoreSlotsAvailable == 1 ? '' : 's'} near you that have some curbside pickup slots, would you like to change your store?
                                              <br/><br/>
                                              WARNING: We cannot guarantee pickup availability, or the price, quantity, and availability of items - including those in your cart - if you change your store.
                                            </p>
                                            <p role="button" aria-labelledby="Change Store" className="btn btn-success" onClick={() => this.changeStore()} style={{cursor: 'pointer'}}>Change Store</p>
                                            </div>
                                          )
                                          :
                                          ''
                                        }
                                      </h3>
                                    </div>
                                  }
                                </div>
                              )
                            }
                            </div>
                          </div>

                        </div>
                      )
                    }
                  </Modal>
                  : null
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    cart: state.cartList.cartObj,
    store: state.storeData.selected_store,
    products: state.data.products,
    symbol: state.data.symbol,
    user: state.userData.user,
    reservedSlot: state.userData.slot,
    total: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps,
    {reserveTimeSlot, getOrLoginUser, removeFromCart, editQty, silentEditQty, removeSilent, emptyCart}
)(cartComponent)
