import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

class Faq extends Component {
	render() {
		return (
			<div>
				<Breadcrumb title={'Faq'} />
				<section className="faq-section section-b-space container">
					<h3 className='pb-2'>Rules and How It Works</h3>
					<p style={{fontSize: '20px'}}><strong><u>****PLEASE READ CAREFULLY AS THIS IS A NEW PROCESS FOR ALL OF US****</u></strong></p>
					<p style={{fontSize: '18px'}}>{this.props.store.name ? `${this.props.store.name} is ` : 'Some Patel Brothers locations are '} offering curbside pickup. We can only provide this service in a safe way for our customers and our associates if the following rules are understood and followed.  By using our online ordering and curbside pickup service, you are agreeing to the specific procedures below, as well as those set out in the <Link to={"/policies/terms-of-service"} title="Terms of Service" class="text-link">Terms of Service</Link> and <Link to={"/policies/privacy-policy"} title="Privacy Policy" class="text-link">Privacy Policy</Link>.</p>
					<p style={{fontSize: '18px'}}>
					For safety and capacity reasons, a limited number of spots is available each day and slots are only available Tuesday through Sunday. We cannot guarantee a particular pickup time or same day pickup. You will receive an email confirmation that your order is ready to be picked up, and you must pick up your order that day.    
					Failure to pick up on the scheduled day may result in the cancellation of your order and a <u>$9.99 re-stocking fee </u>being charged to the credit card that you authorized.
					</p>
					<p style={{fontSize: '18px'}}>Once you arrive at the store, please</p>
					<p style={{fontSize: '18px'}}>(1)      Please drive up the front entrance, an associate will be outside,</p>
					<p style={{fontSize: '18px'}}>(2)      Please roll down your window and tell the associate your order number</p>
					<p style={{fontSize: '18px'}}>You should clean out the trunk of your car ahead of time so that your order can be loaded by our associates in a contactless way.  <strong>Do not get out of your vehicle.  Since it will be necessary to open your window, please wear a mask.</strong>  We will not be providing a paper receipt to minimize contact.</p>
					<p style={{fontSize: '18px'}}>We are currently requiring a <u>{this.props.store.name ? `$${this.props.store.minimum_required} minimum order` : 'minimum order amount per store'}</u>, and reserve the right to change the required amount.</p>
					<p style={{fontSize: '18px'}}>We reserve the right to refuse or to modify an order for any lawful reason.  We also reserve the right to only partially fulfill an order for any lawful reason (although, of course, you will only be charged for the goods that you actually receive).</p>
					<p style={{fontSize: '18px'}}>We will add a <u>$20 pre-authorization</u> on credit cards for an order of produce of any size to allow us to adjust — up or down — to the actual weight of an item, which might vary from the estimated weight requested by a customer online.</p>
					<p style={{fontSize: '18px'}}> </p>
					<p style={{fontSize: '18px'}}><em>Please read these rules and revisit the Terms of Service and Privacy Policy each time you order as we reserve the right to make changes in order to make the process work better.</em></p>
				</section>
			</div >
		)
	}
}

const mapStateToProps = (state) => ({
	store: state.storeData.selected_store,
	user: state.userData.user,
})

export default connect(
	mapStateToProps
)(Faq);
