import React, {Component} from 'react';
import axios from 'axios';
import store from '../../store';
import { receiveUser, receiveCart } from '../../actions'
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux'
import Breadcrumb from "../common/breadcrumb";
import { AsYouType } from 'libphonenumber-js'
import {
  isMobile
} from "react-device-detect";
import LogoImage from "../common/headers/common/logo";

var validator = require('email-validator');

class Register extends Component {

    constructor (props) {
        super (props)

        this.state = {
          email: '',
          password: '',
          first_name: '',
          last_name: '',
          phone_number: '',
          terms_check: false,
          phone_check: false,
          warning: null,
          showForgotPassword: false,
          inputtedPhoneNumber: false,
          phoneNumberError: null
        };
    }

    handleInputChange = e => {
      if (e.target.name == "phone_number") {
        let input = new AsYouType('US').input(e.target.value);

        if (input.length < 14 && input.length > 0) {
          this.setState({phoneNumberError: "Please enter your full phone number", phone_number: input, inputtedPhoneNumber: e.target.value.length > 0})
        } else {
          this.setState({phoneNumberError: null, phone_number: input, inputtedPhoneNumber: e.target.value.length > 0})
        }

      } else {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    };

    handleSubmit = e => {
      e.preventDefault();

      const { email, password, first_name, last_name, terms_check, phone_number, inputtedPhoneNumber, phone_check } = this.state;

      if (!validator.validate(email)) {
        this.setState({warning: 'Your email is not valid. Please check your entered email address and try again.'})
        return;
      }

      if (password.length < 6) {
        this.setState({warning: 'Your password must be at least 6 characters long.'})
        return;
      }

      if (first_name.length === 0 || last_name.length === 0) {
        this.setState({warning: 'Please enter your first and last name to create an account.'})
        return;
      }

      if (terms_check === false) {
        this.setState({warning: 'You must agree to the terms to create an account.'})
        return;
      }

      if (inputtedPhoneNumber && !phone_check) {
        this.setState({warning: 'You must agree to the order texts agreement if you enter your phone number.'})
        return;
      }


      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(process.env[`REACT_APP_RECAPTCHA_KEY`], {action: 'signup'}).then((captcha_token) => {

          let registerData = {email: email.toLowerCase(), password, first_name, last_name, phone_number, get_order_texts: inputtedPhoneNumber, selected_store: this.props.store.id, mobile: isMobile, captcha_token}
          if (this.props.cart.length > 0) {
            registerData.itemsInCart = this.props.cart;
          }

          axios.post('/api/shop/register', registerData)
            .then(({data}) => {
              store.dispatch(receiveUser(data.user));

              if (data.cart) {
                store.dispatch(receiveCart(data.cart, data.line_items));
              }

              return data;
            })
            .catch(error => {
              this.setState({warning: '* An account with this email already exists. If you forgot your password, please choose Forgot Password from the Login page.'})
            })
        });
      });

    };


    render (){
      if (this.props.user) {
        return <Redirect to={'/'} />
      }

        return (
            <div>
                {/*Regsiter section*/}
                {!this.props.user && !this.props.store ?
                  (
                    <div className="top-header pt-1">

                      <div className="container d-none d-lg-block d-xl-block">
                        <div className="row">
                          <div className="col-lg-4">
                          </div>
                          <div className="col-lg-4">
                                <div className="brand-logo" style={{padding: '10px 0'}}>
                                  <LogoImage openCategories={() => {}} />
                                </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  )
                  : null
                }
                <section className="register-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3>create an account</h3>
                                <div className="theme-card">
                                <form onSubmit={this.handleSubmit} className="theme-form">
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="email">First Name</label>
                                                <input type="text" className="form-control" id="fname"
                                                       placeholder="First Name" name="first_name" required onChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="review">Last Name</label>
                                                <input type="text" className="form-control" id="lname"
                                                       placeholder="Last Name" name="last_name" required onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="email">email</label>
                                                <input type="text" className="form-control" id="email"
                                                       placeholder="Email" name="email" required onChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="review">Password</label>
                                                <input type="password" className="form-control" id="review"
                                                       placeholder="Enter a password" name="password" required onChange={this.handleInputChange} />
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="review">Phone Number (optional)</label>
                                                <input type="text" className="form-control" id="review"
                                                       placeholder="Phone Number (optional)" name="phone_number" value={this.state.phone_number} onChange={this.handleInputChange} />
                                            </div>

                                            <div className="col-md-12">
                                              <input type="checkbox" name="terms_check" id="terms_agreement" required onChange={this.handleInputChange} />
                                              <label className="terms-label" htmlFor="terms_agreement" style={{textTransform:'none', paddingLeft: '10px'}}>
                                                I agree to the Patel Brothers
                                                <Link to={"/policies/terms-of-service"} style={{marginLeft: '2px'}}>
                                                {" "}Terms of Service.
                                                </Link>
                                              </label>
                                            </div>

                                            {this.state.inputtedPhoneNumber ?
                                              <div className="col-md-12" style={{marginBottom: '20px'}}>
                                                <input type="checkbox" name="phone_check" id="phone_agreement" required onChange={this.handleInputChange} style={{marginBottom: '3px'}} />
                                                <label className="phone-label" htmlFor="phone_agreement" style={{textTransform:'none', paddingLeft: '10px', display: 'inline'}}>
                                                  By entering my phone number, I agree that Patel Bros can send me order pick up information via text messages.
                                                  Msg and data rates may apply. Other important terms and conditions apply,
                                                  including an agreement to resolve any disputes only by individual arbitration (no court trial, no class actions).
                                                  To opt out, reply to message with STOP.
                                                </label>
                                              </div>
                                              :
                                              null
                                            }

                                            {this.state.warning ?
                                              (<div className="col-md-12">
                                                <p className="text-danger">{this.state.warning}</p>
                                              </div>)
                                              :
                                              ''
                                            }
                                            {this.state.phoneNumberError ?
                                              (<div className="col-md-12">
                                                <p className="text-danger">{this.state.phoneNumberError}</p>
                                              </div>)
                                              :
                                              ''
                                            }
                                            {this.state.phoneNumberError ?
                                              <div className="btn btn-solid disabled">Create an Account</div>
                                              :
                                              <button aria-labelledby="Create an account" className="btn btn-solid" type="submit">Create an Account</button>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.userData.user,
    store: state.storeData.selected_store,
    cart: state.cartList.cart
})

export default connect(
    mapStateToProps, {receiveUser, receiveCart}
)(Register)
