import {
  parseMoney, addCost, subtractCost, multiplyByQty, moneyFormat
} from '../components/helpers';
var numeral = require('numeral');

//Get stores nearest to user : TODO!!
export const getNearestStores = (data, {location}) => {
  return [];
}

export const getAllStores = (data) => {
  return data.stores;
}

// Get unique categories
export const getCategories = (products) => {
    var uniqueCategories = [];
    products.map((product, index) => {
          if (uniqueCategories.indexOf(product.category) === -1) {
              uniqueCategories.push(product.category);
          }
          return product
    })

    return uniqueCategories;
}

export const hasSale = products => {
    var hasSale = products.filter((p) => {
      if (p.sale_price) {
        return true
      }

      return p.store_products.filter(sp => sp.sale_price != null).length > 0
    }).length > 0;

    return hasSale;
}

export const getVisibleproducts = (data, { category, sortBy, name }) => {
    return data.products.filter(product => {
        let categoryMatch;

        if (category.length > 0) {
           categoryMatch = category.some(c => product.category === c);
        } else {
          categoryMatch = true;
        }

        if (name.length > 0) {
          if (!product.name.toLowerCase().includes(name.toLowerCase())) {
            categoryMatch = false;
          }
        }

        return categoryMatch;

    }).sort((product1, product2) => {
        if (sortBy === 'HighToLow') {
            return numeral(product2.price).value() < numeral(product1.price).value() ? -1 : 1;
        } else if (sortBy === 'LowToHigh') {
            return  numeral(product2.price).value() > numeral(product1.price).value() ? -1 : 1;
        } else if (sortBy === 'Newest') {
            return product2.id < product1.id ? -1 : 1;
        } else if (sortBy === 'AscOrder') {
            return product1.name.localeCompare(product2.name);
        } else if (sortBy === 'DescOrder') {
            return product2.name.localeCompare(product1.name);
        } else{
            return product2.id > product1.id ? -1 : 1;
        }
    });
}

export const getCartTotal = cartItems => {
    var total = 0;
    for(var i=0; i<cartItems.length; i++) {
      total = addCost(total, multiplyByQty(cartItems[i].price, cartItems[i].qty));
    }

    return total;
}

// Get Trending Tag wise Collection
export const getTrendingTagCollection = (products, type, tag) => {
    const items = products.filter(product => {
        return product.category === type && product.tags.includes(tag);
    })
    return items.slice(0,8)
}

// Get Trending Collection
export const getTrendingCollection = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    })
    return items.slice(0,8)
}

// Get Special 5 Collection
export const getSpecialCollection = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    })
    return items.slice(0,5)
}

// Get New Products
export const getNewProducts = (products, type) => {
    const items = products.filter(product => {
        return product.new === true && product.category === type;
    })

    return items.slice(0,8)
}

// Get Related Items
export const getRelatedItems = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    })

    return items.slice(0,4)

}

// Get Best Seller Furniture
export const getBestSellerProducts = (products, type) => {
    const items = products.filter(product => {
        return product.sale === true && product.category === type;
    })

    return items.slice(0,8)
}

// Get Best Seller
export const getBestSeller = products => {
    const items = products.filter(product => {
        return product.sale === true;
    })

    return items.slice(0,8)
}

// Get Single Product
export const getSingleItem = (products, id) => {

    const items = products.find((element) => {
        return element.id === id;
    })
    return items;
}

// Get Feature Products
export const getFeatureImages = (products, type) => {

    const items = products.filter(product => {
        return product.type === type;
    })
    return items;
}
