import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import SessionCheck from "../common/SessionCheck";
import StoreListing from "./common/store-listing";
import { connect } from 'react-redux'
import storeDispatch from '../../store';
import Splash from '../pages/splash'
import { getOrLoginUser, reserveTimeSlot, clearStores, refreshHomeStore, refreshGuestHomeStore, logoutUser } from '../../actions'
import CatsProds from './common/cats-prods';
const axios = require('axios');

class HomePage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			layoutColumns: 4, hasMoreItems: true, loadingStore: false, open: false,
			loadingPickupSlots: true,
			showDates: [],
			activeSelected: -1,
			//the active date selected in the select date modal, if -1, that means no slots are available this week, try again next sunday
			alternateStoreSlotsAvailable: 0
		};
	}

	componentWillMount() {

		if (this.props.user) {
			if (!this.props.shouldChangeStore) {
				this.setState({ loadingStore: true });

				axios.post('/api/shop/session_refresh', { user_id: this.props.user.id })
					.then(({ data }) => {
						let orderErrorCheck = 0;
      	    if (this.props.cartItems) {
      		    orderErrorCheck = this.props.cartItems.length;
      	    }

						storeDispatch.dispatch(refreshHomeStore(data));
						this.setState({ loadingStore: false });

            storeDispatch.dispatch(refreshHomeStore(data));

						if (this.props.reservedSlot) { //Refresh slot on activity
							axios.post('/api/shop/refresh_slot', {slot_id: this.props.reservedSlot.id})
							.then(({data}) => {
								storeDispatch.dispatch(reserveTimeSlot(data, true));
							}).catch(e => {
								storeDispatch.dispatch(reserveTimeSlot(null))
							})
						} else if (this.props.cart) {
							if (orderErrorCheck > 0 && this.props.cartItems.length == 0) {
                storeDispatch.dispatch(reserveTimeSlot(null));
          			this.props.history.push({pathname: '/account/orders'});
          		}

							axios.post('/api/shop/refresh_slot', {cart_id: this.props.cart.id})
							.then(({data}) => {
								storeDispatch.dispatch(reserveTimeSlot(data, true));
							}).catch(e => {
								storeDispatch.dispatch(reserveTimeSlot(null))
							})
						}
						
            if (!this.props.cart) {
							if (orderErrorCheck > 0) {
          			storeDispatch.dispatch(reserveTimeSlot(null));
          			this.props.history.push({pathname: '/account/orders'});
          		}
						}

					})
					.catch(error => {
						this.setState({ loadingStore: false });
						storeDispatch.dispatch(refreshHomeStore());

						if (error.response) {
			        if (error.response.status === 401) {
								storeDispatch.dispatch(logoutUser());
			        }
			      }
					});

			}
		} else {
			this.setState({ loadingStore: true });
			axios.post('/api/shop/session_refresh', { user_id: null })
				.then(({ data }) => {
					storeDispatch.dispatch(refreshHomeStore(data));
					if (data.cart) {
						axios.post('/api/shop/refresh_slot', {cart_id: data.cart.id})
						.then(({data}) => {
							storeDispatch.dispatch(reserveTimeSlot(data, true));
						}).catch(e => {
							storeDispatch.dispatch(reserveTimeSlot(null))
						})
					}

					this.setState({ loadingStore: false });
				})
				.catch(error => {
					this.setState({ loadingStore: false });
					if (this.props.selected_store) {
						storeDispatch.dispatch(refreshGuestHomeStore(this.props.selected_store));
					} else {
						storeDispatch.dispatch(refreshHomeStore());
					}

					if (error.response) {
						if (error.response.status === 401) {
							storeDispatch.dispatch(logoutUser());
						}
					}
				});

			// if (this.props.selected_store.id) { //is a guest, so refresh home store if it has one
			// 	storeDispatch.dispatch(refreshGuestHomeStore(this.props.selected_store));
			// 	this.setState({ loadingStore: false });
			// }
		}

	}

	changeStore = () => {
		this.setState({ open: false });
		storeDispatch.dispatch(clearStores());
	}

	render() {
		if (!this.props.user && !this.props.selected_store.id) {
			return <Splash />;
		}

		if (!this.props.selected_store.id) {
			return <StoreListing />;
		}

		return (
			<div>
				{this.props.shouldChangeStore && this.props.users ? <SessionCheck /> : null}

				{/*SEO Support*/}
				<Helmet>
					<title>Patel Brothers</title>
					<meta name="description" content="Your Local Patel Brothers is Now Available Online For Curbside Pickup!" />
				</Helmet>
				{/*SEO Support End */}

				<CatsProds match={this.props.match} history={this.props.history} />
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	reservedSlot: state.userData.slot,
	user: state.userData.user,
	cart: state.cartList.cartObj,
	cartItems: state.cartList.cart,
	selected_store: state.storeData.selected_store,
	shouldChangeStore: state.storeData.change_store
})

export default connect(
	mapStateToProps, { getOrLoginUser, reserveTimeSlot, clearStores, refreshHomeStore, refreshGuestHomeStore, logoutUser }
)(HomePage)
